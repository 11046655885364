import React, { useEffect, useContext, useState,useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  customStyle,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Moment from "react-moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
// import { MdDelete } from 'react-icons/md';
// import StockReport from "./StockReport";

//pages & includes
import AccountsSidebar from "../AccountsSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { FinanceContext } from "../../../../../contexts/Finance";

const ProfitAndLossReport = () => {
  const { t } = useTranslation();
  let {  loading, setLoading, generalSettings } = useContext(SettingsContext);
    //toast obj
    let common_toast={
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    }

    //reportFields state
    const [reportFields,setReportFields]=useState({
      from_date:"",
      to_date:"",
    });

    //report states
    const [reportData, setReportData] = useState([]);
    const [generatedReport, setGeneratedReport] = useState(false);

    const componentRef = useRef();

    const handleFromDate=(from_date)=>{
      setReportFields({
        ...reportFields,
        from_date
      })
    }

    const handleToDate=(to_date)=>{
      setReportFields({
        ...reportFields,
        to_date
      })
    }

    const handleSubmit=(e)=>{
      e.preventDefault();
      let {from_date , to_date } = reportFields
      if((from_date !== "" && from_date !== null) && (to_date !== "" && to_date !== null)){
        // setLoading(true);
        const url = BASE_URL + "/settings/get-consumption-report";
        let formData = new FormData();
        formData.append('from_date', moment(from_date).format("YYYY-MM-DD"));
        formData.append('to_date', moment(to_date).format("YYYY-MM-DD"));

        return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setReportData(res.data[0]);
          setGeneratedReport(true);
          componentRef.current.handleDownload();            
          setLoading(false);
        })
        .catch((error) => {            
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`,common_toast);
        });
        
      }else{
        toast.error(`${_t(t("Please select from date & to date"))}`,common_toast);
      }
    }


  return (
    <>
    <Helmet>
      <title>{_t(t("Accounts Profit And Loss Report"))}</title>
    </Helmet>

    <ReactHTMLTableToExcel
      id="test-table-xls-button"
      className="download-table-xls-button btn btn-success float-right mr-3 invisible"
      table="table-to-xls"
      filename="Accounts Profit And Loss Report"
      sheet="sheet 1"
      buttonText="Report"
      ref={componentRef}
    />

    {/* Print Button */}
    <div className="d-none">
      <div>
        <style type="text/css" media="print">
          {
            "\@page { size: landscape; }\
            "
          }
        </style>
        <div className="fk-print">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {
                reportData !== null &&
                  reportData !== undefined &&
                  generatedReport === true && 
                  (
                    <>
                      <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                        <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                          <table
                            className="table table-bordered table-hover min-table-height mt-3"
                            id="table-to-xls"
                          >
                            <div className="page-break"></div>
                            <thead className="align-middle">
                              <tr style={{ border: "0px" }}>
                                <th colspan="12" style={{ border: "0px" }}>
                                  <div className="row">
                                    <div className="col-12">
                                      <h3 className="text-center mb-0">
                                        {getSystemSettings(generalSettings, "siteName")}
                                      </h3>
                                      <h3 className="text-center mt-0">
                                        Accounts Profit And Loss Report
                                      </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {reportFields.from_date}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{reportFields.to_date}</Moment>
                                        </p>
                                    </div>
                                  </div>
                                </th>
                              </tr>
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                {_t(t("Code"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Doc Date"))}
                                </th>
                                
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Doc No"))}
                                </th>
                                
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Cheque No"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Remarks"))}
                                </th>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Debit"))}
                                </th>
                               
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Credit"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Balance"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here*/}
                              { reportData &&
                                Object.keys(reportData).map(
                                  (group_name, index) => {
                                    let Amount = 0; 
                                    let stock_Qty = 0;
                                    
                                    return(
                                    <div key={index}>
                                    <tr>
                                      <th
                                        scope="row"
                                        className="lg-text text-capitalize align-middle text-center"
                                      >
                                        {group_name}
                                      </th>
                                    </tr>
                                    {reportData[group_name].map((item, i) => {
                                      Amount += parseFloat(item.amount);
                                      stock_Qty += parseFloat(item.stock_qty);
                                      
                                      return (
                                        <tr>
                                        <td></td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center "
                                          >
                                            {i + 1}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                          <Moment format="LL">

                                            {item.document_date}
                                            </Moment>
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.branch_name}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.unit_name}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {item.stock_qty}
                                          </td>
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {formatPrice(item.avg_rate)}
                                          </td>
                                        
                                          <td
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {formatPrice(item.amount)}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  <tr>
                                <th
                                  scope="row"
                                  className="xsm-text text-capitalize align-middle text-center"
                                >
                                  Total
                                </th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            
                              <td
                              scope="row"
                              className="xsm-text text-capitalize align-middle text-center"
                              >
                                {formatPrice(stock_Qty)}
                              </td>
                              <td></td>
                              <td
                              scope="row"
                              className="xsm-text text-capitalize align-middle text-center"
                              >
                                {formatPrice(Amount)}
                              </td>
                               </tr>
                              </div>
                              );
                            }
                            )
                           }
                          </tbody>
                            
                          </table>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <AccountsSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${(loading) && "loadingBlur"}`}></div>
                    <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    {/* spin loading ends */}

                    <div key="fragment-3">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Profit And Loss Report"))}
                                </span>
                            </li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                        </div>
                        </div>

                        {/* Form starts here */}
                        <form
                        className="row card p-2 mx-3 mb-5 table-text"
                        onSubmit={handleSubmit}
                        >
                        <div className="col-12">
                            <div>                            
                            <div className="row">
                                <div className="form-group col-3 text-right mt-2">
                                <div className="mb-2 text-left">
                                    <label
                                    htmlFor="fromDate"
                                    className="control-label"
                                    >
                                    {_t(t("From Date"))}
                                    <span className="text-danger">*</span>
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <DatePicker
                                    selected={reportFields.from_date}
                                    onChange={(date) => handleFromDate(date)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control table-text"
                                    placeholderText={_t(t("From Date"))}
                                    shouldCloseOnSelect={false}                                  
                                    />
                                </div>
                                </div>

                                <div className="form-group col-3 text-right mt-2">
                                <div className="mb-2 text-left">
                                    <label
                                    htmlFor="toDate"
                                    className="control-label"
                                    >
                                    {_t(t("To Date"))}
                                    <span className="text-danger">*</span>
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <DatePicker
                                    selected={reportFields.to_date}
                                    onChange={(date) => handleToDate(date)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="form-control table-text"
                                    placeholderText={_t(t("To Date"))}
                                    shouldCloseOnSelect={false}                                  
                                    />
                                </div>
                                </div>

                                <div className="form-group mt-2 col-4">
                                </div>
                            
                                <div className="form-group mt-4 col-2">                                                                                                                        
                                <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-primary rounded mt-2"
                                >
                                  <i className="fa fa-table" aria-hidden="true"></i> {_t(t("Print P&L"))}
                                </button>                                                                                                                                                                                       
                                </div>
                            </div>

                            </div>                                                                        
                        </div>
                        </form>
                    </div>
                </div>
              </div>
            </div>
          </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
      {/* main body ends */}
  </>
  )
}

export default ProfitAndLossReport;
