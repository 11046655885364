import React, {useContext, useMemo} from 'react';
import {
    _t,
    currencySymbolRight,
    currencySymbolLeft,
    getSystemSettings,
    getCookie,
    showPriceOfEachOrderItem
} from "../../../../../functions/Functions";
import { useCustomHooks } from '../../../../../functions/Hooks';
import { BASE_URL } from "../../../../../BaseUrl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next"; 
import { SettingsContext } from '../../../../../contexts/Settings';
import { RestaurantContext } from '../../../../../contexts/Restaurant';
import { getServiceCharges }  from "../newpos/helpers";
import axios from 'axios';

const SplitBill = ({ splitBill, setSplitBill, taxType, setBillModal, billModal}) => {
    const { t } = useTranslation();
    const { loading, setLoading, schemeForSearchObj } = useContext(RestaurantContext);
    const {generalSettings} = useContext(SettingsContext);
    const { roundOffPrice } = useCustomHooks();

    const focusPopUpInputField = (input) => {
        if (input) {
          setTimeout(() => {
            input.focus();
          }, 200);
        }
    };

    const toastObj = {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
    };

    function calculateRemaining(total, totalPayable) {
        const remaining = roundOffPrice(Number(total)) - roundOffPrice(Number(totalPayable));
        return (remaining > 0.1) ? roundOffPrice(remaining) : 0;
    }

    const handleCloseItemModal = () =>{
        setSplitBill({});
        setBillModal({
            modal:false,
            currentState:null,
            orderDetail:null,
            originalItem:null,
            currentItem: null,
        });
    }
   
    const totalPrice = useMemo(() => {
        return Object.values(splitBill).reduce(
            (acc, item) => ({
                vat: acc.vat + (item.vat || 0),
                order_bill: acc.order_bill + (item.order_bill || 0),
                discount_amount: acc.discount_amount + (item.discount_amount || 0),
                serviceCharge: acc.serviceCharge + (item.serviceCharge || 0),
                service_amount: acc.service_amount + (item.service_amount || 0),
                order_tip: acc.order_tip + (item.order_tip || 0),
                totalPayable: acc.totalPayable + (item.totalPayable || 0),
            }),
            {
                vat: 0,
                order_bill: 0,
                discount_amount: 0,
                serviceCharge: 0,
                service_amount: 0,
                order_tip: 0,
                totalPayable: 0,
            }
        );
    }, [splitBill]);
    console.log("Abcd45",totalPrice,splitBill);

    const handleBillSplit = async(e) =>{
        e.preventDefault();
        const url = BASE_URL + "/settings/new-split-order";
        let items = false;
        if(Object.keys(splitBill)?.length < 2){
            toast.error("Split bill should be greater than 1", toastObj);
            return false;
        }
        const splitBillData = Object.values(splitBill).map(item =>{
            if(item.items?.length === 0){
                items = true
            }
            return ({
                ...item,
                vat: roundOffPrice(item.vat),
                order_bill: roundOffPrice(item.order_bill),
                discount_amount: roundOffPrice(item.discount_amount),
                serviceCharge: roundOffPrice(item.serviceCharge),
                service_amount: roundOffPrice(item.service_amount),
                order_tip: roundOffPrice(item.order_tip),
                totalPayable: roundOffPrice(item.totalPayable),
             }
            )
        });

        if(calculateRemaining(billModal.orderDetail.total_payable, totalPrice.totalPayable) < 0){
            toast.error("Please check total payable can't be negative", toastObj);
            return false;
        }

        const hasZeroQuantity = Object.values(billModal.currentItem).find((item) => item.quantity > 0);
        if(items){
            toast.error("Split bill cart item can't be empty", toastObj);
            return false;
        }
        
        if (hasZeroQuantity) {
            toast.error("Please check items in your order section is not transfer to bill split", toastObj);
            return false;
        }
        const localCurrency = JSON.parse(localStorage.getItem("currency"));
        if(!localCurrency){
            toast.error("Please select your currency", toastObj);
            return false;
        }
        const formData = {
            order_id: billModal.orderDetail.id,
            localCurrency : localCurrency,
            orderDetail: billModal.orderDetail,
            split_bill: splitBillData
        }
        setLoading(true)
        try{
            const res = await axios.post(url, formData,{
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            if(res.data){
                handleCloseItemModal();
                toast.success(_t("Bill split successfully"), toastObj);
            }
            setLoading(false);
        }catch (error){
            setLoading(false);
            toast.error("Please try again", toastObj);
        }
    }

    //get tax price
    const getTaxPrice = (price) => {
        return Number((price * (billModal.orderDetail.theBranch.branch_tax / 100)).toFixed(2));
    };

    const scheme = useMemo(() => {
        return billModal.orderDetail && billModal.orderDetail?.scheme_id ? schemeForSearchObj[billModal.orderDetail.scheme_id] : null;
    },[billModal.orderDetail])

    const getTax = (price) =>{
        const vat = price * (billModal.orderDetail.theBranch.branch_tax / 100);
        return Number(vat.toFixed(2));
    }

    const getOpenDis = (discount,qty) =>{
        return (Number(discount) / Number(qty)).toFixed(2)
    }

    const getOpenDisPercent = (price,percent) =>{
        return Number(((percent / 100) * price).toFixed(2))
    }
    const getDiscount = (discount,qty) =>{
        if((totalPrice.discount_amount + (discount/qty)) <= Number(billModal.orderDetail.discount_amount)){
            return Number((discount/qty).toFixed(2))
        }
        return (Number(billModal.orderDetail.discount_amount) - Number(totalPrice.discount_amount)).toFixed(2)
    }
    const getPrice = (price,qty) =>{
        return Number((price/qty).toFixed(2))
    }

    const getTip = (tip,qty) =>{
        return Number((tip/qty).toFixed(2))
    }

    const getDeliveryCharges = (deliveryCharges,qty) =>{
        return Number((deliveryCharges/qty).toFixed(2))
    }

    const getServiceAmount = (orderDetails, total, dis) =>{
        let serviceCharge = 0;
        let isServiceCharge = orderDetails?.theBranch && orderDetails.theBranch?.service_fees &&
        orderDetails?.total_guest >= orderDetails.theBranch.service_fees_person && orderDetails.order_type_name.match(/dine(.*)in/i)
        if (isServiceCharge) {
            let subtotalTax = parseFloat(total);
            if(parseInt(getSystemSettings(generalSettings, "service_charges_type")) === 1){
                subtotalTax = parseFloat((parseFloat(total) - parseFloat(dis)).toFixed(2));
            }
            serviceCharge = getServiceCharges(
                subtotalTax,
                orderDetails.theBranch.service_fees
            );
        }
        return serviceCharge
    }

    const handleMaxSplit = (e) =>{
        const value  = e.target.value;
        if(e.key === "Enter" && value < 2 || value > 4){
            toast.error("Split bill should be between 2 and 4", toastObj);
            return false;
        }
        if (e.key === "Enter" && value > 1 && value < 5) {
            const splitBill = {}
            for(let i = 1; i<=value; i++){
                splitBill[i] = {
                    items:[],
                    vat: 0,
                    order_bill: 0,
                    discount_amount: 0,
                    serviceCharge: 0,
                    service_amount: 0,
                    order_tip: 0,
                    totalPayable: 0,
                }
            }
            setSplitBill(splitBill);
            setBillModal({
                ...billModal,
                currentState: "1",
            });
        }
    }

    const handleCloseSplit = (key) =>{
        const billItems = splitBill[key].items;
        let orderItem = {...billModal.currentItem};
        if(billItems?.length){
            for (const item of billItems) {
                const currentQuantity = orderItem[item.id]?.quantity || 0;
                orderItem[item.id] = {
                    ...orderItem[item.id],
                    quantity: currentQuantity + item.quantity,
                };
            }
        }
        setSplitBill((curr)=>{
            const { [key]: _, ...rest } = curr;
            return rest; 
        });
       
        setBillModal((curr) => {
            const updatebill = {...splitBill};
            delete updatebill[key];
            const current =  Object.keys(updatebill)[0] || 0;
            const newBillModal = { ...curr, currentItem: orderItem, currentState: current };
            return newBillModal;
        });
    }

    const taxDiscount = useMemo(() => {
        return getSystemSettings(generalSettings, "tax_type_discount") === "tax_discount_after"
    }, [generalSettings]);

    const handleItemQty = (item, type)=> {
        const originalItem = billModal.currentItem[item.id];
        const updateItem = billModal.originalItem[item.id];
        if (!originalItem || !billModal.currentState) {
          console.error("Original item not found.");
          return;
        }
        const currentBillItems = splitBill[billModal.currentState] && splitBill[billModal.currentState].items || [];
        const existingBillItem = currentBillItems.find((item2) => item.id === item2.id);
        const updatedQuantity = existingBillItem ? existingBillItem.quantity + (type === "del" ? -1 : 1) : 1;
        
        const afterTaxprice = getPrice(updateItem.after_tax_price, updateItem.quantity);
        const items = { ...updateItem, price: updateItem.price / updateItem.quantity};
        const price = showPriceOfEachOrderItem(items, taxType, billModal.orderDetail.theBranch.branch_tax);
        
        const itemDiscount = (updateItem.item_discount * updatedQuantity) / updateItem.quantity;
        const tip = getTip(billModal.orderDetail.order_tip, Object.keys(splitBill).length);
        const delivery = getDeliveryCharges(billModal.orderDetail.service_charge, Object.keys(splitBill).length);

        const updateTotals = (split, operation) => {
            const factor = operation === "add" ? 1 : -1;
            const subtotal =  split.order_bill + (factor * afterTaxprice);
            let discount = getDiscount(Number(updateItem.item_discount),updateItem.quantity);
            if(scheme && scheme?.open_discount){
                if(billModal.orderDetail.discount){
                    let schemePrice = subtotal;
                    discount = getOpenDisPercent(schemePrice, billModal.orderDetail.discount);
                }else{
                    discount = getOpenDis(billModal.orderDetail.discount_amount, Object.keys(splitBill)?.length);
                }
            }
            let tax = getTax(subtotal);
            if(taxDiscount){
                tax = getTax(subtotal - discount);
            }
            const dis = (scheme && scheme?.open_discount) ? Number(discount) : (split.discount_amount + factor * discount);
            const serviceAmount = getServiceAmount(billModal.orderDetail, subtotal + tax, dis); 
            
            return {
                ...split,
                vat: tax,
                order_bill: subtotal,
                discount_amount: dis,
                serviceCharge :delivery,
                service_amount: serviceAmount,
                order_tip: split.order_tip + factor * tip,
                totalPayable: subtotal + tax + serviceAmount - dis,
            };
        };
        const updatedSplit = updateTotals({ ...splitBill[billModal.currentState] }, type);
        // Update quantity in item
        const updatedCurrentItem = {
            ...billModal.currentItem,
            [item.id]: (()=>{
                if (originalItem.id) {
                    let cartQuantity = originalItem.quantity;
                    if(type === "del" && updateItem.quantity > originalItem.quantity && existingBillItem){
                        cartQuantity = originalItem.quantity + 1
                    }else if(type === "add"){
                        cartQuantity = originalItem.quantity - 1   
                    }
            
                    if (cartQuantity < 0) cartQuantity = 0;
            
                    return {
                        ...originalItem,
                        quantity: cartQuantity
                    };
                }
                })(),
        };
  
        setBillModal((curr) => {
            const newData = {
                ...curr,
                currentItem: updatedCurrentItem,
            }
            return newData;
        })
       
        if(type === "del" && updatedQuantity >= 0 && existingBillItem && existingBillItem.quantity == 1){
            setSplitBill((curr)=>{
                const { [billModal.currentState]: currentBillItems, ...rest } = curr;
                const updatedBillItems = currentBillItems.items.filter((i) => i.id !== item.id);
                return { ...rest, [billModal.currentState]:  {...updatedSplit, items: updatedBillItems} };
            });
        }else if(existingBillItem){
            setSplitBill((curr)=>({...curr,[billModal.currentState]: {
                ...updatedSplit,
                    items: currentBillItems.map((i) =>
                        i.id === item.id ? { ...i, after_tax_price: afterTaxprice * updatedQuantity, price, quantity: updatedQuantity, item_discount: itemDiscount } : i
                    ),
                }
            }));
        }else if(type === "add"){
            setSplitBill((curr)=>({...curr,[billModal.currentState]:{...updatedSplit, items:[...currentBillItems, {...originalItem, after_tax_price: afterTaxprice, price, quantity: 1, item_discount: itemDiscount}]}}));
        }
       
    }

  return (
    <>
        <div
        className={`modal fade ${
            billModal.modal !== false ? "showing" : ""
        }`}
        aria-hidden="true"
        >
            {
                billModal.modal && billModal.orderDetail && (
                    <div className="m-4">
                        <div className="modal-content">
                            <div className="modal-header align-items-center py-2">
                                <div className="w-100 d-flex align-items-center justify-content-center">
                                <div className = "rider-text">
                                    Split Bill
                                </div>
                                <button
                                type="button"
                                className="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => handleCloseItemModal()}
                                ></button>
                                </div>
                            </div>
                            <div className="modal-body">
                                {/* show form or show saving loading */}
                                <div key="fragment-food-group-1">
                                    <div className="row">
                                        <div className='col-5 pr-0'>    
                                            <div className='bg-white d-flex flex-column justify-content-between border-right border-left'>
                                                <div>
                                                    <div className='bg-success text-center p-3 d-flex align-items-center justify-content-between'>
                                                        <h5 className='text-white m-0'>Your Order</h5>
                                                        <h5 className='text-white m-0'>{billModal.orderDetail && billModal.orderDetail.token.id}</h5>
                                                    </div>
                                                    <div className='my-1 mx-2 px-3'>
                                                        <div className="row border-bottom p-2 m-0">
                                                            <div className="text-capitalize genAcc-select font-weight-md col-5 pl-0">
                                                                Food Item
                                                            </div>
                                                            <div className="text-capitalize genAcc-select font-weight-md col-3 text-center">
                                                                Qty
                                                            </div>
                                                            <div className="text-capitalize genAcc-select font-weight-md col-4 text-right">
                                                                Price
                                                            </div>
                                                        </div>
                                                        <div className='items-wrap'>
                                                            {
                                                                billModal.currentItem && Object.keys(billModal.currentItem)?.length ? (Object.values(billModal.currentItem).map((item,index)=>{
                                                                    const itemProperty = [];
                                                                    const originalItem = billModal.originalItem[item.id];
                                                                    if (item?.properties && JSON.parse(item?.properties)?.length > 0) {
                                                                        JSON.parse(item.properties).forEach((item) => {
                                                                            const propertyName = item.property;
                                                                            itemProperty.push(
                                                                                <span className="item_property" key={propertyName}>
                                                                                {item.quantity} - {propertyName}
                                                                                </span>
                                                                            );
                                                                        });
                                                                    }
                                                                    return (
                                                                        <div key={index} className="p-2 row border-bottom position-relative">
                                                                            <div className="text-capitalize genAcc-select col-5">
                                                                                {item.food_item}
                                                                                {item.variation_id ? (
                                                                                    <span className="item_variation">
                                                                                    ({item?.variation}){" "}
                                                                                    </span>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                                <br />
                                                                                {itemProperty && itemProperty?.length ? [itemProperty] : ""}
                                                                            </div>
                                                                            <div className="text-capitalize genAcc-select col-3 text-center">
                                                                                <button
                                                                                    className="mx-1 itemQtyBtn cart-decrement"
                                                                                    onClick={() => billModal.currentState && handleItemQty(item, "del")}
                                                                                >
                                                                                    <i class="fa fa-plus"></i>
                                                                                </button>
                                                                                    {item.quantity}
                                                                                <button
                                                                                    disabled = {!item.quantity}
                                                                                    className="mx-1 itemQtyBtn cart-increment"
                                                                                    onClick={() => billModal.currentState && handleItemQty(item,"add")}
                                                                                >
                                                                                    <i class="fa fa-minus"></i>
                                                                                </button>
                                                                            </div>
                                                                            <div className="text-capitalize genAcc-select col-4 text-right text-break">
                                                                            { 
                                                                                Number(item.item_discount) > 0 && item.quantity && !scheme?.open_discount ? (
                                                                                <span> 
                                                                                    <span style={{color:"#e43b53"}}>
                                                                                    {currencySymbolLeft()}
                                                                                    {(((Number(item.after_tax_price) * item.quantity) / originalItem.quantity) - (Number(item.item_discount) * item.quantity / originalItem.quantity)).toFixed(2)}
                                                                                    {currencySymbolRight()}
                                                                                    </span>
                                                                                    <del className="ml-1">
                                                                                    {currencySymbolLeft()}
                                                                                    {item.quantity ? ((item.after_tax_price * item.quantity) / originalItem.quantity).toFixed(2) : 0}
                                                                                    {currencySymbolRight()}
                                                                                    </del> 
                                                                                </span>      
                                                                                )
                                                                                :
                                                                                <span>
                                                                                {currencySymbolLeft()}
                                                                                {item.quantity ? ((item.after_tax_price * item.quantity) / originalItem.quantity).toFixed(2) : 0}
                                                                                {currencySymbolRight()}
                                                                                </span>
                                                                            }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }))
                                                                : ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="pt-3 customer-border customer-border-radius" style={{paddingLeft:"2rem", paddingRight:"2rem"}}>
                                                        <div className="d-flex justify-content-between">
                                                            <span className='lg-text font-weight-md' >Sub Total</span>
                                                            <span className='lg-text font-weight-md'>{currencySymbolLeft()} {calculateRemaining(billModal.orderDetail.order_bill, totalPrice.order_bill)} {currencySymbolRight()}</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <span className='lg-text font-weight-md'>Tax</span>
                                                            <span className='lg-text font-weight-md'>{currencySymbolLeft()} {calculateRemaining(billModal.orderDetail.vat, totalPrice.vat)} {currencySymbolRight()}</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <span className='lg-text font-weight-md'>Service Charge</span>
                                                            <span className='lg-text font-weight-md'>{currencySymbolLeft()} {calculateRemaining(billModal.orderDetail.service_amount, totalPrice.service_amount)} {currencySymbolRight()}</span>
                                                        </div>
                                                        <div className="d-flex justify-content-between">
                                                            <span className='lg-text font-weight-md'>Discount</span>
                                                            <span className='lg-text font-weight-md'>{currencySymbolLeft()} {calculateRemaining(billModal.orderDetail.discount_amount, totalPrice.discount_amount)} {currencySymbolRight()}</span>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 text-center bg-success text-white p-3 customer-border-radius">
                                                        <h5 className='text-white m-0'>Total Payable: {currencySymbolLeft()} {calculateRemaining(billModal.orderDetail.total_payable, totalPrice.totalPayable)} {currencySymbolRight()}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-7 px-2'>
                                            <div className='mb-2 pl-3 pr-2'>
                                                <label className='pr-2 mb-1'> Please press enter after input split. (Max 4 split) </label>
                                                <input ref={focusPopUpInputField} disabled={Object.keys(splitBill)?.length > 0} type='number' step="1" min='2' max='4' onKeyDown={(e)=>handleMaxSplit(e)} defaultValue="2" className='form-control' placeholder='Please press enter'/>
                                            </div>
                                            <div className='mt-3 m-1 row fk-scroll--order-history kitchen_order'>
                                            {
                                                splitBill && Object.keys(splitBill)?.length ? Object.keys(splitBill).map((key)=>{
                                                    return(
                                                        <div className='col-6 mb-2'>
                                                            <div class="card cart-card">
                                                                <div class="card-header d-flex justify-content-between align-items-center" onClick={()=> setBillModal((curr)=>({...curr, currentState:key}))}>
                                                                    <div>
                                                                        <input
                                                                            title="Please select checkbox to add items"
                                                                            type="checkbox"
                                                                            style={{height:"1.25rem", width:"1.25rem"}}
                                                                            className="form-check-input pointer-cursor"
                                                                            checked={key === billModal.currentState}
                                                                        />
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close cursor-pointer"
                                                                        data-dismiss="modal"
                                                                        aria-label="Close"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleCloseSplit(key)
                                                                        }}
                                                                    ></button>
                                                                </div>
                                                                <div class="card-body px-2 py-1">
                                                                    <div className='m-1'>
                                                                        <div className="row border-bottom p-1 m-0">
                                                                            <div className="text-capitalize table-text font-weight-md col-5 pl-0">
                                                                                Food Item
                                                                            </div>
                                                                            <div className="text-capitalize table-text font-weight-md col-3 text-center">
                                                                                Qty
                                                                            </div>
                                                                            <div className="text-capitalize table-text font-weight-md col-4 text-right">
                                                                                Price
                                                                            </div>
                                                                        </div>
                                                                        <div className='bill_modal_scroll' style={{ height: Object.keys(splitBill)?.length > 2 ? "130px" : "200px"}}>
                                                                            {
                                                                                splitBill[key] && splitBill[key].items?.length ? splitBill[key].items.map((item,index)=>{
                                                                                    const itemProperty = [];
                                                                                    if (item?.properties && JSON.parse(item?.properties)?.length > 0) {
                                                                                        JSON.parse(item.properties).forEach((item) => {
                                                                                            const propertyName = item.property;
                                                                                            itemProperty.push(
                                                                                                <span className="item_property" key={propertyName}>
                                                                                                {item.quantity} - {propertyName}
                                                                                                </span>
                                                                                            );
                                                                                        });
                                                                                    }
                                                                                    return (
                                                                                        <div key={index} className="p-1 row border-bottom position-relative">
                                                                                            <div className="text-capitalize table-text col-5">
                                                                                                {item.food_item}
                                                                                                {item.variation_id ? (
                                                                                                    <span className="item_variation">
                                                                                                    ({item?.variation}){" "}
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    <></>
                                                                                                )}
                                                                                                <br />
                                                                                                {itemProperty && itemProperty?.length ? [itemProperty] : ""}
                                                                                            </div>
                                                                                            <div className="text-capitalize table-text col-3 text-center">
                                                                                                {item.quantity}
                                                                                            </div>
                                                                                            <div className="text-capitalize table-text col-4 text-right text-break">
                                                                                            { 
                                                                                                Number(item.item_discount) > 0 && item.quantity && !scheme?.open_discount ? (
                                                                                                <span> 
                                                                                                    <span style={{color:"#e43b53"}}>
                                                                                                    {currencySymbolLeft()}
                                                                                                    {((Number(item.after_tax_price)) - Number(item.item_discount)).toFixed(2)}
                                                                                                    {currencySymbolRight()}
                                                                                                    </span>
                                                                                                    <del className="ml-1">
                                                                                                    {currencySymbolLeft()}
                                                                                                    {item.quantity ? (item.after_tax_price).toFixed(2) : 0}
                                                                                                    {currencySymbolRight()}
                                                                                                    </del> 
                                                                                                </span>      
                                                                                                )
                                                                                                :
                                                                                                <span>
                                                                                                {currencySymbolLeft()}
                                                                                                {item.quantity ? (item.after_tax_price).toFixed(2) : 0}
                                                                                                {currencySymbolRight()}
                                                                                                </span>
                                                                                            }
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                : ""
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    
                                                })
                                                : ""
                                            }
                                            </div>
                                            <div class="card-footer bg-transparent text-right px-4">
                                                <button disabled={loading} class="btn btn-primary mr-1" onClick={(e)=> handleBillSplit(e)}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    </>
  )
}

export default SplitBill
