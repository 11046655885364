import React, { useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

//functions
import {
  _t,
  getCookie,
  tableLoading,
  formatPrice,
  getSystemSettings,
  customStyle
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import moment from 'moment';
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { UserContext } from "../../../../contexts/User";

const SalesSummaryReport = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const inputProps = {
    placeholder: 'To Time',
  };

  const inputProp = {
    placeholder: 'From Time',
  };
  //getting context values here
  let { loading, setLoading, dataPaginating, generalSettings } = useContext(SettingsContext);
  let { branchForSearch, orderTypeList, paymentTypeForSearch } = useContext(RestaurantContext);
  const { partyForSearch, authUserInfo } = useContext(UserContext);

  let [newSalesSummaryReport, setNewSalesSummaryReport] = useState(null);

  //all data
  const [reportData, setReportData] = useState(null);
  const [zReportData, setZReportData] = useState(null);
  const [branchReportData, setBranchReportData] = useState(null);
  const [branchDeliveryTypeReportData, setBranchDeliveryTypeReportData] = useState(null);
  const [branchTypeReportData, setBranchTypeReportData] = useState(null);
  const [branchTakewayTypeReportData, setBranchTakewayReportData] = useState(null);
  const [branchDineTypeReportData, setBranchDineReportData] = useState(null);

  const componentRef = useRef();
  const componentRef1 = useRef();
  const componentRefThermal = useRef();

  const [time,setTime] = useState({
    fromTime: null,
    toTime: null,
    key: false,
  });

  const groupByArray = [
    { id: "Branch", name: "Branch" },
    { id: "Order Types", name: "Order Types" },
    { id: "Payment Types", name: "Payment Types" },
  ];
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);
  // const [sumPrice, setSumPrice] = useState(0);
  // const [sumTotalPrice, setSumTotalPrice] = useState(0);
  const [siteName, setSiteName] = useState("");

  //useEffect == componentDidMount()
  const [branchSearch, setBranchSearch] = useState(null);

  useEffect(() => {
    setSiteName(getSystemSettings(generalSettings, "siteName"));
    if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
      setBranchSearch(
        branchForSearch.filter(
          (branch) => branch.id === authUserInfo.details.branch_id
        )
      );
    } else {
      setBranchSearch(branchForSearch);
    }

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [generalSettings]);

  const handleBranch = (branch) => {
    setNewSalesSummaryReport({ ...newSalesSummaryReport, branch });
    // setBranch(branch);
  };
  const handleGroup = (group) => {
    setNewSalesSummaryReport({ ...newSalesSummaryReport, group });
    //setGroup(group);
  };
  const handleItem = (item) => {
    setNewSalesSummaryReport({ ...newSalesSummaryReport, item });
    //setItem(item);
  };
  const handleParty = (party) => {
    setNewSalesSummaryReport({ ...newSalesSummaryReport, party });
    //setParty(party);
  };
  const handleOrderTypes = (orderTypes) => {
    setNewSalesSummaryReport({ ...newSalesSummaryReport, orderTypes });
    //setOrderTypes(orderTypes);
  };
  const handlePaymentTypes = (paymentTypes) => {
    setNewSalesSummaryReport({ ...newSalesSummaryReport, paymentTypes });
    //setPaymentTypes(paymentTypes);
  };
  const handleGroupBy = (groupBy) => {
    setNewSalesSummaryReport({ ...newSalesSummaryReport, groupBy });
    //setGroupBy(groupBy);
  };

  //get BranchWise reports filter
  const getSalesListing = () => {
    setReportData(null);
    if (
      startDate !== null &&
      endDate !== null
    ) {
      setLoading(true);
      const fromDate = moment(startDate).format("YYYY-MM-DD");
      const toDate =  moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/sales-listing";
      let formData = new FormData();
      formData.append("from_date", fromDate);
      formData.append("to_date", toDate);
      formData.append("branch_id", newSalesSummaryReport?.branch?.id || "");
      formData.append("key", getSystemSettings(generalSettings, "sales_listing_key"));

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          history.push({
            pathname: '/sales-listing',
            state: { data: res.data },
          });
          setLoading(false);
          setTime({
            fromTime:null,
            toTime:null,
            key: !time.key,
          })

        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select from date, to date to generate report"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  //get BranchWise reports filter
  const getBranchWiseSelected = () => {
    setReportData(null);
    if (
      authUserInfo.details.user_type === "staff" &&
      newSalesSummaryReport?.branch?.id === undefined
    ) {
      toast.error(`${_t(t("Please select Branch to generate report"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return;
    }

    if (
      // newSalesSummaryReport?.branch?.id !== null &&
      startDate !== null &&
      endDate !== null &&
      newSalesSummaryReport?.groupBy?.id
    ) {
      setLoading(true);
      let fromDate = moment(startDate).format("YYYY-MM-DD");
      let toDate =  moment(endDate).format("YYYY-MM-DD");
      let  fromTime = time.fromTime ? time.fromTime.format('HH:mm A') : null;
      let toTime = time.toTime ? time.toTime.format('HH:mm A') : null;

      const url = BASE_URL + "/settings/get-sales-summary-report";
      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("fromTime", fromTime);
      formData.append("toTime", toTime);
      formData.append("branch_id", newSalesSummaryReport?.branch?.id);
      formData.append("order_type_id", newSalesSummaryReport?.orderTypes?.id);
      formData.append("party_id", newSalesSummaryReport?.party?.id);
      formData.append(
        "payment_type_name",
        newSalesSummaryReport?.paymentTypes?.name
      );
      formData.append("group_by", newSalesSummaryReport?.groupBy?.id);

      console.log("formData ", formData);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          console.log("SalesSummaryReportResult", res);
          setReportData(res.data[0]);

          setLoading(false);
          console.log("Component ", componentRef);
          setGeneratedReport(true);
          componentRef.current.handleDownload();
          setNewSalesSummaryReport(null);
          setTime({
            fromTime:null,
            toTime:null,
            key: !time.key,
          })
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(
          t(
            "Please select from date, to date, branch and report group to generate report"
          )
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  //get BranchWise reports filter
  const getBranchWiseSaleSummary = () => {
    setBranchReportData(null);
    setBranchDeliveryTypeReportData(null);
    setBranchTypeReportData(null);
    setBranchTakewayReportData(null);
    setBranchDineReportData(null)

    if (
      authUserInfo.details.user_type === "staff" &&
      newSalesSummaryReport?.branch?.id === undefined
    ) {
      toast.error(`${_t(t("Please select Branch to generate report"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return;
    }

    if (
      // newSalesSummaryReport?.branch?.id !== null &&
      startDate !== null &&
      endDate !== null &&
      newSalesSummaryReport?.groupBy?.id
    ) {
      setLoading(true);
      let fromDate = moment(startDate).format("YYYY-MM-DD");
      let toDate =  moment(endDate).format("YYYY-MM-DD");
      let  fromTime = time.fromTime ? time.fromTime.format('HH:mm A') : null;
      let toTime = time.toTime ? time.toTime.format('HH:mm A') : null;

      const url = BASE_URL + "/settings/get-branch-sales-report";
      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("fromTime", fromTime);
      formData.append("toTime", toTime);
      formData.append("branch_id", newSalesSummaryReport?.branch?.id);
      formData.append("order_type_id", newSalesSummaryReport?.orderTypes?.id);
      formData.append("party_id", newSalesSummaryReport?.party?.id);
      formData.append(
        "payment_type_name",
        newSalesSummaryReport?.paymentTypes?.name
      );
      formData.append("group_by", newSalesSummaryReport?.groupBy?.id);

      console.log("formData ", formData);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setBranchReportData(res.data[0]);
          setBranchTypeReportData(res.data[1]);
          setBranchDeliveryTypeReportData(res.data[2]);
          setBranchTakewayReportData(res.data[3]);
          setBranchDineReportData(res.data[4]);
          setLoading(false);
          setGeneratedReport(true);
          componentRef1.current.handleDownload();
          setNewSalesSummaryReport(null);
          setTime({
            fromTime:null,
            toTime:null,
            key: !time.key,
          })
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(
          t(
            "Please select from date, to date, branch and report group to generate report"
          )
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  const thermalPrint = useReactToPrint({
    content: () => componentRefThermal.current,
  });

  //get BranchWise reports filter
  const getThermalSelected = () => {
    setReportData(null);
    
    if (
      (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") &&
      newSalesSummaryReport?.branch?.id === undefined
    ) {
      toast.error(`${_t(t("Please select Branch to generate report"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      return;
    }

    if (
      // newSalesSummaryReport?.branch?.id !== null &&
      startDate !== null &&
      endDate !== null &&
      newSalesSummaryReport?.groupBy?.id
    ) {
      setLoading(true);
      let fromDate = moment(startDate).format("YYYY-MM-DD");
      let toDate =  moment(endDate).format("YYYY-MM-DD");
      let  fromTime = time.fromTime ? time.fromTime.format('HH:mm A') : null;
      let toTime = time.toTime ? time.toTime.format('HH:mm A') : null;
      
      const url = BASE_URL + "/settings/get-boc-sales-report";
      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("fromTime", fromTime);
      formData.append("toTime", toTime);
      formData.append("branch_id", newSalesSummaryReport?.branch?.id);
      formData.append("order_type_id", newSalesSummaryReport?.orderTypes?.id);
      formData.append("party_id", newSalesSummaryReport?.party?.id);
      formData.append(
        "payment_type_name",
        newSalesSummaryReport?.paymentTypes?.name
      );
      formData.append("group_by", newSalesSummaryReport?.groupBy?.id);

      console.log("formData ", formData);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setZReportData(res.data[0])
          setLoading(false);
          thermalPrint();
          setNewSalesSummaryReport(null);
          setTime({
            fromTime:null,
            toTime:null,
            key: !time.key,
          })
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(
          t(
            "Please select from date, to date, branch and report group to generate report"
          )
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  let total = [];

  const calculateTotal = (value,index) =>{
    if((typeof (value) === 'number')){
      total[index] = (total[index] || 0) + value;
    }
  }

  
  let total_price = 0;
  let grand_total = 0;
  let tax_total = 0;
  let g_tax = 0 ;
  let g_discount = 0
  let g_delCharges = 0;
  let g_serCharges = 0;
  let g_afterDiscount = 0;
  let g_order_tip = 0;
  let g_pos_fee = 0;
  let g_total_guest = 0;

  ///print totals
  let salesAvg = 0;
  let salesAmount = 0;
  let salesCount = 0;
  let disUnclaimAvg = 0;
  let salesBreakUp = 0;
  let disUnclaimAmount = 0;
  let disUnclaimCount = 0;
  let disAvg = 0;
  let disAmount = 0;
  let disCount = 0;
  let tenderAvg = 0;
  let tenderAmount = 0;
  let tenderCount = 0;
  let saleTypeAvg = 0;
  let saleTypeAmount = 0;
  let saleTypeCount = 0;
  let deliveryAvg = 0;
  let deliveryAmount = 0;
  let deliveryCount = 0;
  let paymentModeAvg = 0;
  let paymentModeAmount = 0;
  let paymentModeCount = 0;

  return (
    <>
      <Helmet>
        <title>{_t(t("Sales Summary Report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="Sales Summary Report"
        sheet="sheet 1"
        buttonText="Sales Detail"
        ref={componentRef}
      />

      <ReactHTMLTableToExcel
        id="table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-sale"
        filename="Sales Summary Branch Report"
        sheet="sheet 1"
        buttonText="Sale Detail"
        ref={componentRef1}
      />

      {/* Print branch wise Button */}
      <div className="d-none">
        {branchReportData !== null && branchReportData &&
          branchReportData !== undefined &&
          generatedReport === true && (
            <>
              <table
                className="table table-bordered"
                id="table-to-sale"
              >
                <thead className="align-middle">
                  <tr style={{ border: "0px" }}>
                    <th colspan={Object.keys(branchReportData[0])?.length || "14"} style={{ border: "0px" }}>
                      <div className="row">
                        <div className="col-12">
                          <h3 className="text-center mb-0">
                            {siteName}
                          </h3>
                          <h3 className="text-center mt-0">
                            Sales Summary Branch Wise Report
                          </h3>
                          <p className="text-center">
                            FROM&nbsp;
                            <Moment format="LL">
                              {startDate}
                            </Moment>
                            &nbsp;TO&nbsp;
                            <Moment format="LL">{endDate}</Moment>
                          </p>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    { branchReportData?.length &&
                      Object.keys(branchReportData[0]).map((name) =>{
                        return (
                          <th
                            scope="col"
                          >
                            {_t(t(name))}
                          </th>
                        )
                      })
                    }
                  </tr>
                </thead>
                <tbody className="align-middle">
                  
                  {/* loop here*/}
                  {branchReportData?.length && branchReportData.map((item) => {
                      return (
                        <tr>
                          {
                            Object.keys(branchReportData[0]).map((name,index)=>{
                              if(index > 0){
                                calculateTotal(parseFloat(item[name]),index-1)
                              }
                              return (
                                <td
                                  scope="row"
                                >
                                  {item[name]}
                                </td>
                              )
                            })
                          }
                        </tr>
                      );
                    }
                  )}
                  <tr>
                    <th>
                      Total
                    </th>
                    {
                      total && total.map(item =>{
                        return (
                          <td>
                            {formatPrice(item)}
                          </td>
                        )
                      })
                    }
                  </tr>

                  <tr></tr>

                  <tr>
                    <th>
                      Service Type
                    </th>
                    <th>
                      No of Invoice
                    </th>
                    <th>
                      Net Amount
                    </th>
                  </tr>
                  {(() => {
                    total = []; 
                    return branchTypeReportData && branchTypeReportData.map((item) =>{
                      calculateTotal(parseFloat(item["Total Orders"]),0);
                      calculateTotal(parseFloat(item["Net Sales"]),1)

                      return (
                        <tr>
                          <th>
                            {item["Type"]}
                          </th>
                          <td>
                            {item["Total Orders"]}
                          </td>
                          <td>
                            {item["Net Sales"]}
                          </td>
                        </tr>
                        )

                    })
                  })()}

                  <tr>
                    <th>
                      Total
                    </th>
                    {
                      total && total.map(item =>{
                        return (
                          <td>
                            {formatPrice(item)}
                          </td>
                        )
                      })
                    }
                  </tr>

                  <tr></tr>

                  <tr>
                    <th>
                      Delivery Channel
                    </th>
                    <th>
                      No of Invoice
                    </th>
                    <th>
                      Net Amount
                    </th>
                  </tr>

                  {(() => {
                    total = [];
                    return branchDeliveryTypeReportData && branchDeliveryTypeReportData.map((item) =>{
                      calculateTotal(parseFloat(item["Total Orders"]),0);
                      calculateTotal(parseFloat(item["Net Sales"]),1)

                      return (
                        <tr>
                          <th>
                            {item["Party Type"]}
                          </th>
                          <td>
                            {item["Total Orders"]}
                          </td>
                          <td>
                            {item["Net Sales"]}
                          </td>
                        </tr>
                        )

                    })
                  })()}

                  <tr>
                    <th>
                      Total
                    </th>
                    {
                      total && total.map(item =>{
                        return (
                          <td>
                            {formatPrice(item)}
                          </td>
                        )
                      })
                    }
                  </tr>

                  <tr></tr>

                  <tr>
                    <th>
                      Takeaway Channel
                    </th>
                    <th>
                      No of Invoice
                    </th>
                    <th>
                      Net Amount
                    </th>
                  </tr>

                  {(() => {
                    total = []; 
                    return branchTakewayTypeReportData && branchTakewayTypeReportData.map((item) =>{
                      calculateTotal(parseFloat(item["Total Orders"]),0);
                      calculateTotal(parseFloat(item["Net Sales"]),1)

                      return (
                        <tr>
                          <th>
                            {item["Party Type"]}
                          </th>
                          <td>
                            {item["Total Orders"]}
                          </td>
                          <td>
                            {item["Net Sales"]}
                          </td>
                        </tr>
                        )

                    })
                  })()}

                  <tr>
                    <th>
                      Total
                    </th>
                    {
                      total && total.map(item =>{
                        return (
                          <td>
                            {formatPrice(item)}
                          </td>
                        )
                      })
                    }
                  </tr>

                  <tr></tr>
                  
                  <tr>
                    <th>
                      Dine-In Channel
                    </th>
                    <th>
                      No of Invoice
                    </th>
                    <th>
                      Net Amount
                    </th>
                  </tr>

                  {(() => {
                    total = []; 
                    return branchDineTypeReportData && branchDineTypeReportData.map((item) =>{
                      calculateTotal(parseFloat(item["Total Orders"]),0);
                      calculateTotal(parseFloat(item["Net Sales"]),1)

                      return (
                        <tr>
                          <th>
                            {item["Party Type"]}
                          </th>
                          <td>
                            {item["Total Orders"]}
                          </td>
                          <td>
                            {item["Net Sales"]}
                          </td>
                        </tr>
                        )

                    })
                  })()}

                  <tr>
                    <th>
                      Total
                    </th>
                    {
                      total && total.map(item =>{
                        return (
                          <td>
                            {formatPrice(item)}
                          </td>
                        )
                      })
                    }
                  </tr>
                    
                </tbody>
              </table>
            </>
        )}
      </div>       

      {/* thermal print */}
      <div className="d-none">
        <div ref={componentRefThermal}>
          {
            zReportData !== null && (
              <div className="fk-print">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                    <span className="mt-2 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                      Sales Summary Report
                    </span>
                    {
                      newSalesSummaryReport && newSalesSummaryReport?.branch && (
                        <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                          {newSalesSummaryReport.branch?.name}
                        </span>
                      )
                    }
                    <div>
                    <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                    FROM&nbsp;
                    <Moment format="LL">
                        {startDate}
                      </Moment>&nbsp;{time.fromTime ? time.fromTime.format('hh:mm A') : null}
                      &nbsp;TO&nbsp;
                      <Moment format="LL">{endDate}</Moment>&nbsp;{time.toTime ? time.toTime.format('hh:mm A') : null}
                    </span>
                    </div>
                    <div>
                    <span className="mt-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                      Print Time:{" "} 
                      <Moment format="LLL">
                        {new Date()}
                      </Moment>
                    </span>
                    </div>
                    <div>
                    <span className="mt-1 mb-1 d-block fk-print-text_pos font-weight-bold text-uppercase text-center table-text">
                      New Shift Detail
                    </span>
                </div>
              {/*ordertype catg wise report */}
              <div className="col-12">
              {
                zReportData &&
                zReportData !== null && (
                <div>
                  <p className="mb-1 xsm-text fk-print-text_pos text-capitalize d-flex justify-content-between border border-dark mx-1 font-weight-bold border-1">
                    <span className="font-weight-bold mx-2" style={{width:"40.5%"}}>
                    {zReportData &&
                      zReportData !== null &&
                      "Sales Summary"}
                      </span>
                      
                      <span className="font-weight-bold mx-2"  style={{width:"22.5%"}}>
                      {zReportData &&
                        zReportData !== null &&
                        "Amt"}
                      </span>
        
                      <span className="font-weight-bold mx-2" style={{width:"19%"}}>
                    
                      {zReportData &&
                        zReportData !== null &&
                        "Inv Count"}
                      </span>
        
                      <span className="font-weight-bold mx-2" style={{width:"18%",textAlign:"right"}}>
                      {zReportData &&
                        zReportData !== null &&
                        "Avg"}
                      </span>
                    </p>
                  </div>
                  )}
                
                  {
                    zReportData && zReportData["Sales Summary"] && typeof(zReportData["Sales Summary"]) === "object" && Object.values(zReportData["Sales Summary"])?.length > 0 &&(
                      <div>
                        {
                          Object.keys(zReportData["Sales Summary"]).map((report,index)=>{
                            let avg = zReportData["Sales Summary"][report].count !== 0 ? zReportData["Sales Summary"][report].amount / zReportData["Sales Summary"][report].count : 0;
                            salesAvg += avg;
                            salesAmount += parseFloat(zReportData["Sales Summary"][report].amount);
                            salesCount += zReportData["Sales Summary"][report].count
                          return(
                            <div className={`mb-0 fk-print-text_pos sm-text  text-capitalize d-flex justify-content-between w-100 ${index === Object.values(zReportData["Sales Summary"]).length - 1 && "border border-1 border-dark mb-1 fk-print-text_pos--bold"}`}>
                              <span className="mx-2 "  style={{width:"41%"}}>{report}</span>
                              <span className="mx-2 "  style={{width:"24%"}}>{parseFloat(zReportData["Sales Summary"][report].amount).toFixed(2)}</span>
                              <span className="mx-2 "  style={{width:"17%"}}>{zReportData["Sales Summary"][report].count}</span>
                              <span className="mx-2 " style={{width:"18%",textAlign:"right"}}>{avg.toFixed(0)}</span>
                            </div>
                          )
                        })
                        }
                        {
                          /*
                        
                        <div className=" sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100 border-1 border border-dark mb-1">
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"41%"}}>Total</span>
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"24%"}}>{salesAmount.toFixed(2)}</span>
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"17%"}}>{salesCount}</span>
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"18%",textAlign:"right"}}>{salesAvg.toFixed(0)}</span>
                        </div>
                          */
                        }
                      </div>
                    ) 
                  }

                  {
                    zReportData && zReportData["Sales Payments"] && typeof(zReportData["Sales Payments"]) === "object" && Object.values(zReportData["Sales Payments"])?.length > 0 && (
                      <div className="w-100 col-12 ">
                      <span className="mx-2 d-flex fk-print-text_pos font-weight-bold justify-content-start print-text">Sales Payments</span>

                        { Object.keys(zReportData["Sales Payments"]).map((report,i)=>{
                          let total =parseFloat(zReportData["Sales Payments"][report]["Gross Sales"]) + parseFloat(zReportData["Sales Payments"][report]["Delivery Charges"])
                          + parseFloat(zReportData["Sales Payments"][report]["Sales Tax"]) - parseFloat(zReportData["Sales Payments"][report]["Total Discount"]);
                          salesBreakUp += total;
                          return(
                            <>
                              <span className="mx-2 d-flex fk-print-text_pos font-weight-md justify-content-center border-dark border-bottom border-1 print-text border-top">{report}</span>
                              <div className="mb-0 sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100">
                                <span className="mx-2"  style={{width:"41%"}}>Gross Sales</span>
                                <span className="mx-2"  style={{width:"24%"}}>{parseFloat(zReportData["Sales Payments"][report]["Gross Sales"]).toFixed(2)}</span>
                                <span className="mx-2"  style={{width:"17%"}}>0</span>
                                <span className="mx-2"  style={{width:"18%",textAlign:"right"}}>0</span>
                              </div>
                              <div className="mb-0 sm-text fk-print-text_pos  text-capitalize d-flex justify-content-between w-100">
                                <span className="mx-2"  style={{width:"41%"}}>Total Discount</span>
                                <span className="mx-2"  style={{width:"24%"}}>{parseFloat(zReportData["Sales Payments"][report]["Total Discount"]).toFixed(2)}</span>
                                <span className="mx-2"  style={{width:"17%"}}>0</span>
                                <span className="mx-2"  style={{width:"18%",textAlign:"right"}}>0</span>
                              </div>
                              <div className="mb-0 sm-text fk-print-text_pos  text-capitalize d-flex justify-content-between w-100">
                                <span className="mx-2"  style={{width:"41%"}}>Delivery Charges</span>
                                <span className="mx-2"  style={{width:"24%"}}>{parseFloat(zReportData["Sales Payments"][report]["Delivery Charges"]).toFixed(2)}</span>
                                <span className="mx-2"  style={{width:"17%"}}>0</span>
                                <span className="mx-2"  style={{width:"18%",textAlign:"right"}}>0</span>
                              </div>
                              <div className="mb-0 sm-text fk-print-text_pos  text-capitalize d-flex justify-content-between w-100">
                                <span className="mx-2 "  style={{width:"41%"}}>Sales Tax</span>
                                <span className="mx-2 "  style={{width:"24%"}}>{parseFloat(zReportData["Sales Payments"][report]["Sales Tax"]).toFixed(2)}</span>
                                <span className="mx-2 "  style={{width:"17%"}}>0</span>
                                <span className="mx-2 "  style={{width:"18%",textAlign:"right"}}>0</span>
                              </div>
                              <div className=" sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100 border-1 border border-dark mb-1">
                                <span className="mx-2 fk-print-text_pos--bold"  style={{width:"41%"}}>Total</span>
                                <span className="mx-2 fk-print-text_pos--bold"  style={{width:"24%"}}>{total.toFixed(2)}</span>
                                <span className="mx-2 fk-print-text_pos--bold"  style={{width:"17%"}}>0</span>
                                <span className="mx-2 fk-print-text_pos--bold"  style={{width:"18%",textAlign:"right"}}>0</span>
                              </div>
                            </>
                            )
                          })
                        }
                        <div className=" sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100 border-1 border border-dark mb-1">
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"41%"}}>Total</span>
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"24%"}}>{salesBreakUp.toFixed(2)}</span>
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"17%"}}>0</span>
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"18%",textAlign:"right"}}>0</span>
                        </div>
                      </div> 
                    )
                  }

                  {
                    zReportData && zReportData["Discount Summary Claim"] && typeof(zReportData["Discount Summary Claim"]) === "object" && Object.values(zReportData["Discount Summary Claim"])?.length > 0 && (
                      <div className="w-100 col-12 ">
                      <span className="mx-2 d-flex fk-print-text_pos font-weight-bold justify-content-start border-dark border-bottom border-1 print-text">Discount Summary Claim</span>

                        { Object.keys(zReportData["Discount Summary Claim"]).map((report)=>{
                            let avg = zReportData["Discount Summary Claim"][report].count !== 0 ? parseFloat(zReportData["Discount Summary Claim"][report].amount) / zReportData["Discount Summary Claim"][report].count : 0;
                            disAvg += avg;
                            disAmount += parseFloat(zReportData["Discount Summary Claim"][report].amount);
                            disCount += zReportData["Discount Summary Claim"][report].count;
                            
                            return(
                              <div className="mb-0 sm-text fk-print-text_pos  text-capitalize d-flex justify-content-between w-100">
                                <span className="mx-2 "  style={{width:"41%"}}>{report}</span>
                                <span className="mx-2 "  style={{width:"24%"}}>{parseFloat(zReportData["Discount Summary Claim"][report].amount).toFixed(2)}</span>
                                <span className="mx-2 "  style={{width:"17%"}}>{zReportData["Discount Summary Claim"][report].count}</span>
                                <span className="mx-2 "  style={{width:"18%",textAlign:"right"}}>{avg.toFixed(0)}</span>
                              </div>
                            )
                          })
                        }
                        <div className=" sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100 border-1 border border-dark mb-1">
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"41%"}}>Total</span>
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"24%"}}>{disAmount.toFixed(2)}</span>
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"17%"}}>{disCount}</span>
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"18%",textAlign:"right"}}>{(disAmount/disCount).toFixed(0)}</span>
                        </div>
                      </div> 
                    )
                  }

                  {
                    zReportData && zReportData["Discount Summary UnClaim"] && typeof(zReportData["Discount Summary UnClaim"]) === "object" && Object.values(zReportData["Discount Summary UnClaim"])?.length > 0 && (
                      <div className="w-100 col-12 ">
                      <span className="mx-2 d-flex fk-print-text_pos font-weight-bold justify-content-start border-dark border-bottom border-1 print-text">Discount Summary UnClaim</span>

                        { Object.keys(zReportData["Discount Summary UnClaim"]).map((report)=>{
                            let avg = zReportData["Discount Summary UnClaim"][report].count !== 0 ? parseFloat(zReportData["Discount Summary UnClaim"][report].amount) / zReportData["Discount Summary UnClaim"][report].count : 0;
                            disUnclaimAvg += avg;
                            disUnclaimAmount += parseFloat(zReportData["Discount Summary UnClaim"][report].amount);
                            disUnclaimCount += zReportData["Discount Summary UnClaim"][report].count;
                            
                            return(
                              <div className="mb-0 sm-text fk-print-text_pos  text-capitalize d-flex justify-content-between w-100">
                                <span className="mx-2 "  style={{width:"41%"}}>{report}</span>
                                <span className="mx-2 "  style={{width:"24%"}}>{parseFloat(zReportData["Discount Summary UnClaim"][report].amount).toFixed(2)}</span>
                                <span className="mx-2 "  style={{width:"17%"}}>{zReportData["Discount Summary UnClaim"][report].count}</span>
                                <span className="mx-2 "  style={{width:"18%",textAlign:"right"}}>{avg.toFixed(0)}</span>
                              </div>
                            )
                          })
                        }
                        <div className=" sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100 border-1 border border-dark mb-1">
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"41%"}}>Total</span>
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"24%"}}>{disUnclaimAmount.toFixed(2)}</span>
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"17%"}}>{disUnclaimCount}</span>
                          <span className="mx-2 fk-print-text_pos--bold"  style={{width:"18%",textAlign:"right"}}>{(disUnclaimAmount/disUnclaimCount).toFixed(0)}</span>
                        </div>
                      </div> 
                    )
                  }
                  
                  {
                    zReportData && zReportData["un_tender_summary"] && typeof(zReportData["un_tender_summary"]) === "object" && Object.values(zReportData["un_tender_summary"])?.length > 0 && (
                      <div className="w-100 col-12 ">
                      <span className="mx-2 d-flex fk-print-text_pos font-weight-bold justify-content-start border-dark border-bottom border-1 print-text">UnTender Summary</span>

                          { Object.keys(zReportData["un_tender_summary"]).map((report)=>{
                              let avg = zReportData["un_tender_summary"][report].count !== 0 ? parseFloat(zReportData["un_tender_summary"][report].amount) / zReportData["un_tender_summary"][report].count : 0;
                              tenderAvg += avg;
                              tenderAmount +=  parseFloat(zReportData["un_tender_summary"][report].amount);;
                              tenderCount += zReportData["un_tender_summary"][report].count;
                              
                              return(
                                <div className="mb-0 sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100">
                                  <span className="mx-2 "  style={{width:"41%"}}>{report}</span>
                                  <span className="mx-2 "  style={{width:"24%"}}>{parseFloat(zReportData["un_tender_summary"][report].amount).toFixed(2)}</span>
                                  <span className="mx-2 "  style={{width:"17%"}}>{zReportData["un_tender_summary"][report].count}</span>
                                  <span className="mx-2 "  style={{width:"18%",textAlign:"right"}}>{avg.toFixed(0)}</span>
                                </div>
                              )
                            })
                          }
                        
                          <div className=" sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100 border-1 border border-dark mb-1">
                            <span className="mx-2 fk-print-text_pos--bold"  style={{width:"41%"}}>Total</span>
                            <span className="mx-2 fk-print-text_pos--bold"  style={{width:"24%"}}>{tenderAmount.toFixed(2)}</span>
                            <span className="mx-2 fk-print-text_pos--bold"  style={{width:"17%"}}>{tenderCount}</span>
                            <span className="mx-2 fk-print-text_pos--bold"  style={{width:"18%",textAlign:"right"}}>{(tenderAmount/tenderCount).toFixed(0)}</span>
                          </div>
                        </div> 
                      )
                    }

                  {
                    zReportData && zReportData["Sales Type"] && typeof(zReportData["Sales Type"]) === "object" && Object.values(zReportData["Sales Type"])?.length > 0 &&(
                      <div className="w-100 col-12 ">
                      <span className="mx-2 d-flex fk-print-text_pos font-weight-bold justify-content-start border-dark border-bottom border-1 print-text">Sales Type</span>
                  
                      { Object.keys(zReportData["Sales Type"]).map((report)=>{
                          let avg = zReportData["Sales Type"][report].count !== 0 ? parseFloat(zReportData["Sales Type"][report].amount) / zReportData["Sales Type"][report].count : 0;
                          saleTypeAvg += avg;
                          saleTypeAmount += parseFloat(zReportData["Sales Type"][report].amount);
                          saleTypeCount += zReportData["Sales Type"][report].count;
                          return(
                            <div className="mb-0 sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100">
                            <span className="mx-2 "  style={{width:"41%"}}>{report}</span>
                            <span className="mx-2 "  style={{width:"24%"}}>{parseFloat(zReportData["Sales Type"][report].amount).toFixed(2)}</span>
                            <span className="mx-2 "  style={{width:"17%"}}>{zReportData["Sales Type"][report].count}</span>
                            <span className="mx-2 "  style={{width:"18%",textAlign:"right"}}>{avg.toFixed(0)}</span>
                            </div>
                          )
                        })
                      }
                      <div className=" sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100 border-1 border border-dark mb-1">
                        <span className="mx-2 fk-print-text_pos--bold"  style={{width:"41%"}}>Total</span>
                        <span className="mx-2 fk-print-text_pos--bold"  style={{width:"24%"}}>{saleTypeAmount.toFixed(2)}</span>
                        <span className="mx-2 fk-print-text_pos--bold"  style={{width:"17%"}}>{saleTypeCount}</span>
                        <span className="mx-2 fk-print-text_pos--bold"  style={{width:"18%",textAlign:"right"}}>{(saleTypeAmount/saleTypeCount).toFixed(0)}</span>
                      </div>
                  </div> 
                  )
                }


                  {
                    zReportData && zReportData["Delivery Breakup"] && typeof(zReportData["Delivery Breakup"]) === "object" && Object.keys(zReportData["Delivery Breakup"])?.length > 0 &&(
                      <div className="w-100 col-12 ">
                      <span className="mx-2 d-flex fk-print-text_pos  font-weight-bold justify-content-start border-dark border-bottom border-1 print-text">Delivery Breakup</span>

                      { Object.keys(zReportData["Delivery Breakup"]).map((report)=>{
                          let avg = zReportData["Delivery Breakup"][report].count !== 0 ? parseFloat(zReportData["Delivery Breakup"][report].amount) / zReportData["Delivery Breakup"][report].count : 0;
                          deliveryAvg += avg;
                          deliveryAmount += parseFloat(zReportData["Delivery Breakup"][report].amount);
                          deliveryCount += zReportData["Delivery Breakup"][report].count;
                          return(
                            <div className="mb-0 sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100">
                            <span className="mx-2 "  style={{width:"41%"}}>{report}</span>
                            <span className="mx-2 "  style={{width:"24%"}}>{parseFloat(zReportData["Delivery Breakup"][report].amount).toFixed(2)}</span>
                            <span className="mx-2 "  style={{width:"17%"}}>{zReportData["Delivery Breakup"][report].count}</span>
                            <span className="mx-2 " style={{width:"18%",textAlign:"right"}}>{avg.toFixed(0)}</span>
                            </div>
                          )
                        })
                      }

                      <div className=" sm-text fk-print-text_pos  text-capitalize d-flex justify-content-between w-100 border-1 border border-dark mb-1">
                        <span className="mx-2 fk-print-text_pos--bold"  style={{width:"41%"}}>Total</span>
                        <span className="mx-2 fk-print-text_pos--bold"  style={{width:"24%"}}>{deliveryAmount.toFixed(2)}</span>
                        <span className="mx-2 fk-print-text_pos--bold"  style={{width:"17%"}}>{deliveryCount}</span>
                        <span className="mx-2 fk-print-text_pos--bold" style={{width:"18%",textAlign:"right"}}>{(deliveryAmount/deliveryCount).toFixed(0)}</span>
                      </div>
                    </div> 
                    )
                  }

                  {
                    zReportData && zReportData["Payment Mode"] && typeof(zReportData["Payment Mode"]) === "object" && Object.values(zReportData["Payment Mode"])?.length > 0 &&(
                      <div className="w-100 col-12 ">
                      <span className="mx-2 d-flex fk-print-text_pos  font-weight-bold justify-content-start border-dark border-bottom border-1 print-text">Payment Mode</span>

                      { Object.keys(zReportData["Payment Mode"]).map((report)=>{
                          let avg = zReportData["Payment Mode"][report].count !== 0 ?  parseFloat(zReportData["Payment Mode"][report].amount) / zReportData["Payment Mode"][report].count : 0;
                          paymentModeAvg += avg;
                          paymentModeAmount += parseFloat(zReportData["Payment Mode"][report].amount);
                          paymentModeCount += zReportData["Payment Mode"][report].count ;
                          return(
                            <div className="mb-0 sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100">
                              <span className="mx-2 "  style={{width:"41%"}}>{report}</span>
                              <span className="mx-2 "  style={{width:"24%"}}>{parseFloat(zReportData["Payment Mode"][report].amount).toFixed(2)}</span>
                              <span className="mx-2 "  style={{width:"17%"}}>{zReportData["Payment Mode"][report].count}</span>
                              <span className="mx-2 " style={{width:"18%",textAlign:"right"}}>{avg.toFixed(0)}</span>
                            </div>
                          )
                        })
                      }
                      <div className=" sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100 border-1 border border-dark mb-1">
                        <span className="mx-2 fk-print-text_pos--bold"  style={{width:"41%"}}>Total</span>
                        <span className="mx-2 fk-print-text_pos--bold"  style={{width:"24%"}}>{paymentModeAmount.toFixed(2)}</span>
                        <span className="mx-2 fk-print-text_pos--bold"  style={{width:"17%"}}>{paymentModeCount}</span>
                        <span className="mx-2 fk-print-text_pos--bold" style={{width:"18%",textAlign:"right"}}>{(paymentModeAmount/paymentModeCount).toFixed(0)}</span>
                      </div>
                  </div> 
                  )
                }

              
                  {
                    zReportData && zReportData["Payments"] && typeof(zReportData["Payments"]) === "object" && Object.values(zReportData["Payments"])?.length > 0 && Object.keys(zReportData["Payments"]).map((report,index)=>{
                      let paymentAvg = 0;
                      let paymentAmount = 0;
                      let paymentCount = 0;
                      return(
                        <div className="w-100 col-12" key={index}>
                        <span className="mx-2 d-flex fk-print-text_pos font-weight-bold justify-content-start border-dark border-bottom border-1 print-text">{report}</span>
                        {
                          typeof(zReportData["Payments"][report]) === "object" && Object.keys(zReportData["Payments"][report]).map((payment)=>{
                            let avg = zReportData["Payments"][report][payment].count !== 0 ?  parseFloat(zReportData["Payments"][report][payment].amount) / zReportData["Payments"][report][payment].count : 0;
                            paymentAvg += avg;
                            paymentAmount += parseFloat(zReportData["Payments"][report][payment].amount);
                            paymentCount += zReportData["Payments"][report][payment].count;
                            return(
                              <div className="mb-0 sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100">
                              <span className="mx-2 "  style={{width:"41%"}}>{payment}</span>
                              <span className="mx-2 "  style={{width:"24%"}}>{parseFloat(zReportData["Payments"][report][payment].amount).toFixed(2)}</span>
                              <span className="mx-2 "  style={{width:"17%"}}>{zReportData["Payments"][report][payment].count}</span>
                              <span className="mx-2 " style={{width:"18%",textAlign:"right"}}>{avg.toFixed(0)}</span>
                              </div>
                            )
                          })
                        }
                        <div className="sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100 border-1 border border-dark mb-1">
                            <span className="mx-2 fk-print-text_pos--bold"  style={{width:"41%"}}>Total</span>
                            <span className="mx-2 fk-print-text_pos--bold"  style={{width:"24%"}}>{paymentAmount.toFixed(2)}</span>
                            <span className="mx-2 fk-print-text_pos--bold"  style={{width:"17%"}}>{paymentCount}</span>
                            <span className="mx-2 fk-print-text_pos--bold" style={{width:"18%",textAlign:"right"}}>{(paymentAmount/paymentCount).toFixed(0)}</span>
                        </div>
                      </div>
                    )})
                  }

                  {
                    zReportData && zReportData["Void Summary"] && typeof(zReportData["Void Summary"]) === "object" && (
                      <div className="w-100 col-12 ">
                      <span className="mx-2 d-flex fk-print-text_pos  font-weight-bold justify-content-start border-dark border-bottom border-1 print-text">Void Summary</span>
                      </div> 
                    )
                  }

                  {
                    zReportData && zReportData["Void Summary"] && typeof(zReportData["Void Summary"]) === "object" && Object.keys(zReportData["Void Summary"]).map((report)=>{
                      let avg = zReportData["Void Summary"][report].count !== 0 ?  parseFloat(zReportData["Void Summary"][report].amount) / zReportData["Void Summary"][report].count : 0
                      return(
                        <div className="mb-0 sm-text fk-print-text_pos text-capitalize d-flex justify-content-between w-100">
                        <span className="mx-2 "  style={{width:"41%"}}>{report}</span>
                        <span className="mx-2 "  style={{width:"24%"}}>{parseFloat(zReportData["Void Summary"][report].amount).toFixed(2)}</span>
                        <span className="mx-2 "  style={{width:"17%"}}>{zReportData["Void Summary"][report].count}</span>
                        <span className="mx-2 " style={{width:"18%",textAlign:"right"}}>{avg.toFixed(0)}</span>
                        </div>
                      )
                    })
                  }
                </div>
              </div> 
              </div> 
            </div> 
            </div> 
            )
          }
        </div> 
      </div> 
     
      {/* Print Button */}
      <div className="d-none">
        <div>
        {
          /*  
  
          <style type="text/css" media="print">
            {
              "\
          @page { size: landscape; }\
        "
            }
          </style>
          */}
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null && reportData &&
                    reportData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="41" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Sales Summary Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;TO&nbsp;
                                          <Moment format="LL">{endDate}</Moment>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                  ></th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Order Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Created Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Token"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Order No"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Punching Time"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Dispatch Time"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Branch Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Order Type"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Party Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Customer Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Customer Phone No"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Customer Address"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Rider Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Ref No"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Payment Type 1"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Payment Type 2"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Card No"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Scheme"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Amount"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Discount"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Gross After Discount"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Discount Claimable"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Discount Unclaimable"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Vat"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Delivery Charges"))}
                                  </th>
                                  <th
                                  scope="col"
                                  >
                                    {_t(t("Service Charges"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Total"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("POS Service Fee"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Order Tip"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Total Guest"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("User Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Status"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Reason"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Order Source"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Order Notes"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Waiter Name"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Table Name"))}
                                  </th>
                              </tr>
                              </thead>
                              <tbody className="align-middle">
                                
                                {/* loop here*/}
                                {Object.keys(reportData).map(
                                  (group_name, index) => {
                                    let sum = 0;
                                    let g_total = 0;
                                    let discount = 0;
                                    let delCharges = 0;
                                    let serCharges = 0;
                                    let afterDiscount = 0;
                                    let order_tip = 0;
                                    let pos_fee = 0;
                                    let total_guest = 0;
                                    tax_total = 0;
                                    return (
                                      <div key={index}>
                                        <tr>
                                          <th
                                            scope="row"
                                          >
                                            {group_name}
                                          </th>
                                        </tr>
                                        {reportData[group_name].map(
                                          (item, i) => {
                                            sum += parseFloat(item.order_bill);
                                            total_price += parseFloat(
                                              item.order_bill
                                            );
                                            g_total += parseFloat(
                                              item.total_payable
                                            );
                                            grand_total += parseFloat(
                                              item.total_payable
                                            );
                                            tax_total += parseFloat(item.vat);
                                            g_tax += parseFloat(item.vat)
                                            g_discount += parseFloat(item.discount_amount)
                                            g_delCharges += parseFloat(item.delivery_charges);
                                            g_serCharges += item.service_amount ? parseFloat(item.service_amount) : 0;
                                            discount += parseFloat(item.discount_amount);
                                            delCharges += parseFloat(item.delivery_charges);
                                            serCharges = parseFloat(item.service_amount);
                                            afterDiscount += parseFloat(item.gross_after_discount);
                                            g_afterDiscount += parseFloat(item.gross_after_discount);
                                            order_tip += parseFloat(item?.order_tip);
                                            g_order_tip += parseFloat(item?.order_tip);
                                            pos_fee += parseFloat(item?.fee);
                                            g_pos_fee += parseFloat(item?.fee);
                                            total_guest += parseFloat(item?.total_guest);
                                            g_total_guest += parseFloat(item?.total_guest);

                                            return (
                                              <tr>
                                                <td></td>
                                                <td
                                                  scope="row"
                                                >
                                                  {i + 1}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  <Moment format="LL">
                                                    {item.order_date}
                                                  </Moment>
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  <Moment format="DD-MM-YYYY">
                                                    {item.created_at}
                                                  </Moment>
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.token.id}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.order_no}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.punching_time}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.ready_time}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.branch_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.order_type_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.party_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.customer_name}
                                                </td>
                                                <td
                                                 scope="row"
                                                >
                                                  {item?.delivery_phn_no}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.customer_address}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.delivery_boy_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.ref_no}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.bill_distribution[0]
                                                    ? item.bill_distribution[0].name
                                                    : "-"}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.bill_distribution[0]
                                                    ? item.bill_distribution[0].value
                                                    : "0"}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.bill_distribution[1]
                                                    ? item.bill_distribution[1].name
                                                    : "-"}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.bill_distribution[1]
                                                    ? item.bill_distribution[1].value
                                                    : "0"}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.payment_card_number}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.scheme_name}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.order_bill}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.discount_amount}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.gross_after_discount}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.claimable_amount}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.unclaimable_amount}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.vat}
                                                </td>
                                                <td
                                                  scope="row"
                                                >
                                                  {item.delivery_charges}
                                                </td>
                                                <td
                                                scope="row"
                                              >
                                                {item.service_amount}
                                              </td>
                                              <td
                                                scope="row"
                                              >
                                                {item.total_payable}
                                              </td>
                                              <td
                                                scope="row"
                                              >
                                                {item.fee}
                                              </td>
                                              <td
                                                scope="row"
                                              >
                                                {item?.order_tip}
                                              </td>
                                              <td
                                                scope="row"
                                              >
                                                {item?.total_guest}
                                              </td>
                                              <td
                                                scope="row"
                                              >
                                                {item.user_name}
                                              </td>
                                              <td>
                                              {parseInt(
                                                  item.is_cancelled
                                                ) === 0 ? (
                                                  [
                                                    parseInt(
                                                      item.is_ready
                                                    ) === 1 &&
                                                    parseInt(
                                                      item.is_refund
                                                    ) === 0 && parseInt(item.is_foc) === 0 ? (
                                                      <span>
                                                        {_t(t("Ready"))}
                                                      </span>
                                                    ) : parseInt(
                                                        item.is_refund
                                                      ) === 1 && parseInt(item.is_foc) === 0 ? (
                                                      <span>
                                                        {_t(t("Refund"))}
                                                      </span>
                                                    ) : 
                                                    parseInt(item.is_foc) === 1 ?(
                                                      <span>
                                                      {_t(t("Free of cost"))}
                                                      </span>
                                                    ) :
                                                    (
                                                      <span>
                                                        {_t(t("processing"))}
                                                      </span>
                                                    ),
                                                  ]
                                                ) : (
                                                  <span>
                                                    {_t(t("Cancelled"))}
                                                  </span>
                                                )}
                                              </td>
                                              <td
                                              scope="row"
                                              >
                                                {item.reason ? item.reason : "-"}
                                              </td>
                                              <td
                                              scope="row"
                                              >
                                                {item.order_source ? item.order_source : "-"}
                                              </td>
                                              <td
                                              scope="row"
                                              >
                                                {item?.note_to_rider ? item.note_to_rider : "-"}
                                              </td>
                                              <td
                                              scope="row"
                                              >
                                                {item.waiter_name ? item.waiter_name : "-"}
                                              </td>
                                              <td
                                              scope="row"
                                              >
                                                {item.table_name}
                                              </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr>
                                          <th colspan="21"></th>
                                          <th>
                                            Total
                                          </th>
                                          <td>
                                            {formatPrice(sum)}
                                          </td>
                                          <td>{discount}</td>
                                          <td>{afterDiscount}</td>
                                          <td></td>
                                          <td></td>
                                          <td>
                                            {formatPrice(tax_total)}
                                          </td>
                                          <td>{delCharges}</td>
                                          <td>{serCharges}</td>
                                          <td>
                                            {formatPrice(g_total)}
                                          </td>
                                          <td>{pos_fee}</td>
                                          <td>
                                            {formatPrice(order_tip)}
                                          </td>
                                          <td>
                                            {formatPrice(total_guest)}
                                          </td>
                                        </tr>
                                      </div>
                                    );
                                  }
                                )}
                                {
                                 <>
                                  <tr>
                                    <th colspan="21"></th>
                                    <th>
                                      Grand Total
                                    </th>
                                    <td>
                                      {formatPrice(total_price)}
                                    </td>
                                    <td>{g_discount}</td>
                                    <td>{g_afterDiscount}</td>
                                    <td></td>
                                    <td></td>
                                    <td>{formatPrice(g_tax)}</td>
                                    <td>{g_delCharges}</td>
                                    <td>{g_serCharges}</td>
                                    <td>
                                      {formatPrice(grand_total)}
                                    </td>
                                    <td>{g_pos_fee}</td>
                                    <td>
                                      {formatPrice(g_order_tip)}
                                    </td>
                                    <td>
                                      {formatPrice(g_total_guest)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th colspan="22"></th>                                  
                                    <td>
                                      Gross Amount
                                    </td>
                                    <td>Discount</td>
                                    <td>After Discount</td>
                                    <td></td>
                                    <td></td>
                                    <td>Vat</td>
                                    <td>D.C</td>
                                    <td>S.C</td>
                                    <td>
                                      Total
                                    </td>
                                    <td>
                                      Pos Service Fee
                                    </td>
                                    <td>
                                      Order Tip
                                    </td>
                                    <td>
                                      Total Guest
                                    </td>
                                  </tr>
                                 </>
                                }
                              </tbody>
                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading <div className={`${dataPaginating && "loading"}`}></div>*/}
                    
                    <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                    <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    {/* spin loading ends */}

                    {/* Loading effect 
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      */}
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Sales Summary Report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <div className="col-md-2 d-md-block">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2 t-mb-15 mb-md-0"
                              placeholderText={_t(t("From date"))}
                              shouldCloseOnSelect={true}
                            />
                          </div>
                          <div className="col-md-2 t-mb-15 mb-md-0">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control sm-text py-2"
                              placeholderText={_t(t("To date"))}
                              shouldCloseOnSelect={true}
                            />
                          </div>
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={branchSearch && branchSearch}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              isClearable
                              //value={branch}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleBranch}
                              value={newSalesSummaryReport && newSalesSummaryReport.branch}
                              key={newSalesSummaryReport?.branch}
                              styles={customStyle}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a branch")) + ".."}
                            />
                          </div>
                          <div className="col-md-2 t-mb-15 mb-md-0">
                            <Select
                              options={partyForSearch && partyForSearch}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              isClearable
                              //value={party}
                              classNamePrefix="select"
                              styles={customStyle}
                              className="sm-text "
                              value={newSalesSummaryReport && newSalesSummaryReport.party}
                              onChange={handleParty}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a party")) + ".."}
                            />
                          </div>
                          <div className="col-md-2 t-mb-15 mb-md-0">
                            <Select
                              options={orderTypeList?.data && orderTypeList.data}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              isClearable
                              //value={orderTypes}
                              styles={customStyle}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleOrderTypes}
                              value={newSalesSummaryReport && newSalesSummaryReport.orderTypes}
                              key={newSalesSummaryReport?.orderTypes}
                              maxMenuHeight="200px"
                              placeholder={_t(t("Select a order types")) + ".."}
                            />
                          </div>
                        </div>
                        <div className="row gx-2 mt-2">
                          <>
                            <div className="d-md-block col-md-2">
                            <Datetime
                            inputProps={inputProp}
                            value={time.fromTime}
                            key={time.key}
                            onChange={(date)=>setTime({...time,fromTime:date})}
                            placeholder="From Time"
                            dateFormat={false} // Hide the date picker
                            className="col"
                            timeConstraints={{
                              minutes: { step: 5 }, // Customize time selection steps
                            }}
                            />
                          </div>
                          <div className="d-md-block col-md-2">
                            
                            <Datetime
                            //input={false} // Hide the input field for time selection only
                            inputProps={inputProps}
                            value={time.toTime}
                            key={time.key}
                            onChange={(date)=>setTime({...time,toTime:date})}
                            placeholder="To Time"
                            dateFormat={false} // Hide the date picker
                            className="col"
                            timeConstraints={{
                              minutes: { step: 5 }, // Customize time selection steps
                            }}
                            />
                          </div>
                          </>
                         
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={
                                paymentTypeForSearch && paymentTypeForSearch
                              }
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              isClearable
                              //value={paymentTypes}
                              classNamePrefix="select"
                              className="sm-text "
                              styles={customStyle}
                              value={newSalesSummaryReport && newSalesSummaryReport.paymentTypes}
                              key={newSalesSummaryReport?.paymentTypes}
                              onChange={handlePaymentTypes}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(t("Select a payment type")) + ".."
                              }
                            />
                          </div>
                          <div className="col-md-4 t-mb-15 mb-md-0">
                            <Select
                              options={groupByArray && groupByArray}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.name}
                              isClearable
                              //value={groupBy}
                              styles={customStyle}
                              value={newSalesSummaryReport && newSalesSummaryReport.groupBy}
                              key={newSalesSummaryReport?.groupBy}
                              classNamePrefix="select"
                              className="sm-text "
                              onChange={handleGroupBy}
                              maxMenuHeight="200px"
                              placeholder={
                                _t(t("Select a report group")) + ".."
                              }
                            />
                          </div>
                        </div>
                        <>
                          <div className="row gx-2 justify-content-end t-pb-15 t-pt-15">
                            {/* Action Button */}
                            <div className="col-2 t-mb-15 mb-md-0 text-right">
                              <button
                                className="float-right btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                                onClick={(e) => getSalesListing(e)}
                              >
                                Sales Data
                              </button>
                            </div>
                            <div className="col-2 t-mb-15 mb-md-0 text-right">
                              <button
                                className="float-right btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                                onClick={(e) => getBranchWiseSelected(e)}
                              >
                                Print Report
                              </button>
                            </div>
                            <div className="col-2 t-mb-15 mb-md-0 text-right">
                              <button
                                className="float-right btn btn-block btn-primary text-uppercase sm-text py-2 rounded"
                                onClick={(e) => getBranchWiseSaleSummary(e)}
                              >
                                Branch Wise Report
                              </button>
                            </div>
                            <div className="col-2 t-mb-15 mb-md-0 text-right">
                            <button
                              className="float-right btn btn-block btn-success text-uppercase sm-text py-2 rounded"
                              onClick={(e) => getThermalSelected(e)}
                            >
                              Thermal Print Report
                            </button>
                          </div>
                          </div>
                          {/* )} */}
                        </>
                      </div>
                    
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default SalesSummaryReport;
