import React, { useState, useContext, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import StockReport from "./StockReport";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
  formatPrice,
  currencySymbolLeft,
  currencySymbolRight,
  checkPermission
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import { MdDelete } from 'react-icons/md';

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import { UserContext } from "../../../../../contexts/User";
import ViewAccountSheet from "../../accounts/transactions/ViewAccountSheet"

const IngredientPurchaseHistory = () => {
  const { t } = useTranslation();
  //getting context values here
  let {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);

  let {
    //ingredient purchases
    ingredientPurchaseHistory,
    setIngredientPurchaseHistory,
    setPaginatedIngredientPurchase,
    ingredientPurchaseHistoryForSearch,
    setIngredientPurchaseHistoryForSearch,

    branchForSearch,
    //pagination
    dataPaginating,
  } = useContext(RestaurantContext);

  let { authUserInfo, getSupplier, supplierForSearch } = useContext(UserContext);
  // States hook here
  //new customer
  let [newSupplier, setNewSupplier] = useState({
    name: "",
    email: "",
    phn_no: "",
    address: "",
    due: null,
    edit: false,
    editSlug: null,
    uploading: false,
  });

  const [ledgerData, setLedgerData] = useState({
    modal: false,
    ledger: null
  })

  //search result
  let [searchedSupplier, setSearchedSupplier] = useState({
    list: null,
    searched: false,
  });

  //useEffect == componentDidMount
  useEffect(() => { getSupplier() }, []);

  //search purchase here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedSupplier({ ...searchedSupplier, searched: false });
    } else {
      const searchedList = ingredientPurchaseHistoryForSearch.filter((item) => {
        //name
        let lowerCaseItemSupplierName = item.supplier_name.toLowerCase();
        //email
        let lowerCaseItemInvoice = item.invoice_number !== null && item.invoice_number.toLowerCase();
        let lowerCaseBranch = item.branch?.name && item.branch.name.toLowerCase();

        return (
          lowerCaseItemSupplierName.includes(searchInput) ||
          lowerCaseItemInvoice.includes(searchInput) ||
          lowerCaseBranch.includes(searchInput)
        );
      });
      setSearchedSupplier({
        ...searchedSupplier,
        list: searchedList,
        searched: true,
      });
    }
  };

  //delete confirmation modal of waiter
  const handleDeleteConfirmation = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDeletePurchase(id);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //delete purchase here
  const handleDeletePurchase = (id) => {
    setLoading(true);
    const url = BASE_URL + `/settings/delete-ingredient_purchase/${id}`;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setIngredientPurchaseHistory(res.data[0]);
        setIngredientPurchaseHistoryForSearch(res.data[1]);
        setSearchedSupplier({
          ...searchedSupplier,
          list: res.data[1],
        });
        setLoading(false);
        toast.success(`${_t(t("Purchase has been deleted successfully"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };

  //print bills states and function
  const [newRow,setNewRow]=useState([]);
  const [purchaseDate,setPurchaseDate]=useState(new Date());
  let [newItem, setNewItem] = useState({
    branch: null,
    supplier: null,
    invoice: "",
    description: "",
    paymentType: "",
  });
  const componentRef = useRef();
  const handleOnlyPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePiLedger = async(id) =>{
    const url = BASE_URL + "/settings/check-financial-ledger-view/" + parseInt(id) + "/PI";
    try{
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      if(res.data){
        setLedgerData({ modal: true, ledger: res.data});
      }
    }catch(err){
      console.log("error==>",err)
    }
  }
  
  const handleGetPrintData = async(id) => {
    const url =
    BASE_URL +
      "/settings/get-ingredient_purchase_items/" +
      parseInt(id);
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        // console.log("check specific acc==>",res.data);
          if(res.data){
            let purchaseDate = new Date(res.data[1].purchase_date);
            setPurchaseDate(purchaseDate);
            let branch=branchForSearch?.find((item)=>item.id === res.data[1].branch_id)
            let supplier=supplierForSearch?.find((item)=>item.id === res.data[1].supplier_id)            
            setNewItem({
              ...newItem,
              branch,
              description:res.data[1].desc,
              paymentType:res.data[1].payment_type,
              supplier,
              invoice:res.data[1].invoice_number,
              total_amount: res.data[1].total_amount,
              total_discount: res.data[1].total_discount,
              total_tax: res.data[1].total_tax,
              total_bill: res.data[1].total_bill,
            })
            let newArr=[];
            if(res.data[0] && res.data[0].length>0){
              res.data[0].map((item)=>{
                newArr.push(item)
              })
              setNewRow([...newArr])
            }
          }
          handleOnlyPrint();
          
        } catch(error) {
          console.log("error==>",error)
        }
  }

  const handleGetAmount = (index) => {
    let qty = 0;
    let rate = 0;
    if (newRow !== null && newRow[index].qty !== "") {
      qty = parseFloat(newRow[index].qty);
    }
    if (newRow !== null && newRow[index].rate !== "") {
      rate = parseFloat(newRow[index].rate);
    }
    return rate * qty;
  };

  //post button function
  const handlePostApi = async (id) => {
    setLoading(true);
    const url = BASE_URL + "/settings/mark-is-post-purchase-invoice"
    let formData = new FormData();
    formData.append('id',id)
    formData.append('is_post',1)
    try {
      const res = await axios.post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
        if(res.data){
          setIngredientPurchaseHistory(res.data[0]);
          setIngredientPurchaseHistoryForSearch(res.data[1]);
          setSearchedSupplier({
            ...searchedSupplier,
            list: res.data[1],
          });
          toast.success(`${_t(t("Finish goods has been post"))}`,{
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
        setLoading(false);
    } catch {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`,{
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
    }
  }

  return (
    <>
      <Helmet>
        <title>{_t(t("Ingredient Purchases"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {newSupplier.uploading === true || loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                        <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedSupplier.searched
                                    ? _t(t("Purchase Invoice List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search customer */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-left"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary rounded-right"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add customer modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                <NavLink
                                  to="/dashboard/manage/stock/purchase-invoice-add"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                                >
                                  {_t(t("add new"))}
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Supplier"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Invoice"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Purchased"))}
                                </th>
                                
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Branch"))}
                                </th>
                                
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Total"))}
                                </th> 

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Created Date"))}
                                </th>                               

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedSupplier.searched
                                ? [
                                  ingredientPurchaseHistory && [
                                    ingredientPurchaseHistory.data.length ===
                                      0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="7"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      ingredientPurchaseHistory.data.map(
                                        (item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (ingredientPurchaseHistory.current_page -
                                                    1) *
                                                  ingredientPurchaseHistory.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.supplier_name}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.invoice_number}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                <Moment format="DD-MMMM-YYYY">
                                                  {item.purchase_date}
                                                </Moment>
                                              </td>
                                            
                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.branch.name}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {currencySymbolLeft()}
                                                {formatPrice(item.total_bill)}
                                                {currencySymbolRight()}
                                              </td>  

                                              <td className="xsm-text text-capitalize align-middle text-center">                                        
                                                <Moment format="DD-MMMM-YYYY hh:mm A">
                                                  {item?.created_at}
                                                </Moment>                                          
                                              </td>                                            

                                              <td className="xsm-text text-capitalize align-middle text-center">                                                
                                                <div className="d-flex justify-content-center">                                                    
                                                  {/* <NavLink
                                                  className="btn btn-primary btn-sm ml-2 rounded-sm"
                                                  to={`/dashboard/manage/stock/purchase-invoice-edit/${item.id}`}                                                       
                                                 >
                                                  <i className="fa fa-pencil" aria-hidden="true" style={{cursor: "pointer", fontSize: "1rem", color:"white"}}></i>                                                
                                                 </NavLink>
                                                  <button
                                                  className="btn btn-danger btn-sm ml-2 rounded-sm"
                                                  onClick={()=>handleDeleteConfirmation(item.id)}
                                                  >
                                                   <MdDelete style={{cursor: "pointer", fontSize: "1.3rem", color:"white"}} />
                                                 </button>
                                                  <button
                                                  className="btn btn-secondary btn-sm ml-2 rounded-sm"
                                                    onClick={()=>handleGetPrintData(item.id)}
                                                   >
                                                    <i className="fa fa-print"></i>
                                                  </button> */}
                                                  {item.is_post !== 1 && (
                                                    <NavLink
                                                      className="btn btn-primary ml-2 rounded-sm"
                                                      to={`/dashboard/manage/stock/purchase-invoice-edit/${item.id}`}
                                                      style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                    >
                                                      <div className="d-flex align-items-center justify-content-center">
                                                        <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                      </div>
                                                    </NavLink>
                                                  )}
                                                 <NavLink
                                                  title="duplicate invoice purchase"
                                                  to={`/dashboard/manage/stock/purchase-invoice-edit/${item.id}?duplicate=1`}
                                                  className="btn btn-success ml-2 rounded-sm del-btn"
                                                  >
                                                    <i className="fa fa-clone"></i>  
                                                  </NavLink>
                                                  {item.is_post !== 1 && (
                                                        <button
                                                        className="btn btn-danger ml-2 rounded-sm del-btn"
                                                        onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                                        >                                                    
                                                          <MdDelete className="del-icon" />                                                    
                                                      </button>
                                                    )
                                                  }
                                                  {authUserInfo.permissions !== null && authUserInfo.permissions.includes("Accounts") && 
                                                    item.is_post === 1 && (
                                                      <button
                                                        className="btn btn-primary ml-2 rounded-sm del-btn"
                                                        onClick={()=>handlePiLedger(item.id)}                                                    
                                                      >                                                    
                                                          <i className="fa fa-eye" aria-hidden="true"></i>                                                    
                                                      </button>
                                                    )
                                                  }
                                                  <button
                                                  className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                    onClick={()=>handleGetPrintData(item.id)}
                                                   >
                                                    <i className="fa fa-print edit-icon"></i>
                                                  </button>
                                                  {(item.is_post !== 1 ? <button
                                                      className="btn btn-dark ml-2 rounded-sm del-btn"
                                                      title="Post PI" 
                                                      style={{padding:"1px 0px 0px 1px"}} 
                                                      disabled={loading}                                              
                                                      onClick={()=>handlePostApi(item.id)}
                                                    >
                                                      <i className="fa fa-check-square-o" aria-hidden="true"></i>
                                                    </button> : null)
                                                  }
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ),
                                  ],
                                ]
                                : [
                                  /* searched data, logic === haveData*/
                                  searchedSupplier && [
                                    searchedSupplier.list.length === 0 ? (
                                      <tr className="align-middle">
                                        <td
                                          scope="row"
                                          colSpan="7"
                                          className="xsm-text align-middle text-center"
                                        >
                                          {_t(t("No data available"))}
                                        </td>
                                      </tr>
                                    ) : (
                                      searchedSupplier.list.map(
                                        (item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (ingredientPurchaseHistory.current_page -
                                                    1) *
                                                  ingredientPurchaseHistory.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.supplier_name}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.invoice_number}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                <Moment format="DD-MMMM-YYYY">
                                                  {item.purchase_date}
                                                </Moment>
                                              </td>
                                              
                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.branch.name}
                                              </td>
                                                
                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {currencySymbolLeft()}
                                                {formatPrice(item.total_bill)}
                                                {currencySymbolRight()}
                                              </td> 

                                              <td className="xsm-text text-capitalize align-middle text-center">                                        
                                                <Moment format="DD-MMMM-YYYY hh:mm A">
                                                  {item?.created_at}
                                                </Moment>                                          
                                              </td>                                              

                                              <td className="xsm-text text-capitalize align-middle text-center">                                                                                                
                                                <div className="d-flex justify-content-center">                                                    
                                                {/* <NavLink
                                                className="btn btn-primary btn-sm ml-2 rounded-sm"
                                                to={`/dashboard/manage/stock/purchase-invoice-edit/${item.id}`}                                                       
                                                >
                                                <i className="fa fa-pencil" aria-hidden="true" style={{cursor: "pointer", fontSize: "1rem", color:"white"}}></i>                                                
                                                </NavLink>
                                                <button
                                                className="btn btn-danger btn-sm ml-2 rounded-sm"
                                                onClick={()=>handleDeleteConfirmation(item.id)}
                                                >
                                                  <MdDelete style={{cursor: "pointer", fontSize: "1.3rem", color:"white"}} />
                                                </button>
                                                <button
                                                className="btn btn-secondary btn-sm ml-2 rounded-sm"
                                                  onClick={()=>handleGetPrintData(item.id)}
                                                  >
                                                  <i className="fa fa-print"></i>
                                                </button> */}
                                                <NavLink
                                                className="btn btn-primary ml-2 rounded-sm"
                                                to={`/dashboard/manage/stock/purchase-invoice-edit/${item.id}`}
                                                style={{height:"1.7rem",width:"1.7rem"}}                                                                                                                                              
                                                >
                                                <div className="d-flex align-items-center justify-content-center">
                                                  <i className="fa fa-pencil edit-icon" aria-hidden="true"></i>
                                                </div>
                                                </NavLink>
                                                <NavLink
                                                title="duplicate invoice purchase"
                                                to={`/dashboard/manage/stock/purchase-invoice-edit/${item.id}?duplicate=1`}
                                                className="btn btn-success ml-2 rounded-sm del-btn"
                                                >
                                                  <i className="fa fa-clone"></i>  
                                                </NavLink>
                                                <button
                                                className="btn btn-danger ml-2 rounded-sm del-btn"
                                                onClick={()=>handleDeleteConfirmation(item.id)}                                                    
                                                >                                                    
                                                  <MdDelete className="del-icon" />                                                    
                                                </button>
                                                {authUserInfo.permissions !== null && authUserInfo.permissions.includes("Accounts") &&
                                                  item.is_post === 1 && (
                                                    <button
                                                      className="btn btn-primary ml-2 rounded-sm del-btn"
                                                      onClick={()=>handlePiLedger(item.id)}                                                    
                                                    >                                                    
                                                        <i className="fa fa-eye" aria-hidden="true"></i>                                                    
                                                    </button>
                                                  )
                                                }
                                                <button
                                                className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                  onClick={()=>handleGetPrintData(item.id)}
                                                  >
                                                  <i className="fa fa-print edit-icon"></i>
                                                </button>
                                                {/* checkPermission(authUserInfo.permissions, "PI Post") && */}
                                                {(item.is_post !== 1 ? <button
                                                      className="btn btn-dark ml-2 rounded-sm del-btn"
                                                      title="Post PI" 
                                                      style={{padding:"1px 0px 0px 1px"}} 
                                                      disabled={loading}                                              
                                                      onClick={()=>handlePostApi(item.id)}
                                                    >
                                                      <i className="fa fa-check-square-o" aria-hidden="true"></i>
                                                    </button> : null)
                                                  }
                                                </div>                                                
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ),
                                  ],
                                ]}
                            </tbody>
                          </table>
                        </div>
                      </div>                    
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {newSupplier.uploading === true || loading === true
                ? paginationLoading()
                : [
                  // logic === !searched
                  !searchedSupplier.searched ? (
                    <div key="fragment4">
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            {/* pagination function */}
                            {pagination(
                              ingredientPurchaseHistory,
                              setPaginatedIngredientPurchase
                            )}
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {showingData(ingredientPurchaseHistory)}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    // if searched
                    <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                      <div className="row align-items-center t-pl-15 t-pr-15">
                        <div className="col-md-7 t-mb-15 mb-md-0">
                          <ul className="t-list d-flex">
                            <li className="t-list__item no-pagination-style">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  setSearchedSupplier({
                                    ...searchedSupplier,
                                    searched: false,
                                  })
                                }
                              >
                                {_t(t("Clear Search"))}
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-5">
                          <ul className="t-list d-flex justify-content-md-end align-items-center">
                            <li className="t-list__item">
                              <span className="d-inline-block sm-text">
                                {searchedShowingData(
                                  searchedSupplier,
                                  ingredientPurchaseHistoryForSearch
                                )}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ),
                ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
      <StockReport
        name = "Purchase Invoice"
        purchaseDate={purchaseDate}
        newItem={newItem}
        newRow={newRow}
        invoiceNo = {"Invoice No"}
        inoviceDate = {"Invoice Date"}
        handleGetTotal={""}
        handleAmount={""}
        handleTaxAmount={""}
        handleDisAmount={""}
        handleGetSubTotal={""}
        handleGetAmount={handleGetAmount}
        stockPrint={componentRef}
      /> 
      <ViewAccountSheet
        accountLedger={ledgerData}
        setAccountLedger= {setLedgerData}
      />
    </>
  );
};

export default IngredientPurchaseHistory;
