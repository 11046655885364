import React, { useState, useEffect, useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

//functions
import { getCookie } from "../functions/Functions";

//3rd party packages

// creating context api
const RestaurantContext = React.createContext();

const RestaurantProvider = ({ children }) => {
  // State hooks  here
  //loading
  const [loading, setLoading] = useState(false);
  const [dataPaginating, setDataPaginating] = useState(false);

  //website
  const [branchListWeb, setBranchListWeb] = useState(null);

  //delivery user
  const [pendingOrdersDelivery, setPendingOrdersDelivery] = useState(null);
  const [pendingOrdersDeliveryForSearch, setPendingOrdersDeliveryForSearch] =
    useState(null);
  const [deliveredOrdersDelivery, setDeliveredOrdersDelivery] = useState(null);
  const [
    deliveredOrdersDeliveryForSearch,
    setDeliveredOrdersDeliveryForSearch,
  ] = useState(null);

  //ingredientGroup
  const [ingredientGroupList, setIngredientGroupList] = useState(null);
  const [ingredientGroupForSearch, setIngredientGroupForSearch] =
    useState(null);

  //ingredientItem
  const [ingredientItemList, setIngredientItemList] = useState(null);
  const [ingredientItemForSearch, setIngredientItemForSearch] = useState(null);
  const [ingredientLimitList, setIngredientLimitList] = useState(null);
  const [ingredientLimitForSearch, setIngredientLimitForSearch] = useState(null);
  const [ingredientItemStock, setIngredientItemStock] = useState(null);
  const [ingredientPurchaseHistory, setIngredientPurchaseHistory] =
    useState(null);
  const [
    ingredientPurchaseHistoryForSearch,
    setIngredientPurchaseHistoryForSearch,
  ] = useState(null);
  const [bellRing, setBellRing] = useState(false);
  const audioInstance = useRef(null);

  //production 
  const [productionItemList, setProductionItemList] = useState(null);
  const [productionItemForSearch, setProductionItemForSearch] = useState(null);

  //microsoft integrauion
  const [MdIntegrationList, setMdIntegrationList] = useState(null);
  const [MdIntegrationForSearch, setMdIntegrationForSearch] =useState(null);
  const [expense,setExpense] = useState(null);
  const [expenseForSearch, setExpenseForSearch] = useState(null)
  
  
  const [expensePayment,setPaymentExpense] = useState(null);
  const [paymentExpenseForSearch, setPaymentExpenseForSearch] = useState(null)

  //branch
  const [branchList, setBranchList] = useState(null);
  const [branchForSearch, setBranchforSearch] = useState(null);

  //branch list
  //branch
  const [getBranchList, setGetBranchList] = useState(null);
  const [getBranchForSearch, setGetBranchforSearch] = useState(null);
  const [branchForListSearch, setBranchListforSearch] = useState(null);

  //OrderType
  const [orderTypeList, setOrderTypeList] = useState(null);
  const [orderTypeForSearch, setOrderTypeForSearch] = useState(null);

  //area and zone
  const [zoneList, setZoneList] = useState(null);
  const [zoneForSearch, setZoneForSearch] = useState(null);

  const [areaList, setAreaList] = useState(null);
  const [areaForSearch, setAreaForSearch] = useState(null);

  //table
  const [tableList, setTableList] = useState(null);
  const [tableForSearch, setTableforSearch] = useState(null);

  //floor
  const [floorList, setFloorList] = useState(null);
  const [floorForSearch, setFloorforSearch] = useState(null);

  //depart
  const [deptTagList, setDeptTagList] = useState(null);
  const [deptTagForSearch, setDeptTagForSearch] = useState(null);

  //kot
  const [KOTList, setKOTList] = useState(null);
  const [KOTForSearch, setKOTForSearch] = useState(null);

  //Scheme
  const [schemeList, setSchemeList] = useState(null);
  const [schemeForSearch, setSchemeForSearch] = useState(null);
  const [schemeForSearchObj, setSchemeForSearchObj] = useState(null);

  //Cards
  const [cardList, setCardList] = useState(null);
  const [cardForSearch, setCardForSearch] = useState(null);

  //Payment Type
  const [paymentTypeList, setPaymentTypeList] = useState(null);
  const [paymentTypeForSearch, setPaymentTypeforSearch] = useState(null);

  //Payment Type
  const [paymentTypeTaxForSearch, setPaymentTypeTaxforSearch] = useState(null);

  //work period
  const [workPeriodList, setWorkPeriodList] = useState(null);
  //const [dayOpen, setDayOpen] = useState(null);
  const [workPeriodForSearch, setWorkPeriodListForSearch] = useState(null);
  const [workPeriodListForBranch, setWorkPeriodListForBranch] = useState(null);

  //submitted orders
  const [submittedOrders, setSubmittedOrders] = useState(null);
  const [submittedOrdersForSearch, setSubmittedOrdersForSearch] =
    useState(null);
  const [runningOrders, setRunningOrders] = useState(null);

  //settled orders
  const [settledOrders, setSettledOrders] = useState(null);
  const [settledOrdersForSearch, setSettledOrdersForSearch] = useState(null);

  //kitchen new orders
  const [kithcenNewOrders, setKithcenNewOrders] = useState(null);
  const [kithcenNewOrdersOnline, setKithcenNewOrdersOnline] = useState(null);

  //all orders
  const [allOrders, setAllOrders] = useState(null);
  const [allOrdersForSearch, setAllOrdersForSearch] = useState(null);

  //all orders
  const [allOnlineOrders, setAllOnlineOrders] = useState(null);
  const [allOnlineOrdersForSearch, setAllOnlineOrdersForSearch] =
    useState(null);
  const [ingredientList, setIngredientList] = useState(null);
  const [ingredientUnitForSearch, setIngredientUnitForSearch] = useState(null);

  // recipes
  const [recipeList, setRecipeList] = useState(null);
  const [recipeForSearch, setRecipeForSearch] = useState(null);
  const [purchaseOrderHistory,setPurchaseOrderHistory] = useState(null);
  const [purchaseOrderHistoryForSearch,setPurchaseOrderHistoryForSearch] = useState(null);

  // good notes
  const [goodReceiveList,setGoodReceiveList] = useState(null);
  const [goodReceiveListForSearch, setGoodReceiveListForSearch] = useState(null)

  // kds setting
  const [kdsList,setKdsList] = useState(null);
  const [kdsListForSearch, setKdsListForSearch] = useState(null)

  //online order counter 
  const [orderCounter, setOrderCounter] = useState({online: 0, foodPanda: 0, ready: 0 });

  // localStorage helper
  const setDataToLocal = (url, res) => {
    localStorage.setItem(url, JSON.stringify(res));
  };

  // localStorage helper
  const getDataFromLocal = (url) => {
    return JSON.parse(localStorage.getItem(url));
  };

  //check if offline helper
  const offline = (url) => {
    return !navigator.onLine && localStorage.getItem(url) != null;
  };

  //useEffect- to get data on render
  useEffect(() => {
    //call- unauthenticated
    getBranchWeb();
    //call if authenticated
    if (getCookie() !== undefined || offline(BASE_URL + "/auth/user")) {
      getBranch();
      getZones();
      getAreas()
      getOrderType();
      getTable();
      getFloor();
      GetBranchList();
      getDeptTag();
      getKOT();
      getScheme();
      getCard();
      getPaymentType();
      getPaymentTypeTax();
      getWorkPeriod();
      getWorkPeriodForBranch();
      // getDeliveryPendingOrders();
      // getDeliveryDeliveredOrders();
      getIngredients();
      getRecipes();
      getIngredientItem();
      // getProductionItem();
      getRunningOrders();
      getExpense()
      getExpenseList()
      getGoodRecieveNotes();
      getKdsList();
    }
  }, []);

  const setDeliveryPendingOrder = (res) => {
    setPendingOrdersDelivery(res.data[0]);
    setPendingOrdersDeliveryForSearch(res.data[1]);
    setLoading(false);
  };

  const setPaginatedIngredientUnits = (res) => {
    setIngredientList(res.data[0]);
    setIngredientUnitForSearch(res.data[1]);
    setDataPaginating(false);
  };

    // get paginated ing units  // Priority ->
    const setPaginatedIngredientUnit = async (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/unit?page=" + pageNo;
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setPaginatedIngredientUnits(res);
        setDataToLocal(url, res);
      } catch (error) {
        if (offline(url)) {
          let parseRes = getDataFromLocal(url);
          setPaginatedIngredientUnits(parseRes);
        }
      }
    };

  // get ingredients form api

  const getIngredients = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/unit";
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      // setDataToLocal(url, res);
      setIngredientList(res.data[0]);
      setIngredientUnitForSearch(res.data[1]);
      setLoading(false);
    } catch (error) {}
  };

  const setPaginatedRecipeLists = (res) => {
    setRecipeList(res.data[0]);
    setRecipeForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated ing units  // Priority ->
  const setPaginatedRecipeList = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/recipe?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedRecipeLists(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setPaginatedIngredientUnits(parseRes);
      }
    }
  };

  // get recipes form api

  const getRecipes = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/recipe";
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      // setDataToLocal(url, res);
      setRecipeList(res.data[0]);
      setRecipeForSearch(res.data[1]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("recipe error", error);
    }
  };

  //get deliveryman pending orders
  const getDeliveryPendingOrders = async () => {
    setLoading(true);
    const ordersUrl = BASE_URL + "/settings/get-assigned-orders";
    try {
      const res = await axios
        .get(ordersUrl, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setDeliveryPendingOrder(res);
      setDataToLocal(ordersUrl, res);
    } catch (error) {
      if (offline(ordersUrl)) {
        let parseRes = getDataFromLocal(ordersUrl);
        setDeliveryPendingOrder(parseRes);
      }
    }
  };

  //Helper
  const setPaginatedDeliveryPendingOrder = (res) => {
    setPendingOrdersDelivery(res.data[0]);
    setPendingOrdersDeliveryForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated deliveryman pending orders
  const setPaginatedDeliveryPendingOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-assigned-orders?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedDeliveryPendingOrder(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setPaginatedDeliveryPendingOrder(parseRes);
      }
    }
  };

  // Helper
  const setDeliveryDeliveredOrder = (res) => {
    setDeliveredOrdersDelivery(res.data[0]);
    setDeliveredOrdersDeliveryForSearch(res.data[1]);
    setLoading(false);
  };

  //get deliveryman delivered orders
  const getDeliveryDeliveredOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-delivered-orders";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setDeliveryDeliveredOrder(res);
        setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setDeliveryDeliveredOrder(parseRes);
      }
      setLoading(false);
    }
  };

  //Helper
  const setPaginatedDeliveryDeliveredOrder = (res) => {
    setDeliveredOrdersDelivery(res.data[0]);
    setDeliveredOrdersDeliveryForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated deliveryman delivered orders
  const setPaginatedDeliveryDeliveredOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-delivered-orders?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedDeliveryDeliveredOrder(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setPaginatedDeliveryDeliveredOrder(parseRes);
      }
    }
  };

  const setIngredientGroups = (res) => {
    setIngredientGroupList(res.data[0]);
    setIngredientGroupForSearch(res.data[1]);
    setLoading(false);
  };

  //get ingredient group
  const getIngredientGroup = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-ingredient_group";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setIngredientGroups(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setIngredientGroups(parseRes);
      }
      setLoading(false);
    }
  };

  const setPaginatedIngredientGroups = (res) => {
    setIngredientGroupList(res.data[0]);
    setIngredientGroupForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated ingredient group
  const setPaginatedIngredientGroup = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-ingredient_group?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedIngredientGroups(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setPaginatedIngredientGroups(parseRes);
      }
    }
  };


  const setProductionItems = (res) => {
    setProductionItemList(res.data[0]);
    setProductionItemForSearch(res.data[1]);
    setLoading(false);
  };

  //get ingredient item
  const getProductionItem = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-production";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setProductionItems(res);
       setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setProductionItems(parseRes);
      }
      setLoading(false)
    }
  };

  const setPaginatedProductionItems = (res) => {
    setProductionItemList(res.data[0]);
    setProductionItemForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated ingredient item
  const setPaginatedProductionItem = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-production?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedProductionItems(res);
      setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setPaginatedProductionItems(parseRes);
      }
      setDataPaginating(false);
    }
  };

  const setIngredientItems = (res) => {
    setIngredientItemList(res.data[0]);
    setIngredientItemForSearch(res.data[1]);
    setIngredientItemStock(res.data[2]);
    setLoading(false);
  };

  //get ingredient item
  const getIngredientItem = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-ingredient_item";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setIngredientItems(res);
       setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setIngredientItems(parseRes);
      }
    }
  };

  const setPaginatedIngredientItems = (res) => {
    setIngredientItemList(res.data[0]);
    setIngredientItemForSearch(res.data[1]);
    setIngredientItemStock(res.data[2]);
    setDataPaginating(false);
  };

  // get paginated ingredient item
  const setPaginatedIngredientItem = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-ingredient_item?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedIngredientItems(res);
        setDataToLocal(url, res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = getDataFromLocal(url);
        setPaginatedIngredientItems(parseRes);
      }
    }
  };

  const setMdIntegrationGroups = (res) => {
    setMdIntegrationList(res.data[0]);
    setMdIntegrationForSearch(res.data[1]);
    setLoading(false);
  };

  //get ingredient group
  const getMdIntegrationGroup = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/md-accounts-list";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setMdIntegrationGroups(res);
    } catch (error) {
      setLoading(false);
    }
  };

  const setPaginatedMdIntegraionGroups = (res) => {
    setMdIntegrationList(res.data[0]);
    setMdIntegrationForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated ingredient group
  const setPaginatedMdIntegrationGroup = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/md-accounts-list?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedMdIntegraionGroups(res);
    } catch (error) {
      setDataPaginating(false);
    }
  };

  const setIngredientLimit = (res) => {
    setIngredientLimitList(res.data[0]);
    setIngredientLimitForSearch(res.data[1]);
    setLoading(false);
  };

  //get ingredient limit
  const getIngredientLimit = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-ingredient_item-limit";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setIngredientLimit(res);
    } catch (error) {
      setLoading(false);
    }
  };

  const setPaginatedIngredientLimits = (res) => {
    setIngredientLimitList(res.data[0]);
    setIngredientLimitForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated ingredient item limit
  const setPaginatedIngredientLimit = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-ingredient_item-limit?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedIngredientLimits(res);
      setDataToLocal(url, res);
    } catch (error) {
      setDataPaginating(false);
    }
  };

  const setBranchWeb = (res) => {
    setBranchListWeb(res.data);
    setLoading(false);
  };

  // paginated branch list
  const setPaginatedBranchesList = (res) => {
    setGetBranchList(res.data[0]);
    setGetBranchforSearch(res.data[1]);
    setBranchListforSearch(res.data[2]);
    setDataPaginating(false);
  };

  const GetBranchList = async () => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-branch-list";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setPaginatedBranchesList(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPaginatedBranchesList(parseRes);
      }
    }
  };

  // paginated branch list
  const setPaginatedBranchList = (res) => {
    setGetBranchList(res.data[0]);
    setGetBranchforSearch(res.data[1]);
    setBranchListforSearch(res.data[2]);
    setDataPaginating(false);
  };
  const getPaginatedBranchList = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-branch-list?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedBranchList(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPaginatedBranchList(parseRes);
      }
    }
  };

  //get branchWeb
  const getBranchWeb = async () => {
    setLoading(true);
    const url = BASE_URL + "/website/get-branch-web";
    try {
      const res = await axios.get(url);
      const res_1 = undefined;
      setDataToLocal(url, res_1);
      setBranchWeb(res_1);
    } catch (error) {
      if (offline(url)) {
        // let parseRes = getDataFromLocal(url);
        // setBranchWeb(parseRes);
      }
      setLoading(false)
    }
  };

  const setBranches = (res) => {
    setBranchList(res.data[0]);
    setBranchforSearch(res.data[1]);
    setBranchListforSearch(res.data[2]);
    setLoading(false);
  };

  //get branch   // Priority ->
  const getBranch = async () => {
    setLoading(true);
    const branchUrl = BASE_URL + "/settings/get-manager-branches";
    try {
      const res = await axios
        .get(branchUrl, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setBranches(res);
      localStorage.setItem(branchUrl, JSON.stringify(res));
    } catch (error) {
      if (offline(branchUrl)) {
        let parseRes = JSON.parse(localStorage.getItem(branchUrl));
        setBranches(parseRes);
      }
    }
  };

  const setPaginatedBranches = (res) => {
    setBranchList(res.data[0]);
    setBranchforSearch(res.data[1]);
    setBranchListforSearch(res.data[2]);
    setDataPaginating(false);
  };

  // get paginated branch
  const setPaginatedBranch = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-manager-branches?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedBranches(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPaginatedBranches(parseRes);
      }
    }
  };

 
  // get areas
  const getAreas = async() =>{
    const areasUrl = BASE_URL + "/settings/get-area-list";
    try{
      const response = await axios.get(areasUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      if(response.data.code == 1){
        setAreaList(response.data.details?.mappedAreas);
        setAreaForSearch(response.data.details?.unMappedAreas);
        localStorage.setItem(areasUrl, JSON.stringify(response.data));
      }
    } catch (error) {
      // Handle the error here
      if (!navigator.onLine && localStorage.getItem(areasUrl) != null) {
        let parseRes = JSON.parse(localStorage.getItem(areasUrl));
        setAreaList(parseRes.details?.mappedAreas);
        setAreaForSearch(parseRes.details?.unMappedAreas);
      }
    }
  }

   //zones
  
   const setZone = (res) => {
    if(res.data.code == 1){
      setZoneList(res.data.details?.pagination);
      setZoneForSearch(res.data.details?.zoneList);
    }
    setLoading(false);
  };

  //get zone
  const getZones = async () => {
    setLoading(true);
    const branchUrl = BASE_URL + "/settings/zones";
    try {
      const res = await axios.get(branchUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setZone(res);
      localStorage.setItem(branchUrl, JSON.stringify(res));
    } catch (error) {
      if (offline(branchUrl)) {
        let parseRes = JSON.parse(localStorage.getItem(branchUrl));
        setZone(parseRes);
      }
      setLoading(false);
    }
  };

  const setPaginatedZone = (res) => {
    if(res.data.code == 1){
      setZoneList(res.data.details?.pagination);
      setZoneForSearch(res.data.details?.zoneList);
    }
    setDataPaginating(false);
  };

  // get paginated zones
  const setPaginatedZones = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/zones?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedZone(res);
        // localStorage.setItem(url, JSON.stringify(res));
      })
      .catch((error) => {
        // if (offline(url)) {
        //   let parseRes = JSON.parse(localStorage.getItem(url));
        //   setPaginatedOrderTypes(parseRes);
        // }
      });
  };

  
  const setOrderType = (res) => {
    setOrderTypeList(res.data[0]);
    setOrderTypeForSearch(res.data[1]);
    setLoading(false);
  };

  //get orderType
  const getOrderType = async () => {
    setLoading(true);
    const branchUrl = BASE_URL + "/settings/get-order-types";
    try {
      const res = await axios.get(branchUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      setOrderType(res);
      localStorage.setItem(branchUrl, JSON.stringify(res));
    } catch (error) {
      if (offline(branchUrl)) {
        let parseRes = JSON.parse(localStorage.getItem(branchUrl));
        setOrderType(parseRes);
      }
      setLoading(false);
    }
  };

  const setPaginatedOrderTypes = (res) => {
    setOrderTypeList(res.data[0]);
    setOrderTypeForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated order type
  const setPaginatedOrderType = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-order-types?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setPaginatedOrderTypes(res);
        localStorage.setItem(url, JSON.stringify(res));
      })
      .catch((error) => {
        if (offline(url)) {
          let parseRes = JSON.parse(localStorage.getItem(url));
          setPaginatedOrderTypes(parseRes);
        }
      });
  };

  const setFloors = (res) => {
    setFloorList(res.data[0]);
    setFloorforSearch(res.data[1]);
    setLoading(false);
  };

  //get tables
  const getFloor = () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-floor";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        localStorage.setItem(url, JSON.stringify(res));
        setFloors(res);
      })
      .catch((error) => {
        if (offline(url)) {
          let parseRes = JSON.parse(localStorage.getItem(url));
          setFloors(parseRes);
        }
        setLoading(false);
      });
  };

  const setTables = (res) => {
    setTableList(res.data[0]);
    setTableforSearch(res.data[1]);
    setLoading(false);
  };

  //get tables
  const getTable = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-table";
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setTables(res);
        localStorage.setItem(url, JSON.stringify(res));
      })
      .catch((error) => {
        if (offline(url)) {
          let parseRes = JSON.parse(localStorage.getItem(url));
          setTables(parseRes);
        }
        setLoading(false);
      });
  };

  const setPaginatedFloors = (res) => {
    setTableList(res.data[0]);
    setTableforSearch(res.data[1]);
    setDataPaginating(false);
  };

  
  // get paginated tables
  const setPaginatedFloor = (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-floor?page=" + pageNo;
    return axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        localStorage.setItem(url, JSON.stringify(res));
        setPaginatedFloors(res);
      })
      .catch((error) => {
        if (offline(url)) {
          let parseRes = JSON.parse(localStorage.getItem(url));
          setPaginatedFloors(parseRes);
        }
      });
  };


  const setPaginatedTables = (res) => {
    setTableList(res.data[0]);
    setTableforSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated tables
  const setPaginatedTable = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-table?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedTables(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPaginatedTables(parseRes);
      }
    }
  };

  const setPaymentTypes = (res) => {
    setPaymentTypeList(res.data[0]);
    setPaymentTypeforSearch(res.data[1]);
    setLoading(false);
  };

  //get payment types
  const getPaymentType = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-payment-type";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaymentTypes(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPaymentTypes(parseRes);
      }
    }
  };

  const setPaymentTypeTaxes = (res) => {
    setPaymentTypeTaxforSearch(res.data);
    setLoading(false);
  };

  //get payment types
  const getPaymentTypeTax = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-payment-tax";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaymentTypeTaxes(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPaymentTypeTaxes(parseRes);
      }
      setLoading(false);
    }
  };

  const setPaginatedPaymentTypes = (res) => {
    setPaymentTypeList(res.data[0]);
    setPaymentTypeforSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated payment types
  const setPaginatedPaymentType = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-payment-type?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedPaymentTypes(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPaginatedPaymentTypes(parseRes);
      }
    }
  };

  const setDeptTags = (res) => {
    setDeptTagList(res.data[0]);
    setDeptTagForSearch(res.data[1]);
    setLoading(false);
  };

  //get dept Tag
  const getDeptTag = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-dept-tag";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setDeptTags(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setDeptTags(parseRes);
      }
      setLoading(false);
    }
  };

  const setPaginatedDeptTags = (res) => {
    setDeptTagList(res.data[0]);
    setDeptTagForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated dept Tag
  const setPaginatedDeptTag = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-dept-tag?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedDeptTags(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPaginatedDeptTags(parseRes);
      }
    }
  };

  const setKOTs = (res) => {
    setKOTList(res.data[0]);
    setKOTForSearch(res.data[1]);
    setLoading(false);
  };

  //get dept Tag
  const getKOT = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-kot";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setKOTs(res);
    } catch (error) {
      // if (offline(url)) {
      //   let parseRes = JSON.parse(localStorage.getItem(url));
      //   setKOTs(parseRes);
      // }
      setLoading(false)
    }
  };

  const setPaginatedKOTs = (res) => {
    setKOTList(res.data[0]);
    setKOTForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated dept Tag
  const setPaginatedKOT = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-kot?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedKOTs(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPaginatedKOTs(parseRes);
      }
    }
  };

  const setSchemes = (res) => {
    setSchemeList(res.data[0]);
    setSchemeForSearch(res.data[1]);
    const obj = {};
    res.data[1]?.length && res.data[1].map((scheme) => {
      obj[parseInt(scheme.id)] = scheme;
    });
    setSchemeForSearchObj(obj);
    setLoading(false);
  };

  //get dept Tag
  const getScheme = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-schemes";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setSchemes(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setSchemes(parseRes);
      }
      setLoading(false);
    }
  };

  const setPaginatedSchemes = (res) => {
    setSchemeList(res.data[0]);
    setSchemeForSearch(res.data[1]);
    const obj = {};
    res.data[1]?.length && res.data[1].map((scheme) => {
      obj[parseInt(scheme.id)] = scheme;
    });
    setSchemeForSearchObj(obj);
    setDataPaginating(false);
  };

  // get paginated schemes
  const setPaginatedScheme = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-schemes?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedSchemes(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPaginatedSchemes(parseRes);
      }
    }
  };

  // cards stuff
  const setCard = (res) => {
    setCardList(res.data[0]);
    setCardForSearch(res.data[1]);
    setLoading(false);
  };

  const getCard = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-card-info";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setCard(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setCard(parseRes);
      }
      setLoading(false);
    }
  };

  const setPaginatedCards = (res) => {
    setCardList(res.data[0]);
    setCardForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated cards
  const setPaginatedCard = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-card-info?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedCards(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPaginatedCards(parseRes);
      }
    }
  };

  const setWorkPeriodForBranch = (res) => {
    setWorkPeriodListForBranch(res.data)
    setLoading(false);
  };

  //get work period
  const getWorkPeriodForBranch = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-work-period-by-branch";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setWorkPeriodForBranch(res);
        localStorage.setItem(url, JSON.stringify(res));
        return res;
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setWorkPeriodForBranch(parseRes);
        return parseRes;
      }
      setLoading(false);
    }
  };

  const setWorkPeriod = (res) => {
    setWorkPeriodList(res.data[0]);
    setWorkPeriodListForSearch(res.data[1]);
    setLoading(false);
  };

  //get work period
  const getWorkPeriod = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-work-period";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setWorkPeriod(res);
        localStorage.setItem(url, JSON.stringify(res));
        return res;
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setWorkPeriod(parseRes);
        return parseRes;
      }
      setLoading(false);
    }
  };


  const setPaginatedWorkPeriods = (res) => {
    setWorkPeriodList(res.data[0]);
    setWorkPeriodListForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated work period
  const setPaginatedWorkPeriod = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-work-period?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedWorkPeriods(res);
        // localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      setDataPaginating(false);
      // if (offline(url)) {
      //   let parseRes = JSON.parse(localStorage.getItem(url));
      //   setPaginatedWorkPeriods(parseRes);
      // }
    }
  };

  const setRunningOrder = (res) => {
    setRunningOrders(res.data);
    setLoading(false);
  };

  //get submitted orders- not settled
  const getRunningOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-running-orders";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
      setRunningOrder(res);
    } catch (error) {
     
    }
  };

  const setSubmittedOrder = (res) => {
    setSubmittedOrders(res.data[0]);
    setSubmittedOrdersForSearch(res.data[1]);
    setLoading(false);
  };

  //get submitted orders- not settled
  const getSubmittedOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-submitted-orders";
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      localStorage.setItem(url, JSON.stringify(res));
      setSubmittedOrder(res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setSubmittedOrder(parseRes);
      }
      setLoading(false);
    }
  };

  const setPaginatedSubmittedOrder = (res) => {
    setSubmittedOrders(res.data[0]);
    setSubmittedOrdersForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated submitted orders- not settled
  const setPaginatedSubmittedOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-submitted-orders?page=" + pageNo;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      //localStorage.setItem(url, JSON.stringify(res));
      setPaginatedSubmittedOrder(res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPaginatedSubmittedOrder(parseRes);
      }
    }
  };

  const setSettledOrder = (res) => {
    setSettledOrders(res.data[0]);
    setSettledOrdersForSearch(res.data[1]);
    setLoading(false);
  };

  //get settled orders
  const getSettledOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-settled-orders";
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      localStorage.setItem(url, JSON.stringify(res));
      setSettledOrder(res);
      console.log("resresresres", res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setSettledOrder(parseRes);
      }
      setLoading(false);
    }
  };

  const setPaginatedSettledOrder = (res) => {
    setSettledOrders(res.data[0]);
    setSettledOrdersForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated settled orders
  const setPaginatedSettledOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-settled-orders?page=" + pageNo;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      //localStorage.setItem(url, JSON.stringify(res));
      setPaginatedSettledOrder(res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPaginatedSettledOrder(parseRes);
      }
    }
  };

  const setKitchenNewOrder = (res) => {
    setKithcenNewOrders(res.data[0]);
    setLoading(false);
    return res.data[0];
  };
  //get kithcen new orders
  const getKitchenNewOrders = async (type = "Kitchen") => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-new-orders/" + type;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setKitchenNewOrder(res);
        localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setKitchenNewOrder(parseRes);
      }
      setLoading(false);
    }
  };

  const setKitchenNewOrderOnline = (res) => {
    setKithcenNewOrdersOnline(res.data[0]);
    setLoading(false);
  };

  // kitchen online orders
  const getKitchenNewOrdersOnline = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-new-orders-online";
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setKitchenNewOrderOnline(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setKitchenNewOrderOnline(parseRes);
      }
      setLoading(false);
    }
  };

  const setAllOrder = (res) => {
    setAllOrdersForSearch(res.data);
    setLoading(false);
  };

  //get all orders for order history
  const getAllOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-order-history-all";
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      //localStorage.setItem(url, JSON.stringify(res));
      setAllOrder(res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setAllOrder(parseRes);
      }
      setLoading(false);
    }
  };

  const setAllPaginatedOrders = (res) => {
    setAllOrders(res.data);
    setDataPaginating(false);
    setLoading(false);
  };

  // get paginated settled orders
  const setPaginatedAllOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-order-history?page=" + pageNo;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      //localStorage.setItem(url, JSON.stringify(res));
      setAllPaginatedOrders(res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setAllPaginatedOrders(parseRes);
      }
    }
  };

  const setAllOnlineOrder = (res) => {
    setAllOnlineOrdersForSearch(res.data);
    setLoading(false);
  };

  // const setPaginatedFilteredOrders = async (params) => {
  //   setDataPaginating(true);
  //   const url = BASE_URL + `/settings/get-order-history-filter`;
  //   try {
  //     const res = await axios.post(url, params, {
  //       headers: { Authorization: `Bearer ${getCookie()}` },
  //     });
  //     // localStorage.setItem(url, JSON.stringify(res));
  //     if (params.type === "pos") setAllOrdersForSearch(res.data);
  //     if (params.type === "online") setAllOnlineOrdersForSearch(res.data);
  //     setDataPaginating(false);
  //     setLoading(false);
  //   } catch (error) {
  //     if (offline(url)) {
  //       let parseRes = JSON.parse(localStorage.getItem(url));
  //       if (params.type === "pos") setAllOrdersForSearch(parseRes.data);
  //       if (params.type === "online")
  //         setAllOnlineOrdersForSearch(parseRes.data);
  //       setDataPaginating(false);
  //       setLoading(false);
  //     }
  //   }
  // };

  const setPaginatedFilteredOrders = async (params) => {
    setDataPaginating(true);
    const url = BASE_URL + `/settings/get-order-histories`;
    try {
      const res = await axios.post(url, params, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      // localStorage.setItem(url, JSON.stringify(res));
      if (params.type === "pos") setAllOrdersForSearch(res.data);
      if (params.type === "online") setAllOnlineOrdersForSearch(res.data);
      setDataPaginating(false);
      setLoading(false);
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        if (params.type === "pos") setAllOrdersForSearch(parseRes.data);
        if (params.type === "online") setAllOnlineOrdersForSearch(parseRes.data);
      }
      setDataPaginating(false);
      setLoading(false);
    }
  };

  const setPaginatedSearchOrders = async (params) => {
    setDataPaginating(true);
    const url = BASE_URL + `/settings/get-order-histories?page=`+params.page;
    try {
      const res = await axios.post(url, params, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      // localStorage.setItem(url, JSON.stringify(res));
      if (params.type === "pos") setAllOrdersForSearch(res.data);
      if (params.type === "online") setAllOnlineOrdersForSearch(res.data);
      setDataPaginating(false);
      setLoading(false);
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        if (params.type === "pos") setAllOrdersForSearch(parseRes.data);
        if (params.type === "online") setAllOnlineOrdersForSearch(parseRes.data);
      }
      setDataPaginating(false);
      setLoading(false);
    }
  };

  const setPaginatedSearchedOrders = async (params) => {
    setDataPaginating(true);
    const url = BASE_URL + `/settings/get-order-history-search`;
    try {
      const res = await axios.post(url, params, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      // localStorage.setItem(url, JSON.stringify(res));
      if (params.type === "pos") setAllOrdersForSearch(res.data);
      if (params.type === "online") setAllOnlineOrdersForSearch(res.data);
      setDataPaginating(false);
      setLoading(false);
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        if (params.type === "pos") setAllOrdersForSearch(parseRes.data);
        if (params.type === "online")
          setAllOnlineOrdersForSearch(parseRes.data);
        }
        setDataPaginating(false);
        setLoading(false);
    }
  };

  //get all  online orders for order history
  const getAllOnlineOrders = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-online-order-history-all";
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      //localStorage.setItem(url, JSON.stringify(res));
      setAllOnlineOrder(res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setAllOnlineOrder(parseRes);
      }
      setLoading(false)
    }
  };

  const setAllPaginatedOnlineOrder = (res) => {
    setAllOnlineOrders(res.data);
    setDataPaginating(false);
    setLoading(false);
  };

  // get paginated online  orders
  const setPaginatedAllOnlineOrders = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-online-order-history?page=" + pageNo;
    try {
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      //localStorage.setItem(url, JSON.stringify(res));
      setAllPaginatedOnlineOrder(res);
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setAllPaginatedOnlineOrder(parseRes);
      }
      setDataPaginating(false)
    }
  };

  
  const setPurchaseOrder = (res) => {
    setPurchaseOrderHistory(res.data[0]);
    setPurchaseOrderHistoryForSearch(res.data[1]);
    setLoading(false);
  };

  //get food purchases
  const getPurchaseOrder = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-purchase-order";
    try {
      const res = await axios
        .get(url);
        setPurchaseOrder(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPurchaseOrder(parseRes);
      }
      setLoading(false);
    }
  };

  const setPaginatedPurchasesOrder = (res) => {
    setPurchaseOrderHistory(res.data[0]);
    setPurchaseOrderHistoryForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated purchases
  const getPaginatedPurchaseOrder = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-purchase-order?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedPurchasesOrder(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPaginatedPurchasesOrder(parseRes);
      }
    }
  };

  const setIngredientPurchase = (res) => {
    setIngredientPurchaseHistory(res.data[0]);
    setIngredientPurchaseHistoryForSearch(res.data[1]);
    setLoading(false);
  };

  //get food purchases
  const getIngredientPurchase = async () => {
    setLoading(true);
    const url = BASE_URL + "/settings/get-ingredient_purchase";
    try {
      const res = await axios
        .get(url,{
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setIngredientPurchase(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setIngredientPurchase(parseRes);
      }
    }
  };

  const setPaginatedIngredientPurchases = (res) => {
    setIngredientPurchaseHistory(res.data[0]);
    setIngredientPurchaseHistoryForSearch(res.data[1]);
    setDataPaginating(false);
  };

  // get paginated purchases
  const setPaginatedIngredientPurchase = async (pageNo) => {
    setDataPaginating(true);
    const url = BASE_URL + "/settings/get-ingredient_purchase?page=" + pageNo;
    try {
      const res = await axios
        .get(url, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        });
        setPaginatedIngredientPurchases(res);
      localStorage.setItem(url, JSON.stringify(res));
    } catch (error) {
      if (offline(url)) {
        let parseRes = JSON.parse(localStorage.getItem(url));
        setPaginatedIngredientPurchases(parseRes);
      }
    }
  };

    //get expense

    const setExpenses = (res) => {
      setExpense(res.data[0]);
      setExpenseForSearch(res.data[1]);
      setLoading(false);
    };

    const getExpense = async () => {
      setLoading(true);
      const url = BASE_URL + "/settings/get-expense";
      try {
        const res = await axios
          .get(url,{
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setExpenses(res);
        localStorage.setItem(url, JSON.stringify(res));
      } catch (error) {
        if (offline(url)) {
          let parseRes = JSON.parse(localStorage.getItem(url));
          setExpenses(parseRes);
        }
        setLoading(false);
      }
    };
  
    const setPaginatedExpense = (res) => {
      setExpense(res.data[0]);
      setExpenseForSearch(res.data[1]);
      setDataPaginating(false);
    };
  
    // get paginated expense
    const getPaginatedExpense = async (pageNo) => {
      setDataPaginating(true);
      const url = BASE_URL + "/settings/get-expense?page=" + pageNo;
      try {
        const res = await axios
          .get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
          });
          setPaginatedExpense(res);
        localStorage.setItem(url, JSON.stringify(res));
      } catch (error) {
        if (offline(url)) {
          let parseRes = JSON.parse(localStorage.getItem(url));
          setPaginatedExpense(parseRes);
        }
      }
    };

        //get payment expense List

        const setExpensesList = (res) => {
          setPaymentExpense(res.data[0]);
          setPaymentExpenseForSearch(res.data[1]);
          setLoading(false);
        };
    
        const getExpenseList = async () => {
          setLoading(true);
          const url = BASE_URL + "/settings/get-payment-expense";
          try {
            const res = await axios
              .get(url,{
                headers: { Authorization: `Bearer ${getCookie()}` },
              });
              setExpensesList(res);
            localStorage.setItem(url, JSON.stringify(res));
          } catch (error) {
            if (offline(url)) {
              let parseRes = JSON.parse(localStorage.getItem(url));
              setExpensesList(parseRes);
            }
          }
        };
      
        const setPaginatedExpenseList = (res) => {
          setPaymentExpense(res.data[0]);
          setPaymentExpenseForSearch(res.data[1]);
          setDataPaginating(false);
        };
      
        // get paginated expense
        const getPaginatedExpenseList = async (pageNo) => {
          setDataPaginating(true);
          const url = BASE_URL + "/settings/get-payment-expense?page=" + pageNo;
          try {
            const res = await axios
              .get(url, {
                headers: { Authorization: `Bearer ${getCookie()}` },
              });
              setPaginatedExpenseList(res);
            localStorage.setItem(url, JSON.stringify(res));
          } catch (error) {
            if (offline(url)) {
              let parseRes = JSON.parse(localStorage.getItem(url));
              setPaginatedExpenseList(parseRes);
            }
          }
        };

        //Good receive notes ctx
        const setGoodRecieveNotes = (res) => {
          setGoodReceiveList(res.data[0]);
          setGoodReceiveListForSearch(res.data[1]);
          setLoading(false);
        };
      
        //get notes
        const getGoodRecieveNotes = async () => {
          setLoading(true);
          const url = BASE_URL + "/settings/get-ingredient_purchase";
          try {
            const res = await axios
              .get(url,{
                headers: { Authorization: `Bearer ${getCookie()}` },
              });
              setGoodRecieveNotes(res);
            localStorage.setItem(url, JSON.stringify(res));
          } catch (error) {
            if (offline(url)) {
              let parseRes = JSON.parse(localStorage.getItem(url));
              setIngredientPurchase(parseRes);
            }
            setLoading(false);
          }
        };
      
        const setPaginatedGoodRecieveNotes = (res) => {
          setGoodReceiveList(res.data[0]);
          setGoodReceiveListForSearch(res.data[1]);
          setDataPaginating(false);
        };
      
        // get paginated purchases
        const setPaginatedGoodsRecieveNotes = async (pageNo) => {
          setDataPaginating(true);
          const url = BASE_URL + "/settings/get-ingredient_purchase?page=" + pageNo;
          try {
            const res = await axios
              .get(url, {
                headers: { Authorization: `Bearer ${getCookie()}` },
              });
              setPaginatedGoodRecieveNotes(res);
            // localStorage.setItem(url, JSON.stringify(res));
          } catch (error) {
            console.log("good receive error==>",error)
            // if (offline(url)) {
            //   let parseRes = JSON.parse(localStorage.getItem(url));
            //   setPaginatedGoodRecieveNotes(parseRes);
            // }
            setDataPaginating(false)
          }
        };

        //set kds setting
        const setKdsListSetting = (res) => {
          setKdsList(res.data);
          setKdsListForSearch(res.data);
          setLoading(false);
        };
      
        //get kds setting
        const getKdsList = async () => {
          setLoading(true);
          const url = BASE_URL + "/settings/kdssettings";
          try {
            const res = await axios
              .get(url,{
                headers: { Authorization: `Bearer ${getCookie()}` },
              });
              setKdsListSetting(res);
            // localStorage.setItem(url, JSON.stringify(res));
          } catch (error) {
            setLoading(false);
            // if (offline(url)) {
            //   let parseRes = JSON.parse(localStorage.getItem(url));
            //   setIngredientPurchase(parseRes);
            // }
          }
        };                

  return (
    <RestaurantContext.Provider
      value={{
        //common
        loading,
        setLoading,
        //delvery pending
        getDeliveryPendingOrders,
        pendingOrdersDelivery,
        setPendingOrdersDelivery,
        pendingOrdersDeliveryForSearch,
        setPendingOrdersDeliveryForSearch,
        setPaginatedDeliveryPendingOrders,

        //delivery delivered
        getDeliveryDeliveredOrders,
        deliveredOrdersDelivery,
        setDeliveredOrdersDelivery,
        deliveredOrdersDeliveryForSearch,
        setDeliveredOrdersDeliveryForSearch,
        setPaginatedDeliveryDeliveredOrders,

        //ingredient group
        getIngredientGroup,
        ingredientGroupList,
        setIngredientGroupList,
        setPaginatedIngredientGroup,
        ingredientGroupForSearch,
        setIngredientGroupForSearch,

        //Recipes
        recipeList,
        recipeForSearch,
        setRecipeList,
        setRecipeForSearch,
        setPaginatedRecipeList,

        //md integration
        MdIntegrationList,
        setMdIntegrationList,
        MdIntegrationForSearch,
        setMdIntegrationForSearch,
        setPaginatedMdIntegrationGroup,
        getMdIntegrationGroup,

        //production
        getProductionItem,
        setPaginatedProductionItem,
        setProductionItemList,
        setProductionItemForSearch,
        productionItemList,
        productionItemForSearch,

        //ingredient item
        getIngredientItem,
        ingredientItemList,
        setIngredientItemList,
        setPaginatedIngredientItem,
        ingredientItemForSearch,
        setIngredientItemForSearch,
        ingredientItemStock,
        setIngredientItemStock,
        getIngredients,

        //ingredient limit
        setPaginatedIngredientLimit,
        setIngredientLimitList,
        setIngredientLimitForSearch,
        ingredientLimitList,
        ingredientLimitForSearch,

        //ingredient purchases
        getIngredientPurchase,
        ingredientPurchaseHistory,
        setIngredientPurchaseHistory,
        setPaginatedIngredientPurchase,
        ingredientPurchaseHistoryForSearch,
        setIngredientPurchaseHistoryForSearch,
        ingredientUnitForSearch,
        setIngredientUnitForSearch,
        ingredientList,
        setIngredientList,

        //purchase order
        getPurchaseOrder,
        getPaginatedPurchaseOrder,
        setPurchaseOrderHistory,
        setPurchaseOrderHistoryForSearch,
        purchaseOrderHistory,
        purchaseOrderHistoryForSearch,


        //website
        getBranchWeb,
        branchListWeb,
        setBranchListWeb,
        //branch
        getBranch,
        branchList,
        setBranchList,
        setPaginatedBranch,
        branchForSearch,
        setBranchforSearch,
        setBranchListforSearch,
        getPaginatedBranchList,
        branchForListSearch,
        //zone
        getZones,
        zoneList,
        zoneForSearch,
        setZoneList,
        setZoneForSearch,
        setPaginatedZones,
        //get areas
        getAreas,
        areaList, 
        setAreaList,
        areaForSearch, 
        setAreaForSearch,

        //branch list
        GetBranchList,
        getBranchList,
        setGetBranchList,
        getBranchForSearch,
        setGetBranchforSearch,

        //order types
        getOrderType,
        orderTypeList,
        setOrderTypeList,
        setPaginatedOrderType,
        orderTypeForSearch,
        setOrderTypeForSearch,

        //table
        getTable,
        tableList,
        setTableList,
        setPaginatedTable,
        tableForSearch,
        setTableforSearch,

        //table
        getFloor,
        floorList,
        setFloorList,
        setPaginatedFloor,
        floorForSearch,
        setFloorforSearch,

      //dept-tag
      getDeptTag,
      deptTagList,
      setDeptTagList,
      setPaginatedDeptTag,
      deptTagForSearch,
      setDeptTagForSearch,

      //kot
      getKOT,
      KOTList,
      setKOTList,
      setPaginatedKOT,
      KOTForSearch,
      setKOTForSearch,

      //scheme
      getScheme,
      schemeList,
      setSchemeList,
      setPaginatedScheme,
      schemeForSearch,
      setSchemeForSearch,
      schemeForSearchObj,
      setSchemeForSearchObj,
      
      //card
      getCard,
      cardList,
      setCardList,
      cardForSearch,
      setCardForSearch,
      setPaginatedCard,

      //payment types
      getPaymentType,
      paymentTypeList,
      setPaymentTypeList,
      setPaginatedPaymentType,
      paymentTypeForSearch,
      setPaymentTypeforSearch,

      //payment type Taxes
      getPaymentTypeTax,
      paymentTypeTaxForSearch,
      setPaymentTypeTaxforSearch,

      //work period
      getWorkPeriod,
      workPeriodList,
      setWorkPeriodList,
      setPaginatedWorkPeriod,
      workPeriodForSearch,
      setWorkPeriodListForSearch,
      // setDayOpen,
      // dayOpen,
      // setOpenDay,
      getWorkPeriodForBranch,
      workPeriodListForBranch,
      setWorkPeriodListForBranch,
      //running order
      getRunningOrders,
      runningOrders,
      setRunningOrders,

      //submitted orders
      getSubmittedOrders,
      submittedOrders,
      setSubmittedOrders,
      setPaginatedSubmittedOrders,
      submittedOrdersForSearch,
      setSubmittedOrdersForSearch,

      //settled orders
      getSettledOrders,
      settledOrders,
      setSettledOrders,
      setPaginatedSettledOrders,
      settledOrdersForSearch,
      setSettledOrdersForSearch,

      //kitchen dashboard
      getKitchenNewOrders,
      kithcenNewOrders,
      setKithcenNewOrders,
      getKitchenNewOrdersOnline,
      kithcenNewOrdersOnline,
      setKithcenNewOrdersOnline,

      //order histories
      getAllOrders,
      allOrders,
      setAllOrders,
      setPaginatedAllOrders,
      setPaginatedFilteredOrders,
      setPaginatedSearchOrders,
      setPaginatedSearchedOrders,
      allOrdersForSearch,
      setAllOrdersForSearch,

      //expense
      setExpense,
      setExpenseForSearch,
      expense,
      expenseForSearch,
      getExpense,
      getPaginatedExpense,

      // payment expense
      expensePayment,
      setPaymentExpense,
      paymentExpenseForSearch,
      setPaymentExpenseForSearch,
      getPaginatedExpenseList,
      getExpenseList,


      //onlineOrder histories
      getAllOnlineOrders,
      allOnlineOrders,
      setAllOnlineOrders,
      setPaginatedAllOnlineOrders,
      allOnlineOrdersForSearch,
      setAllOnlineOrdersForSearch,

      //goods recieve
      goodReceiveList,
      setGoodReceiveList,
      goodReceiveListForSearch,
      setGoodReceiveListForSearch,
      getGoodRecieveNotes,
      setPaginatedGoodsRecieveNotes,

      //KDS Setting
      kdsList,
      setKdsList,
      kdsListForSearch,
      setKdsListForSearch,
      getKdsList,        
      orderCounter,
      setOrderCounter,
      audioInstance,
      bellRing,
      setBellRing,
      //pagination
      dataPaginating,
      setDataPaginating,
      setPaginatedIngredientUnit,
     }}
    >
      {children}
    </RestaurantContext.Provider>
  );
};

export { RestaurantContext, RestaurantProvider };
