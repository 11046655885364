import React, { useContext, useState,useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";
import { useReactToPrint } from "react-to-print";

//functions
import {
  _t,
  customStyle,
  formatPrice,
  getCookie,
  getSystemSettings,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "react-datepicker/dist/react-datepicker.css";
import ChartOfAccPrint from "../transactions/ChartOfAccPrint";

//pages & includes
import AccountsSidebar from "../AccountsSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";

const accountsLevels = [
    {
        id:1,
        name: "Level-1"
    },
    {
        id:2,
        name: "Level-2"
    },
    {
        id:3,
        name: "Complete"
    }
]

const AccountsReport = () => {
    const { t } = useTranslation();
    let { loading, setLoading, generalSettings } = useContext(SettingsContext);

    //toast obj
    let common_toast={
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    }

    //reportFields state
    const [reportFields,setReportFields]=useState(accountsLevels[2]);

    //report states
    const [reportData, setReportData] = useState([]);

    const componentRef = useRef();

    const handleAccount=(account)=>{
        setReportFields(account)
    }

    const handleOnlyPrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleSubmit=(e)=>{
        e.preventDefault();
        if(reportFields){
            // setLoading(true);
            const url = BASE_URL + "/settings/get-consumption-report";
            let formData = new FormData();
            formData.append('type', reportFields ? reportFields.name : "");

            return axios
            .post(url, formData, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              setReportData(res.data);
              setTimeout(() => {
                handleOnlyPrint()          
              }, 200);
              setLoading(false);
            })
            .catch((error) => {            
              setLoading(false);
              toast.error(`${_t(t("Please try again"))}`,common_toast);
            });
            
        }else{
            toast.error(`${_t(t("Please select from date & to date"))}`,common_toast);
        }
    }


  return (
    <>
    <Helmet>
      <title>{_t(t("Accounts Accounts Report"))}</title>
    </Helmet>

    {/* main body */}
    <main id="main" data-simplebar>
      <div className="container-fluid">
        <div className="row t-mt-10 gx-2">
          {/* left Sidebar */}
          <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
            <AccountsSidebar />
          </div>
          {/* left Sidebar ends */}

          {/* Rightbar contents */}
          <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
            <div className="t-bg-white">
              <div
                className="fk-scroll--pos-menu table-bottom-info-hide"
                data-simplebar
              >
                <div className="t-pl-15 t-pr-15">
                  {/* next page data spin loading */}
                  <div className={`${(loading) && "loadingBlur"}`}></div>
                  <div className={`${(loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                  {/* spin loading ends */}

                  {/* Loading effect */}
                  {/* {loading === true ? (
                    tableLoading()
                  ) : ( */}
                    <div key="fragment-3">
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                          <ul className="t-list fk-breadcrumb">
                            <li className="fk-breadcrumb__list">
                              <span className="t-link fk-breadcrumb__link text-capitalize">
                                {_t(t("Accounts Report"))}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6 col-lg-7">
                          <div className="row gx-3 align-items-center"></div>
                        </div>
                      </div>

                      {/* Form starts here */}
                      <form
                        className="row card p-2 mx-3 mb-5 table-text"
                        onSubmit={handleSubmit}
                      >
                        <div className="col-12">
                            <div>                            
                                <div className="row">
                                <div className="form-group mt-2 col-3">
                                    <div className="mb-2 text-left">
                                        <label
                                        htmlFor="transaction"
                                        className="control-label"
                                        >
                                        {_t(t("Level Of Account"))}                                  
                                        </label>
                                    </div>
                                    <Select
                                        options={accountsLevels ? accountsLevels : []}
                                        components={makeAnimated()}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        value={reportFields || ""}
                                        classNamePrefix="select table-text"
                                        onChange={(acc)=>handleAccount(acc)}                                
                                        placeholder={
                                            _t(t("Select acc")) + ".."
                                        }
                                        styles={customStyle}
                                        required
                                    />
                                </div>

                                <div className="col-7"></div>
                                
                                <div className="form-group mt-4 col-2">                                                                                                                        
                                    <button
                                    disabled={loading}
                                    type="submit"
                                    className="btn btn-primary rounded mt-2"
                                    >
                                      <i className="fa fa-table" aria-hidden="true"></i> {_t(t("Print Accounts"))}
                                    </button>                                                                                                                                                                                       
                                </div>
                            </div>
                          </div>                                                                        
                        </div>
                      </form>
                    </div>
                </div>
              </div>
            </div>
        </div>
          {/* Rightbar contents end*/}
        </div>
      </div>
    </main>
    {/* main body ends */}
    <ChartOfAccPrint 
        name = {reportFields?.name}
        chartofAccPrint={reportData}
        ChartOfAcc={componentRef}
    />  
  </>
  )
}

export default AccountsReport;
