import React, { useContext, useEffect, useRef, useState } from "react";
import { RestaurantContext } from "../../../../../../contexts/Restaurant";
import { SettingsContext } from "../../../../../../contexts/Settings";
import { FoodContext } from "../../../../../../contexts/Food";
import moment from "moment";
import { Flip, toast } from "react-toastify";
import LoyaltyImg from "../../../../public/loyalty.png"; 
import axios from "axios";
import { BASE_URL } from "../../../../../../BaseUrl";
import { useTranslation } from "react-i18next";
import {
  _t,
  getCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
  getBeforeTaxPricePayment,
} from "../../../../../../functions/Functions";
import { useCustomHooks } from "../../../../../../functions/Hooks";
import { UserContext } from "../../../../../../contexts/User";
import SplitPayment from "../ItemModal/SplitPayment";
import { useHistory, useParams } from "react-router-dom";
import CardModal from "../../modal/CardModal";

const SettleOrder = ({
  managerId,
  showSettle,
  setSelectedKot,
  selectedDeptTag,
  cartTotal,
  cartSubTotal,
  selectedParty,
  selectedDeliveryType,
  newOrder,
  cartItems,
  buttonDisabled,
  setButtonDisabled,
  activeItemInOrder,
  setActiveItemInOrder,
  taxType,
  deptCommission,
  loyaltyPoint,
  setloyaltyPoint,
  tokenNumber,
  paymentTypeAvailable,
  returnMoneyUsd,
  setReturnMoneyUsd,
  serviceCharges,
  paidMoney,
  setPaidMoney,
  orderDetails,
  handleKotItems,
  totalsettle,
  setTotalSettle,
  theVat,
  theTax,
  setTheVatSettle,
  theVatSettle,
  showPriceOfEachOrderItem,
  setOrderDetails,
  setShowSettle,
  setNewSettings,
  deliveryCharges,
  newSettings,
  isSettle,
  fbrInvoiceNum,
  srbInvoiceNum,
  handleLocalPrint,
  handleReceiptPrint,
  token_No
}) => {
  const history = useHistory();
  const { order_id } = useParams();
  const { t } = useTranslation();
  const { roundOffPrice, debouncedFetchResults } = useCustomHooks();
  const { workPeriodListForBranch,setWorkPeriodListForBranch } =  useContext(RestaurantContext);
  const { propertyGroupForSearch, getFoodGroupForPOS } = useContext(FoodContext);
  const {
    //common
    loading,
    setLoading,
    generalSettings,
    // showManageStock,
  } = useContext(SettingsContext);
  const { authUserInfo } = useContext(UserContext);

  //split payment
  const [payModal, setPayModal] = useState(false);
  const [payment, setPayment] = useState("");
  const [paySelected,setPaySelected] = useState(null);
 
  //vat
  const [theTaxSettle, setTheTaxSettle] = useState(0);
  // tax difference
  const [taxDiff,setTaxDiff] = useState(false);
  const cardAdd = useRef();
  const [cardNumber,setCardNumber] = useState({
    first: "******",
    middle:"****",
    last:""
  });
  const inputRefs = {
    first: useRef(),
    middle: useRef(),
    last: useRef(),
    paid: useRef(),
  };

  // check internet
  let networkStatus = true;

  // check internet connection
 const checkNetworkStatus = async () => {
   try {
     const currencyUrl = BASE_URL + "/check-net";
     const response = await axios.get(currencyUrl,{
      timeout: 3000, 
    });
     if(response.data == 1){
      networkStatus = true
     }else{
      networkStatus = false
     }
   } catch (error) {
     networkStatus = false;
   }
 }

  // redeem point
  const handleRedeemPoint = (e) => {
    const value = e.target.value; 
    if(value <= loyaltyPoint.availablePoints){
      setloyaltyPoint({
        ...loyaltyPoint,
        redeemPoints: value
      });
      let total = Number(cartTotal) - Number(value);
      if(getSystemSettings(generalSettings, "user_wise_integration") == 1 ? authUserInfo.details.sbr_enabled === 1 : (orderDetails.branch.sbr_integration === 1 ||
        orderDetails.branch.fbr_integration === 1 || orderDetails.branch.cbr_integration === 1 || orderDetails.branch?.pbr_integration === 1)){
        total += 1
      }
      setTotalSettle(total);
    }else{
      toast.error("You can't redeem more than your points",toastObj)
    } 
  }

  //clear after settle 
  const handleClearSettle = () =>{
    setCardNumber({
      first:"******",
      middle:"****",
      last:""
    });
    setPayment("");
    setPaySelected(null); 
  }

  const handleGoBack = ()=> {
    setShowSettle(false);
    setTotalSettle(0);
    setPaidMoney(0);
    setTheVatSettle(theVat);
    setReturnMoneyUsd(0);
    setPaySelected(null); 
    setPayment("");
    setOrderDetails({
      ...orderDetails,
      payment_type: null,
      order_tip: 0,
      card_number_settle: "",
    });
    setCardNumber({
      first: "******",
      middle:"****",
      last:""
    })
    setloyaltyPoint({
      qr_code: "",
      availablePoints: 0,
      redeemPoints: 0,
      type: "token",
    })
  }

 const handleToggle = (option) => {
  setloyaltyPoint({
    ...loyaltyPoint,
    qr_code: option === "phone" ? (orderDetails.newCustomerInfo.phn_no) : "",
    type: option
  });
 };

 //clear paid and 
 const handleClearAmount = () =>{
  setPaidMoney(0);
  setReturnMoneyUsd(0);
  setPayment("");
  setPaySelected(null);
 }

 const handleClearAll = () =>{
  handleClearAmount()
  let total = Number(cartTotal)
  if(getSystemSettings(generalSettings, "user_wise_integration") == 1 ? authUserInfo.details.sbr_enabled === 1 : (orderDetails.branch.sbr_integration === 1 ||
    orderDetails.branch.fbr_integration === 1 || orderDetails.branch.cbr_integration === 1 || orderDetails.branch?.pbr_integration === 1)){
    total += 1
  }
  if(loyaltyPoint.redeemPoints){
    total -= Number(loyaltyPoint.redeemPoints) 
  }
  setTheVatSettle(theVat)
  setTotalSettle(total);
  setOrderDetails({
    ...orderDetails,
    payment_type: null,
    card_number_settle: "",
  });
  setCardNumber({
    first: "******",
    middle:"****",
    last:""
  });
 }
  // split pay on click
  const handleClick = () =>{
    setPaidMoney(0);
    setReturnMoneyUsd(0);
    setPayment("");
    setTheVatSettle(theVat);
    setPaySelected(null);
    setOrderDetails({
      ...orderDetails,
      payment_type: null,
    });
    setPayModal(false)
  }
  
  //payment type select
  const handleSetPayment = (pay) =>{
    setCardNumber({
      first: "******",
      middle:"****",
      last:""
    });
    if(pay?.view_card === 1){
      cardAdd.current.classList += " showing";
      inputRefs.last.current.focus();
    }
    setPaySelected(pay)
  }

  //debounce
  const handleAutoSplitPayment = (value) =>{
    let totalAmount = Number(cartTotal);
    if(getSystemSettings(generalSettings, "user_wise_integration") == 1 ? authUserInfo.details.sbr_enabled === 1 : (orderDetails.branch.sbr_integration === 1 ||
      orderDetails.branch.fbr_integration === 1 || orderDetails.branch.cbr_integration === 1 || orderDetails.branch?.pbr_integration === 1)){
      totalAmount += 1
    }
    let remainingAmount = totalAmount - parseFloat(value);
    if(loyaltyPoint.redeemPoints){
      remainingAmount -= Number(loyaltyPoint.redeemPoints);
    }
    if(remainingAmount > 0){
      setPayment((prevPayment) => ({ ...prevPayment, select_split: roundOffPrice(remainingAmount) }));
    }else{
      setPayment((curr)=>{
        const newData = {
          ...curr
        }
        newData["select_split"] && delete newData["select_split"]
        return newData
      });
    }
  }
  
  // Handle input change
  const handleChange = async(e) => {
    const value = e.target.value;
    setloyaltyPoint({
      ...loyaltyPoint,
      qr_code: value,
    })
    if(value && (loyaltyPoint.type === "phone" ? (value?.length > 8) : (value?.length > 4))){
      let result = await debouncedFetchResults(e.target.value, loyaltyPoint.type,setButtonDisabled);
      if(result){
        setloyaltyPoint((curr)=>({
          ...curr,
          availablePoints: result,
        }))
      }
      setButtonDisabled(false)
    }
  };
  
  //split payment on chnage
  const handleSplitPayment = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if(payment && (value == 0 || value === "")){
      setPayment((curr)=>{
        const newData = {
          ...curr
        }
        newData[id] && delete newData[id];
        if(!isNaN(Number(id))){
          newData["select_split"] && delete newData["select_split"]        
        }
        return newData
      });

    }else{
      setPayment((prevPayment) => ({ ...prevPayment, [id]: value }));
      if(!isNaN(Number(id))){
        handleAutoSplitPayment(value);
      }
    }
  };

  //split payment submit
  const handleSetpaymentTypeMuliple = (e) => {
    e.preventDefault();
    if(payment){
      if(!paySelected){
        toast.error(
          "Please select the payment type to split payment", toastObj
        );
        return false;
      }
      let total = 0;
     
      const paymentMethod = payment && Object?.keys(payment).map((id)=>{
        const amount = parseFloat(payment[id]);
        const type = isNaN(Number(id)) ? paySelected : paymentTypeAvailable.find(payType => payType.id === Number(id));
        const taxAmount = (getBeforeTaxPricePayment(amount, orderDetails.branch.branch_tax));
        total += amount;
        return{
          ...type,
          tax_percent: orderDetails.branch.branch_tax,
          tax_amount: roundOffPrice(taxAmount),
          bill_amount : payment[id]
        };
      });
      let localCurrency = JSON.parse(localStorage.getItem("currency"));
      let theUsdPaid = total / localCurrency.rate;
      let totalAmount = parseFloat(cartTotal);
      if(getSystemSettings(generalSettings, "user_wise_integration") == 1 ? authUserInfo.details.sbr_enabled === 1 : (orderDetails.branch.sbr_integration === 1 || 
        orderDetails.branch.fbr_integration === 1 || orderDetails.branch.cbr_integration === 1 || orderDetails.branch?.pbr_integration === 1)){
        totalAmount += 1
      }
      if(loyaltyPoint.redeemPoints){
        totalAmount -= Number(loyaltyPoint.redeemPoints) 
      }
      if(roundOffPrice(theUsdPaid) > roundOffPrice(totalAmount)){
        setPayModal(true)
        setPaidMoney(0);
        setTheVatSettle(theVat);
        setReturnMoneyUsd(0);
        setTotalSettle(parseFloat(roundOffPrice(totalAmount)));
        toast.error(
          "Please enter paid amount exact equal to the total bill amount",
          toastObj
        );
        return false
      }
      
      if (roundOffPrice(theUsdPaid) < roundOffPrice(totalAmount)) {
        setPayModal(true)
        setReturnMoneyUsd(0);
        setPaidMoney(0);
        setTheVatSettle(theVat);
        setTotalSettle(parseFloat(roundOffPrice(totalAmount)));
        toast.error(
          "Please enter paid amount atleast equal to the total bill amount",
          toastObj
        );
      } else {
        setTheTaxSettle(orderDetails.branch.branch_tax);
        setTheVatSettle(theVat);
        setTotalSettle(parseFloat(roundOffPrice(totalAmount)));
        setPaidMoney(parseFloat(roundOffPrice(total)));
        setPayModal(false)
        setOrderDetails({
          ...orderDetails,
          payment_type: paymentMethod,
        });
        let theReturnMoney = theUsdPaid - parseFloat(totalAmount);
        setReturnMoneyUsd(roundOffPrice(theReturnMoney));
      }

    }else{
      toast.error(
        "Please select the payment type to split payment",
        toastObj
      );
    }

  };

  const handleCardSubmit = (e) =>{
    e.preventDefault();

    // if(cardNumber.first?.length < 6){
    //  toast.error("Please enter valid card number");
    //  return false
    // }else 
    if(cardNumber.last?.length < 6){
      toast.error("Please enter valid card number");
      return false
    }
   
    const cardNumberFinal = cardNumber.first + cardNumber.middle + cardNumber.last;
    setOrderDetails({
      ...orderDetails,
      card_number_settle: cardNumberFinal
    })
    
    cardAdd.current.classList.remove("showing");
  }
  
  const handleCardChange = (part, value) => {
    setCardNumber((prev) => ({
      ...prev,
      [part]: value,
    }));
  };

  useEffect(()=>{
    if(showSettle){
      inputRefs.paid.current.focus();
    }
  },[showSettle])

  // LOCAL FUNCTIONS
  function bracketReplace(id) {
    let property_group_id = id.replace(/[\[\]']+/g, "");
    return property_group_id;
  }

  const toastObj = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    className: "text-center toast-notification",
  };

  //payment type pos 2
  const handleSetpaymentTypeSingle = (payment_type) => {
    let b_tax = orderDetails.branch?.payment_tax[payment_type.name];
    if(payment_type?.view_card === 1){
      cardAdd.current.classList += " showing";
      inputRefs.last.current.focus();
    }
    setCardNumber({
      first: "******",
      middle:"****",
      last:""
    });
    setPaySelected(null); 
    setPayment("");

    let inclusive_vat = 0;
    let previous_amount = 0;
    let new_amount = 0;
    let check = 0;
    let totalSettle2 = parseFloat(cartTotal);
    if(getSystemSettings(generalSettings, "user_wise_integration") == 1 ? authUserInfo.details.sbr_enabled === 1 : (orderDetails.branch.sbr_integration === 1 ||
      orderDetails.branch.fbr_integration === 1 || orderDetails.branch.cbr_integration === 1 || orderDetails.branch?.pbr_integration === 1)){
      totalSettle2 += 1
    }
   
    if (b_tax && b_tax >= 0) {
      setTheTaxSettle(b_tax);
      let vatCalculate = getSystemSettings(generalSettings, "tax_type_discount") === "tax_discount_after" ? (parseFloat(cartSubTotal) - parseFloat(orderDetails.scheme.discount_amount)) : parseFloat(cartSubTotal);
      inclusive_vat = vatCalculate * (parseFloat(b_tax) / 100);
      previous_amount = parseFloat(cartTotal) - parseFloat(theVat);

      new_amount = previous_amount + inclusive_vat;
      if(getSystemSettings(generalSettings, "user_wise_integration") == 1 ? authUserInfo.details.sbr_enabled === 1 : (orderDetails.branch.sbr_integration === 1 ||
        orderDetails.branch.fbr_integration === 1|| orderDetails.branch.cbr_integration === 1 || orderDetails.branch?.pbr_integration === 1)){
        new_amount += 1
      }
      totalSettle2 = new_amount;
      if(loyaltyPoint.redeemPoints){
        new_amount -= Number(loyaltyPoint.redeemPoints);
      }
      
      setTheVatSettle(parseFloat(roundOffPrice(inclusive_vat)));
      setTotalSettle(parseFloat(roundOffPrice(new_amount)));
      setPaidMoney(parseFloat(roundOffPrice(new_amount)));
      
      check = 1;
    }

    if(taxType === "inclusive" && !isNaN(b_tax) && orderDetails.branch?.branch_tax !== b_tax){
      setTaxDiff(true);
    }else{
      setTaxDiff(false);
    }

    //setTotalPayable(50);
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    let theUsdPaid = (new_amount ? new_amount : paidMoney)  / localCurrency?.rate;
    if(loyaltyPoint.redeemPoints){
      totalSettle2 -= Number(loyaltyPoint.redeemPoints);
    }
    if (check === 0) {
      if (parseFloat(theUsdPaid.toFixed(2)) < parseFloat(totalSettle2.toFixed(2))) {
        setReturnMoneyUsd(0);
        toast.error("Please enter paid amount atleast equal to the total bill amount",toastObj);
      }
      setTheVatSettle(theVat);
      setTotalSettle(parseFloat(roundOffPrice(totalSettle2)));
    }
    const tax_percent = b_tax > 0 ? b_tax : orderDetails.branch.branch_tax;
    const tax_amount = inclusive_vat > 0 ? inclusive_vat : theVat;

    setOrderDetails({
      ...orderDetails,
      payment_type: [{...payment_type, bill_amount: parseFloat(roundOffPrice(totalSettle2)), tax_percent , tax_amount }],
      card_number_settle: "",
    });
    let theReturnMoney = parseFloat(theUsdPaid.toFixed(2)) - parseFloat(totalSettle2.toFixed(2));
    setReturnMoneyUsd(roundOffPrice(theReturnMoney));
  };
 

  //handle settle order
  const handleSettleOrder = (e) => {
    e.preventDefault();

   if(orderDetails?.payment_type && orderDetails.payment_type[0]?.view_card === 1 && (orderDetails.card_number_settle === null || orderDetails.card_number_settle ==="")){
    toast.error(`${_t(t("Please Enter Card Number"))}`, toastObj);
    cardAdd.current.classList += " showing";
    return false;
   }
   
  if (
    (!orderDetails.token?.id) || typeof(orderDetails.token) !== 'object'
  ) {
    tokenNumber();
    return false;
  }

   if (
     selectedParty?.name.match(/food(.*)panda/i) &&
     parseInt(selectedParty.show_ref_no) === 1 &&
     orderDetails.newCustomerInfo.refNo?.length < selectedParty.ref_min && !order_id
  ) {
    toast.error(`${_t(t(`Please enter ref no between ${selectedParty.ref_min}-${selectedParty.ref_max} digits`))}`, toastObj);
    return false;
  }
 
    if (newOrder && newOrder.length > 0) {

      if(isNaN(totalsettle)){
        toast.error(`${_t(_t("Total amount can't be NaN"))}`, toastObj);
        return false;
      }

      if ((paidMoney) < totalsettle) {
        toast.error(
          "Please enter paid amount atleast equal to the total bill amount",
          toastObj
        );
      } else {
        if (orderDetails.payment_type === null) {
          toast.error("Please Select The Payment Type", toastObj);
        } else {
          setNewSettings({
            ...newSettings,
            vat:
              theTax > 0 ? theTax : orderDetails.branch.branch_tax,
          });
          setButtonDisabled(true);
          setReturnMoneyUsd(roundOffPrice(paidMoney - totalsettle));
          axiosRequestForSettle();
        }
      }
    } else {
      toast.error("Please add items in order list", toastObj);
    }
  };

  // sync order
  const syncSettleToLocalOffline = async (url) => {
    let local = JSON.parse(localStorage.getItem("ordersettle"));
    if (local != null) {
      for (let i = 0; i < local.length; i++) {
        (async function (i) {
          await new Promise(resolve => setTimeout(resolve, i * 4000));
          try {
            await createSettleOrder(url, local[i]);
          } catch (error) {
            console.error("Error:", error);
          }
        })(i);
      }
      await new Promise(resolve => setTimeout(resolve, 3000));
      localStorage.removeItem("ordersettle");
    }
  };

  const getDataFromLocal = (url) => {
    return JSON.parse(localStorage.getItem(url));
  };
  const setDataToLocal = (url, res) => {
    localStorage.setItem(url, JSON.stringify(res));
  };

  const saveOrderToLocalOffline = (formData) => {
    formData.local = true;
    let localData = getDataFromLocal("ordersettle");
    if (localData != null) {
      localData.push(formData);
      setDataToLocal("ordersettle", localData);
    } else {
      let data = [];
      data.push(formData);
      setDataToLocal("ordersettle", data);
    }
    setLoading(false);
  };

  const createSettleOrder = (url, formData) => {
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(async (res) => {
        if(res.data === "exists"){
          toast.error(`${_t(_t("Order already settle, You have click settle button again & again"))}`, toastObj);
          history.push('/dashboard/pos');
          setSelectedKot({});
          setButtonDisabled(false);
          setLoading(false);
          return false
        }
        if (formData.local) {
          handleCreateSettleOrder(res, formData.local);
        } else {
          handleCreateSettleOrder(res);
        }
      })
      .catch((error) => {
        
        if(parseInt(getSystemSettings(generalSettings, "fgs_enable")) === 1 && error.response.data?.message !== ""){
          toast.error(<p style={{whiteSpace:"pre-line"}}>{error.response.data.message}</p>, toastObj);
          setSelectedKot({});
          setButtonDisabled(false);
          setLoading(false);
          return false
        } 
        if (!networkStatus && parseInt(getSystemSettings(generalSettings, "offline_enable")) === 1) {
          let formLocal = formData?.local ? formData?.local : false;
          saveOrderToLocalOffline(formData);
          setLoading(false);
          let newRes = {};
          // let workPeriod = await getWorkPeriod();
          let workperiod = [];
          workperiod.push(workPeriodListForBranch);
          newRes.data = workperiod;
          handleCreateSettleOrder(newRes,formLocal);
          toast.success(`${_t(t("Order Settle Saved To Local"))}`, toastObj);
        } else {
          
          setSelectedKot({});
          let data = [];
          data.push(workPeriodListForBranch);
          //set work period
          let theWorkPeriod = null;
          if (
            orderDetails.branch !== undefined &&
            orderDetails.branch !== null &&
            authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager"
          ) {
            theWorkPeriod =
              data[0] &&
              data[0].find((tempWorkPeriod) => {
                return (
                  (orderDetails.branch.id === parseInt(tempWorkPeriod.branch_id)) && tempWorkPeriod.ended_at === null
                );
              });
          } else {
            theWorkPeriod = data && data[0][0];
          }
          setTimeout(() => {
            setNewSettings({
              ...newSettings,
              vat: orderDetails.branch.branch_tax,
              workPeriod:
                theWorkPeriod !== undefined ? theWorkPeriod : null,
            });
          }, 500);
          setLoading(false);
          if(networkStatus){
            toast.error(`${_t(_t("Please contact support"))}`, toastObj);
          }
          else{
            toast.error(`${_t(_t("Please check your internet connection"))}`, toastObj);
          }
          setButtonDisabled(false);
        }
      });
  };
  
  //axios request for settlement
  const axiosRequestForSettle = async() => {
    let api =  order_id ? "/settings/settle-submitted-order" : "/settings/settle-order" ;
    let url = BASE_URL + api;
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
     await checkNetworkStatus();
   
    if (networkStatus) {
      syncSettleToLocalOffline(url);
    }
    let partyFinal = {
      partyId: selectedParty && selectedParty.id,
      partyName: selectedParty && selectedParty.name,
      partyPercent: selectedParty && selectedParty.receivable_amount,
      partyRefNo: selectedParty && selectedParty.show_ref_no,
     }
  
     let orderTypeFinal = {
      orderTypeId: selectedDeliveryType && selectedDeliveryType.id,
      orderTypeName: selectedDeliveryType && selectedDeliveryType.name,
    }

    if (
      (!orderTypeFinal && !orderTypeFinal?.orderTypeId) ||
      (!partyFinal && !partyFinal?.partyId)
    ) {
      let error = "";
      if (
        (!selectedParty || !selectedParty.id) ||
        (!partyFinal && !partyFinal.partyId)
      )
        error = "party";
      if (
        (!selectedDeliveryType || !selectedDeliveryType.id) ||
        (!orderTypeFinal && !orderTypeFinal?.orderTypeId)
      )
        error = "delivery type";
      if (error) {
        toast.error(`Please select ${error} first.`);
        return false
      }
    }
   
    let formData = {
     branch: orderDetails.branch,
     manager_id: managerId ? managerId : null,
     card_number: orderDetails.card_number,
     payment_card_number: orderDetails.card_number_settle,
     order_tip: orderDetails.order_tip,
     loyalty_type: loyaltyPoint.type,
     loyalty_qr_code: loyaltyPoint.qr_code,
     redeem_points: loyaltyPoint.redeemPoints,
     available_points: loyaltyPoint.availablePoints,
     table: orderDetails.table,
     waiter: orderDetails.waiter,
     dept_tag: selectedDeptTag,
     payment_type: orderDetails.payment_type,
     payment_amount: orderDetails.payment_amount,
     customerInfo: orderDetails.newCustomerInfo,
     note_to_rider: orderDetails.note_to_rider,
     token: orderDetails.token,
     total_guest: orderDetails.total_guest,
     orderItems: newOrder,
     serviceCharge: deliveryCharges,
     scheme: orderDetails.scheme,
     discount: orderDetails.discount,
     orderTypeDetails: orderTypeFinal?.orderTypeId ? orderTypeFinal : orderDetails.orderTypeDetails,
     partyDetails: partyFinal?.partyId ? partyFinal : orderDetails.partyDetails,
     service_amount: serviceCharges,
     subTotal: cartSubTotal,
     totalPayable: totalsettle,
     paidMoney: paidMoney,
     theVat: theVatSettle > 0 ? theVatSettle : theVat,
     tax_percent: theTaxSettle > 0 ? theTaxSettle : theTax,
     dept_commission: deptCommission,
     localCurrency: localCurrency,
     workPeriod: newSettings.workPeriod,
   };
   
   handleKotItems(); 
  
   setLoading(true);
   createSettleOrder(url,formData)
      
  };

  // save to local if offline
  const handleCreateSettleOrder =(res, local = false)=>{
    if (res.data !== "ended") {
      if (res.data !== "paymentIssue") {
        isSettle.current = 1;
        fbrInvoiceNum.current = res.data[2];
        srbInvoiceNum.current = res.data[3];
        getFoodGroupForPOS();
        //getFoodGroup();
        if(local == false){
          let formatTime = moment(res.data[5]).format('DD-MMM-YYYY hh:mm A');
          token_No.current = parseInt(getSystemSettings(generalSettings, "order_no")) === 1 ? {id:res.data[4] ? res.data[4] : orderDetails.token?.id, time:formatTime} : {id:res.data[1] ? res.data[1] : orderDetails.token?.id, time:formatTime};
          setOrderDetails({
            ...orderDetails,
            token : {
              ...orderDetails.token,
              id: res.data[1] ? res.data[1] : orderDetails.token?.id,
            },
            order_No : res.data[4] ? res.data[4] : orderDetails.token?.id,
          })
        }
        //setCustomerForSearch(res.data[0][1]);
        // setWorkPeriodList(res.data[0][0]);
        setWorkPeriodListForBranch(res.data[0]);
          
        //set work period
        let theWorkPeriod = null;
        if (
          orderDetails.branch !== undefined &&
          orderDetails.branch !== null &&
          authUserInfo.details.user_type !== "staff" && authUserInfo.details.user_type !== "manager"
          ) {
         theWorkPeriod =
          res.data[0] &&
          res.data[0].find((tempWorkPeriod) => {
            return (
              orderDetails.branch.id ===
              parseInt(tempWorkPeriod.branch_id) && tempWorkPeriod.ended_at === null
            );
          });
          } else {
            theWorkPeriod = res.data[0][0];
          }

          //set work period here
          setNewSettings({
            ...newSettings,
            vat:
              theTax > 0 ? theTax : orderDetails.branch.branch_tax,
            workPeriod:
              theWorkPeriod !== undefined ? theWorkPeriod : null,
          });

          const directPrint = parseInt(getSystemSettings(generalSettings, "direct_ip_print")) === 1;
          if(res.data[0] && local == false){
            directPrint ? handleLocalPrint(local) : handleReceiptPrint();
          };
         
          handleClearSettle();
          
          setLoading(false)
          //check sales limit target
          // if(authUserInfo.details.user_type === "staff" && authUserInfo?.details?.sales_limit > 0){
          //   if(res?.data[6] > authUserInfo?.details?.sales_limit){
          //     toast.success('Congratulations🎉, you have achieved your target!', {
          //       position: "top-left",
          //       autoClose: 7000,
          //       hideProgressBar: false,
          //       closeOnClick: true,
          //       pauseOnHover: true,
          //       hideProgressBar: true,
          //       // draggable: true,
          //       // progress: undefined,
          //       transition: Flip,
          //       style:{backgroundColor:"#ed8153"}
          //       });
          //   }
          // }
      } else {
        setLoading(false);
        setButtonDisabled(false);
        toast.error(
          `${_t(
            t(
              "Please enter paid amount atleast equal to the total bill amount"
            )
          )}`,
          toastObj
        );
      }
    } else {
      setLoading(false);
      setButtonDisabled(false);
      toast.error(`${_t(t("Please restart the work period"))}`, toastObj);
    }
    
  }


  return (
    <>
      {/* show settle  */}
      <div
        className={`fk-settle-container d-flex flex-column justify-content-center ${
          showSettle ? "" : "d-none"
        }`}
      >
      <div className={`${(buttonDisabled) && "loadingBlur"}`}></div>
      <div className={`${(buttonDisabled) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      
        <div className="fk-settle">
          <div className="container-fluid">
            <div className="row gx-3">
              <div className="col-lg-6 d-none d-lg-block">
                <div className="d-flex justify-content-between px-2">
                <div className="table-text d-block text-capitalize font-weight-bold py-3">
                  New Order {orderDetails?.table && `(Table: ${orderDetails.table ? orderDetails.table?.name : "-"})`}
                </div>
                <div className="table-text d-block text-capitalize font-weight-bold py-3">
                  {orderDetails && orderDetails?.branch?.name}
                </div>
                </div>
                <div
                  className="fk-settle__products d-flex flex-column"
                  data-simplebar
                >
                  <div className="container-fluid">
                    <div className="row gx-3">
                      <div className="col-12">
                        {/* POS Product list will be here  */}
                        <div className="fk-price-table__body t-mt-10">
                          <div className="fk-price-table__body-top">
                            <div className="fk-table">
                            <div className="d-flex justify-content-between px-3 fk-addons-table__head">
                            <div className="mr-3">
                            {_t(t("order token"))}: #
                            {orderDetails?.token &&
                              orderDetails.token?.id}
                             </div>
                             <div>
                              {
                                `${selectedDeliveryType && selectedDeliveryType?.name} - ${selectedParty && selectedParty?.name}`
                              }
                             </div>
                             </div>
                              <div className="fk-table__head">
                                <div className="row g-0 border">
                                  <div className="col-1 text-center border-right">
                                    <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                      {"S/L"}
                                    </span>
                                  </div>
                                  <div className="col-6 text-center border-right">
                                    <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                      {"food item"}
                                    </span>
                                  </div>
                                  <div className="col-2 text-center border-right">
                                    <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                      {"QTY"}
                                    </span>
                                  </div>
                                  <div className="col-3 text-center">
                                    <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                      {"price"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="t-pb-30">
                                <div className="sky-is-blue reverse-this">
                                  {/* loop through order list items */}
                                  {cartItems &&
                                  Object.keys(cartItems)?.length > 0 ? (
                                    Object.values(cartItems).map(
                                      (orderListItem, orderListItemIndex) => {
                                        console.log(
                                          "21 order",
                                          orderListItem,
                                          orderListItemIndex
                                        );
                                        return (
                                          <>
                                            <div
                                              className={`fk-table-container-order ${
                                                orderListItemIndex ===
                                                  activeItemInOrder && "active"
                                              } `}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                //orderListItem's group wise all items
                                                // let tempItems =
                                                //   foodForPOS &&
                                                //   foodForPOS.filter(
                                                //     (tempItem) => {
                                                //       return (
                                                //         tempItem.food_group_id ===
                                                //         orderListItem.item
                                                //           .food_group_id
                                                //       );
                                                //     }
                                                //   );

                                                //orderListItem's group
                                                // let foodGroup =
                                                //   showFilterFoodGroupsByDept &&
                                                //   showFilterFoodGroupsByDept.find(
                                                //     (groupItem) => {
                                                //       return (
                                                //         groupItem.id ===
                                                //         parseInt(
                                                //           orderListItem.item
                                                //             .food_group_id
                                                //         )
                                                //       );
                                                //     }
                                                //   );

                                                // // selected pos item
                                                // let selectedItemTemp =
                                                //   tempItems &&
                                                //   tempItems.find(
                                                //     (tempSelectedItem) => {
                                                //       return (
                                                //         tempSelectedItem.id ===
                                                //         orderListItem.item.id
                                                //       );
                                                //     }
                                                //   );

                                                // // Set variations, properties, selected item
                                                // setFoodItem({
                                                //   ...foodItem,
                                                //   foodGroup: foodGroup,
                                                //   items: tempItems,
                                                //   selectedItem:
                                                //     selectedItemTemp,
                                                //   variations:
                                                //     selectedItemTemp &&
                                                //     parseInt(
                                                //       selectedItemTemp.has_variation
                                                //     ) === 1
                                                //       ? selectedItemTemp.variations
                                                //       : null,

                                                //   properties:
                                                //     selectedItemTemp &&
                                                //     parseInt(
                                                //       selectedItemTemp.has_property
                                                //     ) === 1
                                                //       ? selectedItemTemp.properties
                                                //       : null,
                                                // });

                                                //set active order list index for background color of selected
                                                setActiveItemInOrder(
                                                  orderListItemIndex
                                                );
                                              }}
                                            >
                                              <div
                                                className={`row g-0 border-top-0 border-bottom `}
                                              >
                                                <div className="col-1 text-center border-left d-flex justify-content-center align-items-center">
                                                  {newOrder.length -
                                                    orderListItemIndex}
                                                </div>
                                                <div
                                                  className={`col-6 border-left border-right py-2`}
                                                >
                                                  <div className="d-flex justify-content-between">
                                                    <span className="text-capitalize d-block t-pt-5 t-pb-5 t-pl-5 t-pr-5 sm-text font-weight-bold t-mr-8">
                                                      {orderListItem.item.name}
                                                    </span>
                                                  </div>
                                                  <div className="row g-0">
                                                    {/* if item has variations show the selected in order list */}
                                                    {parseInt(
                                                      orderListItem.item
                                                        .has_variation
                                                    ) === 1 && (
                                                      <div className="col-12">
                                                        <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pr-5 t-pl-5">
                                                        variation :
                                                        </span>
                                                        <span className="text-capitalize xsm-text d-inline-block badge rounded-pill bg-warning text-dark font-weight-md">
                                                        {orderListItem.variation &&
                                                          orderListItem
                                                            .variation
                                                            .variation_name
                                                            ? orderListItem
                                                            .variation
                                                            .variation_name
                                                            : "-"}
                                                        </span>
                                                      </div>
                                                    )}
                                                    {/* if item has properties show the selected in order list, loop here  */ }
                                                    {orderListItem.properties &&
                                                      Object.values(orderListItem.properties)
                                                        .length > 0 &&
                                                      orderListItem.properties !==
                                                        undefined &&
                                                      orderListItem.item.property_groups
                                                      ?.map((thisIsGroup) => {
                                                        
                                                        let theGroup =
                                                        propertyGroupForSearch &&
                                                        propertyGroupForSearch.find(
                                                          (theItem) => {
                                                            return thisIsGroup === (theItem?.id) ;
                                                            
                                                          }
                                                          );
                                                          
                                                          let propertWithGroup = Object.keys(orderListItem.properties).includes(thisIsGroup.toString()) && Object.values(orderListItem.properties[thisIsGroup]);
                                                          //let IsGroup = Object.keys(orderListItem.properties);; 
                                                          // let abc = propertWithGroup && propertWithGroup?.forEach((property)=>{
                                                          //   const propertyGroup =  parseInt(bracketReplace(
                                                          //     property
                                                          //         .item
                                                          //         .property_group_id
                                                          //         )
                                                          //         )
                                                          //   if(!(IsGroup.includes(propertyGroup))){
                                                          //     IsGroup.push(propertyGroup)
                                                              
                                                          //   }
                                                          // })

                                                        return (
                                                          <div className="col-12">
                                                          {
                                                            //IsGroup && IsGroup?.length > 0 && IsGroup.includes(theGroup?.id.toString()) ?
                                                            propertWithGroup && theGroup &&
                                                            <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pr-5 t-pl-5">
                                                              {theGroup &&
                                                                theGroup.name + " :"}
                                                               
                                                              </span>
                                                              // :
                                                              // ""
                                                          }
                                                            {propertWithGroup && propertWithGroup?.map(
                                                              (
                                                                propertyName
                                                              ) => {
                                                                console.log(
                                                                  "15 here",
                                                                  propertyName
                                                                );
                                                                
                                                                if (
                                                                  orderListItem.properties &&
                                                                  parseInt(
                                                                    bracketReplace(
                                                                      propertyName
                                                                        .item
                                                                        .property_group_id
                                                                    )
                                                                  ) ===
                                                                    theGroup?.id
                                                                ) {
                                                                  return (
                                                                    <span className="text-capitalize xsm-text d-inline-block badge rounded-pill bg-warning text-dark font-weight-md mr-1">
                                                                      {
                                                                        propertyName
                                                                          .item
                                                                          .name
                                                                      }{" "}
                                                                      <span>
                                                                        {" "}
                                                                        {propertyName.quantity >
                                                                          1 &&
                                                                          "(" +
                                                                            propertyName.quantity +
                                                                            ")"}
                                                                      </span>
                                                                    </span>
                                                                  );
                                                                }
                                                                else {
                                                                  return true;
                                                                }
                                                              }
                                                            )}
                                                          </div>
                                                        );
                                                      })}

                                                    {/* if item has properties show the selected in order list  */}
                                                  </div>
                                                </div>
                                                {/* Quantity */}
                                                <div className="col-2 text-center border-right d-flex justify-content-center align-items-center">
                                                  <div className="fk-qty t-pt-5 t-pb-5 justify-content-center">
                                                    <input
                                                      type="text"
                                                      value={
                                                        orderListItem.quantity
                                                      }
                                                      className="fk-qty__input t-bg-clear"
                                                      readOnly
                                                    />
                                                  </div>
                                                </div>
                                                {/* Quantity */}

                                                {/* Price */}
                                                <div className="col-3 text-center border-right d-flex justify-content-center align-items-center">
                                                  <div className="text-capitalize sm-text font-weight-bold t-pt-5 t-pb-5">
                                                    {console.log(orderListItem)}
                                                    {parseInt(
                                                      orderListItem.item
                                                        .has_variation
                                                    ) === 1 ? (
                                                      <>
                                                        {currencySymbolLeft()}

                                                        {showPriceOfEachOrderItem(
                                                          orderListItem
                                                        )}
                                                        {currencySymbolRight()}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {currencySymbolLeft()}
                                                        {showPriceOfEachOrderItem(
                                                          orderListItem
                                                        )}
                                                        {currencySymbolRight()}
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    )
                                  ) : (
                                    <div className="text-primary text-center font-weight-bold pt-5 xsm-text text-uppercase">
                                      {"Select food item to add to the list"}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="row gx-3">
                  <div className="col-md-9">
                    {returnMoneyUsd > 0 ? (
                      <div
                        className="alert alert-danger text-center"
                        style={{ height: "56px", marginTop: "16px" }}
                      >
                        Return Amount: {formatPrice(returnMoneyUsd)}
                      </div>
                    ) : (
                      <>
                        {
                          parseInt(getSystemSettings(generalSettings, "loyality_enable")) === 1 && (
                            <>
                              <div className="fk-settle-group t-mt-10 row">
                                <div className="col-6">
                                  <div className="table-text font-weight-bold cursor-pointer text-center d-flex">
                                    <div title="redeem points by code" className={`toggle-option p-2 table-text rounded-left ${loyaltyPoint.type === "token" ? "active" : ""}`} onClick={()=>handleToggle("token")}>Redeem</div>
                                    <div title="new registration with phone" className={`toggle-option p-2 table-text rounded-right ${loyaltyPoint.type === "phone" ? "active" : ""}`} onClick={()=>handleToggle("phone")}>loyalty Reg.</div>
                                    <img title="loyalty points" style={{margin:"auto 0.3rem auto 0.8rem", height:"2.25rem"}} src={LoyaltyImg} alt="loyalty points"/>
                                  </div>
                                </div> 
                                <div className="col-6 pl-0 d-flex">
                                  <input
                                    disabled={orderDetails.payment_type && orderDetails.payment_type?.length}
                                    type="text"
                                    onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                                    onWheel={(e) => e.target.blur()}
                                    value={loyaltyPoint.qr_code}  
                                    id="settle-total"
                                    style={{minHeight:"1rem",borderRadius:"0",color:"#121053"}}
                                    className="form-control w-50 fk-settle-group__input px-2 mr-2 font-weight-bold p-1 rounded-sm"
                                    placeholder={loyaltyPoint.type === "phone" ? "Phone here..." : "Code here..."}
                                    onChange={(e) => handleChange(e)}
                                  />
                                  <input
                                    disabled
                                    type="number"
                                    onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                                    onWheel={(e) => e.target.blur()}  
                                    id="settle-total"
                                    style={{minHeight:"1rem",borderRadius:"0",color:"#121053",width:"48.5%"}}
                                    className="form-control fk-settle-group__input text-right px-2 font-weight-bold p-1 rounded-sm"
                                    placeholder="Your points"
                                    value={loyaltyPoint.availablePoints}
                                  />
                                </div>
                              </div>
                              <div className="fk-settle-group d-flex align-items-center align-items-center t-mt-10">
                                <label
                                  htmlFor="settle-paid-amount"
                                  className="text-capitalize w-50 fk-settle-group__label font-weight-bold d-flex align-items-center justify-content-between"
                                >
                                  redeem amount:
                                  <button title="clear to unlock redeem" className="mx-3 px-2 btn btn-sm btn-danger rounded" onClick={()=>handleClearAll()}>Unlock</button>
                                </label>
                                <input
                                  disabled={orderDetails.payment_type && orderDetails.payment_type?.length}
                                  type="number"
                                  onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                                  onWheel={(e) => e.target.blur()}  
                                  id="settle-total"
                                  style={{minHeight:"1rem",borderRadius:"0",color:"#121053"}}
                                  className="form-control w-50 fk-settle-group__input text-right px-2 font-weight-bold p-1 rounded-sm"
                                  max={loyaltyPoint.availablePoints}
                                  value={loyaltyPoint.redeemPoints || ""}
                                  onChange={(e) => handleRedeemPoint(e)}
                                />
                              </div>
                            </>  
                          )
                        }
                        <div className="fk-settle-group d-flex align-items-center t-mt-10">
                          <label
                            htmlFor="settle-total"
                            className="text-capitalize w-50 fk-settle-group__label font-weight-bold"
                          >
                            total:
                          </label>
                          <div
                            id="settle-total"
                            className="w-50 fk-settle-group__input text-right px-2 font-weight-bold p-1 rounded-sm"
                          >
                            {(totalsettle)}
                          </div>
                        </div>
                        <div className="fk-settle-group d-flex align-items-center t-mt-10 t-mb-15">
                          <label
                            htmlFor="settle-paid-amount"
                            className="text-capitalize w-50 fk-settle-group__label font-weight-bold"
                          >
                            paid amount:
                          </label>
                          {/* {paySelected ? (
                              <div
                                id="settle-total"
                                className="w-50 fk-settle-group__input text-right pr-2 font-weight-bold"
                              >
                                {paidMoney}
                              </div>
                            ) : (
                              )
                            } */}
                            <input
                              ref={inputRefs.paid}
                              type="number"
                              onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                              onWheel={(e) => e.target.blur()}  
                              id="settle-total"
                              style={{minHeight:"1rem",borderRadius:"0",color:"#121053"}}
                              className="form-control w-50 fk-settle-group__input text-right px-2 font-weight-bold p-1 rounded-sm"
                              autoFocus
                              value={paidMoney > 0 ? paidMoney : ""}
                              onChange={(e) => setPaidMoney(e.target.value ? parseFloat(e.target.value) : 0)}
                            />
                        </div>
                      </>
                    )}
                    <div className={`${parseInt(getSystemSettings(generalSettings, "loyality_enable")) === 1 ? "fk-settle-cal-loyality" : "fk-settle-cal"} container-fluid`}>
                      <div className="row h-100 g-2 mt-1">
                        <div className="col-2">
                          <div className="row g-2 h-100">
                            <div className="col-12">
                              <button
                                className="fk-settle-cal-btn t-bg-p t-text-white"
                                onClick={() => {
                                  if (!(returnMoneyUsd > 0)) {
                                    setPaidMoney(Number(paidMoney) + 10);
                                  }
                                }}
                              >
                                10
                              </button>
                            </div>
                            <div className="col-12">
                              <button
                                className="fk-settle-cal-btn t-bg-p t-text-white"
                                onClick={() => {
                                  if(!(returnMoneyUsd > 0)) {
                                    setPaidMoney(Number(paidMoney) + 20);                                  }
                                }}
                              >
                                20
                              </button>
                            </div>
                            <div className="col-12">
                              <button
                                className="fk-settle-cal-btn t-bg-p t-text-white"
                                onClick={() => {
                                  if(!(returnMoneyUsd > 0)) {
                                    setPaidMoney(Number(paidMoney) + 50);                                  }
                                }}
                              >
                                50
                              </button>
                            </div>
                            <div className="col-12 mb-2">
                              <button
                                className="fk-settle-cal-btn t-bg-p t-text-white"
                                onClick={() => {
                                  if(!(returnMoneyUsd > 0)) {
                                    setPaidMoney(Number(paidMoney) + 100);                                  }
                                }}
                              >
                                100
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-10">
                          <div className="row g-2 h-100">
                            <div className="col-3">
                              <div className="row g-2 h-100">
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if(!(returnMoneyUsd > 0)) {
                                        setPaidMoney(paidMoney > 0 ? (String(paidMoney) + 1) : 1);
                                      }
                                    }}
                                  >
                                    1
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if(!(returnMoneyUsd > 0)) {
                                        setPaidMoney(paidMoney > 0 ? (String(paidMoney) + 4) : 4);
                                      }
                                    }}
                                  >
                                    4
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if(!(returnMoneyUsd > 0)) {
                                        setPaidMoney(paidMoney > 0 ? (String(paidMoney) + 7) : 7);
                                      }
                                    }}
                                  >
                                    7
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-p t-text-white"
                                    onClick={() => {
                                      if(!(returnMoneyUsd > 0)) {
                                        setPaidMoney(Number(paidMoney) + 500);                                      }
                                    }}
                                  >
                                    500
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="row g-2 h-100">
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if(!(returnMoneyUsd > 0) ) {
                                        setPaidMoney(paidMoney > 0 ? (String(paidMoney) + 2) : 2);
                                      }
                                    }}
                                  >
                                    2
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if(!(returnMoneyUsd > 0) ) {
                                        setPaidMoney(paidMoney > 0 ? (String(paidMoney) + 5) : 5);
                                      }
                                    }}
                                  >
                                    5
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!(returnMoneyUsd > 0)) {
                                        setPaidMoney(paidMoney > 0 ? (String(paidMoney) + 8) : 8);
                                      }
                                    }}
                                  >
                                    8
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-p t-text-white"
                                    onClick={() => {
                                      if (!(returnMoneyUsd > 0)) {
                                        setPaidMoney(Number(paidMoney) + 1000);
                                      }
                                    }}
                                  >
                                    1000
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="row g-2 h-100">
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if(!(returnMoneyUsd > 0)) {
                                        setPaidMoney(paidMoney > 0 ? (String(paidMoney) + 3) : 3);
                                      }
                                    }}
                                  >
                                    3
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if(!(returnMoneyUsd > 0)) {
                                        setPaidMoney(paidMoney > 0 ? (String(paidMoney) + 6) : 6);
                                      }
                                    }}
                                  >
                                    6
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-w"
                                    onClick={() => {
                                      if (!(returnMoneyUsd > 0)) {
                                        setPaidMoney(paidMoney > 0 ? (String(paidMoney) + 9) : 9);
                                      }
                                    }}
                                  >
                                    9
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-p t-text-white"
                                    onClick={() => {
                                      if (!(returnMoneyUsd > 0)) {
                                        setPaidMoney(Number(paidMoney) + 5000);
                                      }
                                    }}
                                  >
                                    5000
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="row g-2 h-100">
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-text-white t-bg-ac text-capitalize"
                                    onClick={() => {
                                      let theP = parseFloat(totalsettle);
                                      setPaidMoney(theP);
                                    }}
                                  >
                                    all
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-text-white t-bg-ac text-capitalize"
                                    onClick={() => {
                                      if (!(returnMoneyUsd > 0)) {
                                        // let theP = formatPrice(
                                        //   parseFloat(totalsettle) / 2
                                        // );
                                        // setPaidMoney(parseFloat(theP));
                                        setPaidMoney(paidMoney > 0 && (String(paidMoney) + 0));
                                      }
                                    }}
                                  >
                                    0
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    className="fk-settle-cal-btn t-bg-d t-text-white"
                                    onClick={() => {handleClearAmount()}}
                                  >
                                    C
                                  </button>
                                </div>
                                <div className="col-12">
                                  <button
                                    disabled={buttonDisabled}
                                    className="fk-settle-cal-btn bg-primary t-text-white t-bg-r text-capitalize hover-effect"
                                    onClick={!loading && handleSettleOrder}
                                  >
                                    {!loading ? "settle" : "wait"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex flex-column justify-content-center t-mt-15">
                      <div className="fk-settle__pay" data-simplebar>
                        <div className="row gx-3">
                          <div className="col-12">
                            {/* POS Navigation will ber here */}
                            <ul className="t-list fk-pos-nav list-group">
                              <li className="fk-pos-nav__list">
                                <div className="w-100 mb-3 d-flex align-items-center">
                                  <label className="mr-2">Tip:</label>
                                  <input
                                   type="number"
                                   onKeyDown={(evt) => ["e", "E", "+", "-","ArrowUp","ArrowDown"].includes(evt.key) && evt.preventDefault()}
                                   onWheel={(e) => e.target.blur()}  
                                   className="form-control fk-settle-group__input py-1 px-2 font-weight-bold"
                                   autoFocus
                                   value={orderDetails.order_tip}
                                   onChange={(e) => setOrderDetails({...orderDetails,order_tip: e.target.value})}
                                  />
                                </div>
                                <button
                                  type="button"
                                  onClick={() => handleGoBack()}
                                  className="w-100 t-text-dark t-heading-font btn alert alert-danger font-weight-bold text-uppercase py-3 mb-3"
                                >
                                  Go back
                                </button>
                              </li>
                              {/* paymentTypes */}
                              {paymentTypeAvailable &&
                                paymentTypeAvailable.map(
                                  (groupItem, groupIndex) => {
                                    return (
                                      <li
                                        className="fk-pos-nav__list"
                                        key={groupIndex}
                                      >
                                        <button
                                          type="button"
                                          //set active or !
                                          className={`w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase py-3 hover-effect ${
                                            orderDetails &&
                                            orderDetails.payment_type !==null && 
                                            orderDetails.payment_type[0]?.id === groupItem.id
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            handleSetpaymentTypeSingle(
                                              groupItem
                                            );
                                          }}
                                        >
                                          {groupItem.name}
                                        </button>
                                      </li>
                                    );
                                  }
                                )}
                                {/*split paymentTypes */}
                                {paymentTypeAvailable &&
                                 paymentTypeAvailable?.length > 1 && (
                                  <li className="mt-2" title="Clear Paid Amount for Split Payment">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setPayModal(true);
                                        handleClearAll()
                                      }}
                                      className="w-100 t-text-dark t-heading-font btn alert alert-danger font-weight-bold text-uppercase py-3 mb-3"
                                    >
                                      Split Payment
                                    </button>
                                  </li>
                                )}
                             
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/*split pay modal */}
      <SplitPayment
        handleClick={handleClick}
        totalsettle={totalsettle}
        payModal={payModal}
        handleSetPayment={handleSetPayment}
        paySelected={paySelected}
        paymentTypeAvailable={paymentTypeAvailable}
        payment={payment}
        handleSetpaymentTypeMuliple={handleSetpaymentTypeMuliple}
        handleSplitPayment={handleSplitPayment} 
      />
     
       {/* Card Modal */}
       <CardModal
        cardAdd={cardAdd}
        handleCardSubmit={handleCardSubmit}
        inputRefs={inputRefs}
        cardNumber={cardNumber}
        handleCardChange={handleCardChange}
      />

    </>
  );
};

export default SettleOrder;
