import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory, NavLink } from "react-router-dom";

//pages & includes
import ManageSidebar from "../ManageSidebar";
import moment from "moment";
//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  pagination,
  paginationLoading,
  showingData,
  searchedShowingData,
  formatPrice,
  currencySymbolLeft,
  currencySymbolRight,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//3rd party packages
import { Helmet } from "react-helmet";
import ViewAccountSheet from "../../accounts/transactions/ViewAccountSheet";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
import { UserContext } from "../../../../../contexts/User";
import { RestaurantContext } from "../../../../../contexts/Restaurant";
import DatePicker from "react-datepicker";
import { useReactToPrint } from "react-to-print";

const toastObj = {
  position: "bottom-center",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  className: "text-center toast-notification",
}

const StockAdjustment = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);

  const {
    branchForSearch,
    ingredientItemForSearch,
    ingredientGroupForSearch,
    getIngredientItem,
    getIngredientGroup,
    ingredientItemStock,
  } = useContext(RestaurantContext)

  let {
    //stock adjustment
    authUserInfo,
    stockAdjustmentList,
    setStockAdjustmentList,
    setPaginatedStockAdjustment,
    stockAdjustmentForSearch,
    setStockAdjustmentForSearch,
    // units
    units,
    //pagination
    dataPaginating,
  } = useContext(UserContext);

  // States hook here
  //new customer
  let [newStockAdjustment, setNewStockAdjustment] = useState({
    adjust_no: "",
    adjust_date: null,
    branch: null,
    remarks: '',
    ingredients: {},
    edit: false,
    uploading: false,
  });

  const [date, setDate] = useState(null);

  //search result
  let [searchedStockAdjustment, setSearchedStockAdjustment] = useState({
    list: null,
    searched: false,
  });

  const [selectedIngredient, setSelectedIngredient] = useState(null)
  const [selectedIngredientGroup, setSelectedIngredientGroup] = useState(null)
  const [ingredientItemToShow, setIngredientItemToShow] = useState(null)
  const [showWithZero, setShowWithZero] = useState(0)
  const [branchNamesObj, setBranchNamesObj] = useState({})
  const [unitNamesObj, setUnitNamesObj] = useState({})
  const [ingredientLoading, setIngredientLoading] = useState(false);
  const [ledgerData, setLedgerData] = useState({
    modal: false,
    ledger: null
  });
  const stockPrint = useRef();

  //useEffect == componentDidMount
  useEffect(() => {
    getIngredientItem()
    getIngredientGroup()
  }, []);

  useEffect(() => {
    setIngredientItemToShow(ingredientItemForSearch)
  }, [ingredientItemForSearch])

  useEffect(() => {
    if (branchForSearch && branchForSearch.length) {
      const obj = {}
      branchForSearch.forEach(branch => {
        obj[branch.id] = branch
      })
      setBranchNamesObj(obj)
    }
  }, [branchForSearch])

  useEffect(() => {
    if (units && units.length) {
      const obj = {}
      units.forEach(unit => {
        obj[unit.id] = unit.code
      })
      setUnitNamesObj(obj)
    }
  }, [units])

  //set name, phn no hook
  const handleSetNewStockAdjustment = (e) => {
    setNewStockAdjustment({ ...newStockAdjustment, [e.target.name]: e.target.value });
  };

  const handleSetBranch = (branch) => {
    setNewStockAdjustment({ ...newStockAdjustment, branch: branch, ingredients: {} });
  }

  const handleDeleteIngredient = (id) => {
    const newObj = {...newStockAdjustment.ingredients}
    delete newObj[id]
    setNewStockAdjustment(curr => ({
      ...curr,
      ingredients: newObj
    }))
  }

  const handleSetIngredient = (ingredient) => {
    setSelectedIngredient(ingredient)
  }
  const handleSetIngredientGroup = (ingredientGroup) => {
    setSelectedIngredientGroup(ingredientGroup);
    setIngredientItemToShow(
      ingredientItemForSearch.filter(
        (ingredient) => (ingredient.ingredient_group_id == ingredientGroup.id)
      )
    );
  }

  const handleSetIngredientQty = (e, ingredientId) => {
    const newQty = e.target.value
    const newParsedQty = (
      e.target.value
      ? parseInt(e.target.value)
      : 0
    );
    setNewStockAdjustment(curr => ({
      ...curr,
      ingredients: {
        ...curr.ingredients,
        [ingredientId]: {
          ...curr.ingredients[ingredientId],
          qty: newQty,
          parsed_qty: newParsedQty,
          adjust_qty: newParsedQty - (curr.ingredients[ingredientId].stock_qty),
          item_amount: (
            curr.ingredients[ingredientId].avg_rate *
            (newParsedQty - curr.ingredients[ingredientId].stock_qty)
          ),
        }
      },
    }))
  }

  const getIngredientInfo = () => {
    setIngredientLoading(true)
    const formData = new FormData()
    formData.append('branch_id', newStockAdjustment.branch.id);
    formData.append('having', showWithZero)
    if (selectedIngredient) {
      formData.append('item_id', selectedIngredient.id)
    }
    if (selectedIngredientGroup) {
      formData.append('group_id', selectedIngredientGroup.id)
    }
    const url = BASE_URL + '/settings/get-stock-adjustment';
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` }
      })
      .then(res => {
        if (res.data && res.data.length) {
          const newIngredients = {};
          for (const ingredient of res.data) {
            newIngredients[ingredient.item_id] = {
              id: ingredient.item_id,
              item_code: ingredient.item_code,
              name: ingredient.item_name,
              avg_rate: parseFloat(ingredient.avg_rate),
              stock_qty: parseFloat(ingredient.stock_qty),
              unit_id: ingredient.unit_id,
              unit: unitNamesObj[ingredient.unit_id],
              qty: ingredient.stock_qty,
              parsed_qty: parseFloat(ingredient.stock_qty),
              adjust_qty: 0,
              item_amount: parseFloat(ingredient.avg_rate) * parseInt(ingredient.stock_qty),
            }
          }
          setNewStockAdjustment(curr => ({
            ...curr,
            ingredients: {
              ...newIngredients
            },
          }))
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setIngredientLoading(false)
      });
  }

  const handleClearStates = (e) => {
    setNewStockAdjustment({
      adjust_no: "",
      adjust_date: null,
      branch: null,
      remarks: '',
      ingredients: {},
      edit: false,
      uploading: false,
    })
    setDate(null)
    setIngredientItemToShow(ingredientItemForSearch)
    setSelectedIngredient(null)
    setSelectedIngredientGroup(null)
    setShowWithZero(0)
  }

  const validateAndGetFormData = (edit) => {
    const formData = {};
    if (!newStockAdjustment.adjust_date) {
      toast.error('Please select a date', toastObj);
      return false;
    } else {
      let dateObj = newStockAdjustment.adjust_date
      let date =
        `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)}-${dateObj.getDate()}`;
      formData.adjust_date = date
    }
    if (!newStockAdjustment.branch || !newStockAdjustment.branch.id) {
      toast.error('Please select a branch', toastObj);
      return false;
    } else {
      formData.branch_id = newStockAdjustment.branch.id
    }
    if (!newStockAdjustment.ingredients || !Object.keys(newStockAdjustment.ingredients).length) {
      toast.error('Please select ingredients', toastObj);
      return false;
    } else {
      const stock_adjustment_details = [];
      for (const ingredientId in newStockAdjustment.ingredients) {
        const ingredient = newStockAdjustment.ingredients[ingredientId]
        if (!ingredient.qty || !ingredient.parsed_qty) {
          toast.error(`Please enter the qty for ${ingredient.name}.`, toastObj);
          return false;
        } else {
          stock_adjustment_details.push({
            item_id: ingredient.id,
            item_name: ingredient.name,
            item_code: ingredient.item_code,
            unit_id: ingredient.unit_id,
            qty: ingredient.parsed_qty,
            price: ingredient.avg_rate,
            actual_stock_qty: ingredient.stock_qty,
            adjust_qty: ingredient.adjust_qty,
            item_amount: ingredient.item_amount,
          })
        }
      }
      formData.stock_adjustment_details = stock_adjustment_details
    }
    if (edit) formData.adjust_no = newStockAdjustment.adjust_no
    formData.remarks = newStockAdjustment.remarks
    return formData;
  }

  const onSuccessSave = (res) => {
    handleClearStates()
    setStockAdjustmentList(res.data[0]);
    setStockAdjustmentForSearch(res.data[1]);
    setLoading(false);
    toast.success(`${_t(t("Stock adjustment has been added."))}`, toastObj);
  }

  const onFailedSave = (error) => {
    setLoading(false);
    setNewStockAdjustment({
      ...newStockAdjustment,
      uploading: false,
    });
    toast.error(
      `${_t(t("Please try again."))}`,
      toastObj
    );
  }

  //Save New customer
  const handleSaveNewCustomer = (e, edit) => {
    e.preventDefault();
    const formData = validateAndGetFormData(edit)
    if (!formData)
      return;
    const url = BASE_URL + `/settings/stockAdjustment`;
    console.log('ar-log formData', formData)
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then(onSuccessSave)
      .catch(onFailedSave);
  };

  const handlePrint = useReactToPrint({
    content: () => stockPrint.current,
  });

  //set edit true & values
  const handleSetView = (id) => {
    const url = BASE_URL + `/settings/stockAdjustment/${id}`
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` }
      })
      .then(res => {
        console.log(res.data)
        if (res.data) {
          const dateToSet = new Date(res.data.stockAdjustment.adjust_date)
          const newStockObj = {
            id: res.data.stockAdjustment.id,
            adjust_no: res.data.stockAdjustment.adjust_no,
            adjust_date: dateToSet,
            remarks: res.data.stockAdjustment.remarks,
            edit: true,
            uploading: false,
          }
          newStockObj.branch = branchNamesObj[res.data.stockAdjustment.branch_id];
          const ingredientObj = {}
          res.data.stockAdjustmentDetails.forEach(ingredient => {
            console.log('ar-log',unitNamesObj, ingredient.unit_id, unitNamesObj[ingredient.unit_id])
            ingredientObj[ingredient.item_id] = {
              id: ingredient.item_id,
              item_code: ingredient.item_code,
              avg_rate: ingredient.avg_rate,
              qty: ingredient.qty,
              name: ingredient.item_name,
              stock_qty: ingredient.actual_stock_qty,
              unit: unitNamesObj[ingredient.unit_id],
              unit_id: ingredient.unit_id,
              adjust_qty: ingredient.adjust_qty,
              item_amount: ingredient.item_amount,
            }
          })
          newStockObj.ingredients = ingredientObj
          setNewStockAdjustment(newStockObj)
          setDate(dateToSet);
          handlePrint();
        }
      })
      .catch(error => {
      })
  };
  console.log("abcd45",newStockAdjustment)
  //search customers here
  const handleSearch = (e) => {
    let searchInput = e.target.value.toLowerCase();
    if (searchInput.length === 0) {
      setSearchedStockAdjustment({ ...searchedStockAdjustment, searched: false });
    } else {
      let searchedList = stockAdjustmentForSearch.filter((item) => {
        let lowerCaseAdjustmentNo = item.adjust_no.toLowerCase();
        let lowerCaseAdjustmentDate = item.adjust_date.toLowerCase();
        let lowerCaseBranch = (branchNamesObj[item.branch_id])?.name?.toLowerCase();

        return (
          lowerCaseAdjustmentNo.includes(searchInput) ||
          lowerCaseAdjustmentDate.includes(searchInput) ||
          lowerCaseBranch.includes(searchInput)
        );
      });
      setSearchedStockAdjustment({
        ...searchedStockAdjustment,
        list: searchedList,
        searched: true,
      });
    }
  };

  const handleStockLedger = async(id) =>{
    const url = BASE_URL + "/settings/check-financial-ledger-view/" + parseInt(id) + "/ADJ";
    try{
      const res = await axios.get(url, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      });
      if(res.data){
        setLedgerData({ modal: true, ledger: res.data});
      }
    }catch(err){
      console.log("error==>",err)
    }
  }

  return (
    <>
      <Helmet>
        <title>{_t(t("Stock adjustment"))}</title>
      </Helmet>

      {/*stock print */}
      <div className="d-none">
        <div ref={stockPrint}>
          {newStockAdjustment && newStockAdjustment?.branch && newStockAdjustment?.ingredients && (
            <div className="fk-print">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="mx-3">
                      <div className="my-2 mt-3 xlg-print-text-stock print-text-stock text-center text-capitalize print-text-stock--bold">
                        Stock Adjustment
                      </div>

                      <div className="mt-4 my-2 print-text-stock row text-capitalize">
                        <div className="col-6 print-text-stock--bold">Adjust Date: {moment(newStockAdjustment?.adjust_date).format("DD-MM-YYYY")}</div>
                        <div className="col-6 print-text-stock--bold">Branch : {newStockAdjustment?.branch && newStockAdjustment.branch?.name}</div>
                      </div>

                      <div className="mt-4 my-2 print-text-stock row text-capitalize">
                        <div className="col-6 print-text-stock--bold">Show Stock: {showWithZero ? "Without Zero": "With Zero"}</div>
                        <div className="col-6 print-text-stock--bold">Remarks : {newStockAdjustment.remarks}</div>
                      </div>
                    </div>  

                    <div className=" mt-3">
                      <table className="table table-bordered-sm table-hover ">
                        <thead className="align-middle">
                          <tr>
                            <th
                              scope="col"
                              className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border table_child_branch"
                            >
                              {_t(t("Code"))}
                            </th>
                            <th
                              scope="col"
                              className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border table_child"
                            >
                              {_t(t("Name"))}
                            </th>

                            <th
                              scope="col"
                              className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                            >
                              {_t(t("Unit"))}
                            </th>

                            <th
                              scope="col"
                              className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                            >
                              {_t(t("Actual Stock Qty"))}
                            </th> 

                            <th
                              scope="col"
                              className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                            >
                              {_t(t("Adjust Qty"))}
                            </th> 

                            <th
                              scope="col"
                              className="print-text-stock text-capitalize align-middle text-center border-dark border-1 border"
                            >
                              {_t(t("Qty"))}
                            </th> 
                          </tr>
                        </thead>

                        <tbody className="align-middle">
                          {Object.values(newStockAdjustment?.ingredients)?.map((ingredientItem, index) => {
                            return (
                              <tr className="align-middle" key={index}>
                                <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                  {ingredientItem.item_code}
                                </td>

                                <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                  {ingredientItem.name}
                                </td>

                                <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">
                                  {ingredientItem.unit}
                                </td>

                                <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">                                     
                                  {ingredientItem?.stock_qty}
                                </td>

                                <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">                                     
                                  {ingredientItem?.adjust_qty}
                                </td>

                                <td className="print-text-stock text-capitalize border-dark border-1 align-middle text-center">                                     
                                  {ingredientItem?.qty}
                                </td>
                                
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Add modal */}
      <div className="modal fade add-adjustment-modal" id="addAdjustment" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content" style={{maxHeight: 'calc(100vh - 1.75rem - 1.75rem)'}}>
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {!newStockAdjustment.edit
                    ? _t(t("Add new Stock Adjustment"))
                    : _t(t("View Stock Adjustment"))}
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClearStates}
              ></button>
            </div>
            <div className="modal-body" data-simplebar>
              
              {newStockAdjustment.uploading === false ? (
                <div key="fragment-customer-1">
                  <form
                    onSubmit={
                      (e) =>
                        handleSaveNewCustomer(e, newStockAdjustment.edit)
                    }
                    autoComplete="off"
                  >
                    <div className="row m-0">
                      <div className="col-6">
                        <label htmlFor="adjust_no" className="form-label">
                          {_t(t("Adjustment Number"))}{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="adjust_no"
                          name="adjust_no"
                          placeholder="Adjustment Number will be automatically set"
                          value={newStockAdjustment.adjust_no || ""}
                          disabled
                          onChange={handleSetNewStockAdjustment}
                        />
                      </div>

                      <div className="col-6">
                        <label
                          htmlFor="adjust_date"
                          className="form-label"
                        >
                          {_t(t("Adjustment Date"))}
                          <span className="text-danger">*</span>
                        </label>
                        <DatePicker
                          selected={date}
                          onChange={(date) => {
                            setDate(date)
                            setNewStockAdjustment({
                              ...newStockAdjustment,
                              adjust_date: date
                            })
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          className="form-control"
                          placeholderText={_t(t("Adjustment Date"))}
                          disabled={newStockAdjustment.edit}
                        />
                      </div>
                    </div>

                    <div className="row mt-3 m-0">
                      <div className="col-4">
                        <label htmlFor="branch" className="form-label">
                        {_t(t("Branch"))}
                        <span className="text-danger">*</span>
                        </label>
                        <Select
                          options={branchForSearch}
                          value={newStockAdjustment.branch || null}
                          components={makeAnimated()}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          classNamePrefix="select"
                          onChange={handleSetBranch}
                          maxMenuHeight="200px"
                          placeholder={
                            _t(t("Please select a branch")) + ".."
                          }
                          isDisabled={newStockAdjustment.edit}
                        />
                      </div>
                      <div className="col-4">
                        <label htmlFor="ingredient_group" className="form-label">
                          {_t(t("Ingredient Group"))}{" "}
                        </label>
                        <Select
                          options={ingredientGroupForSearch}
                          value={selectedIngredientGroup}
                          components={makeAnimated()}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          classNamePrefix="select"
                          onChange={handleSetIngredientGroup}
                          maxMenuHeight="200px"
                          menuPlacement="bottom"
                          placeholder={
                            _t(t("Please select ingredient group")) + ".."
                          }
                          isDisabled={!newStockAdjustment.branch || newStockAdjustment.edit}
                        />
                      </div>
                      <div className="col-4">
                        <label htmlFor="ingredient" className="form-label">
                          {_t(t("Ingredient"))}{" "}
                        </label>
                        <Select
                          options={ingredientItemToShow}
                          value={selectedIngredient}
                          components={makeAnimated()}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          classNamePrefix="select"
                          onChange={handleSetIngredient}
                          maxMenuHeight="200px"
                          menuPlacement="bottom"
                          placeholder={
                            _t(t("Please select ingredient")) + ".."
                          }
                          isDisabled={!newStockAdjustment.branch || newStockAdjustment.edit}
                        />
                      </div>
                    </div>

                    <div className="row mt-3 m-0">
                      <div className="form-radio col-4">
                        <div className="mb-2">
                          <label className="control-label">
                            {_t(t("Show Stock"))}
                          </label>
                        </div>

                        <div className="d-inline mr-4">
                          <input
                            type="radio"
                            className="form-check-input pointer-cursor"
                            id="showWithZero_yes"
                            name="showWithZero"
                            value="yes"
                            checked={showWithZero===0}
                            onChange={() => setShowWithZero(0)}
                            disabled={newStockAdjustment.edit}
                          />
                          <label
                            className="form-radio-label pointer-cursor ml-2"
                            htmlFor="showWithZero_yes"
                          >
                            {_t(t("With Zero"))}
                          </label>
                        </div>

                        <div className="d-inline mr-4">
                          <input
                            type="radio"
                            className="form-check-input pointer-cursor"
                            id="showWithZero_no"
                            name="showWithZero"
                            value="no"
                            checked={showWithZero===1}
                            onChange={() => setShowWithZero(1)}
                            disabled={newStockAdjustment.edit}
                          />
                          <label
                            className="form-radio-label pointer-cursor ml-2"
                            htmlFor="showWithZero_no"
                          >
                            {_t(t("Without Zero"))}
                          </label>
                        </div>
                      </div>
                      <div className="row m-0 p-0 col-4">
                        <div className="col-6 d-flex flex-row align-items-end">
                          <button
                            className="btn btn-primary w-100"
                            onClick={getIngredientInfo}
                            type="button"
                            disabled={!newStockAdjustment.branch || newStockAdjustment.edit || ingredientLoading}
                          >
                            {_t(t("Get Stock"))}
                          </button>
                        </div>
                        {ingredientLoading ?
                          <div className="position-relative flex-grow-0 mt-auto" style={{width: '2rem', height: '2rem'}}>
                            <div className="loading top-0 bottom-0 left-0 right-0 ml-0" style={{width: '2rem', height: '2rem'}} />
                          </div>
                        : null}
                      </div>
                    </div>

                    <div className="row m-0 mt-3">
                      {newStockAdjustment.ingredients && Object.values(newStockAdjustment.ingredients).length ?
                        <div className="mt-3">
                        <table className="table table-bordered table-hover">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Code"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Name"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{minWidth: '100px'}}
                              >
                                {_t(t("Unit"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{minWidth: '100px'}}
                              >
                                {_t(t("Actual Stock Qty"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{minWidth: '100px', maxWidth: '200px'}}
                              >
                                {_t(t("Qty"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{minWidth: '100px'}}
                              >
                                {_t(t("Adjusted Qty"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{minWidth: '100px'}}
                              >
                                {_t(t("Price"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{minWidth: '100px'}}
                              >
                                {_t(t("Amount"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{minWidth: '100px'}}
                              >
                                {_t(t("Action"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {Object.values(newStockAdjustment.ingredients).map(
                              (ingredient, index) => (
                                <tr
                                  className="align-middle"
                                  key={index}
                                >
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {ingredient.item_code}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {ingredient.name}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {ingredient.unit}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {ingredient.stock_qty}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    <input
                                      type="number"
                                      name="qty"
                                      className="form-control"
                                      value={ingredient.qty}
                                      placeholder={'Enter Qty'}
                                      min="0"
                                      disabled={newStockAdjustment.edit}
                                      onChange={(e) => {handleSetIngredientQty(e, ingredient.id)}}
                                    />
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {ingredient.adjust_qty}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {ingredient.avg_rate}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    {ingredient.item_amount}
                                  </td>
                                  <td className="xsm-text text-capitalize align-middle text-center">
                                    <button className="btn btn-danger" onClick={() => {handleDeleteIngredient(ingredient.id)}}>
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                        : null
                      }
                    </div>

                    <div className="row m-0 mt-3">
                      <div>
                        <label htmlFor="remarks" className="form-label">
                          {_t(t("Remarks"))}{" "}
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="remarks"
                          name="remarks"
                          placeholder="Remarks"
                          value={newStockAdjustment.remarks || ""}
                          onChange={handleSetNewStockAdjustment}
                          disabled={newStockAdjustment.edit}
                        />
                      </div>
                    </div>

                    <div className="mt-4">
                      <div className="row justify-content-end">
                        <div className="col-2">
                          {!newStockAdjustment.edit ?
                          <button
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                          >
                            {_t(t("Save"))}
                          </button>
                          : null}
                        </div>
                        <div className="col-2">
                          <button
                            type="button"
                            className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                            data-dismiss="modal"
                            onClick={handleClearStates}
                          >
                            {_t(t("Close"))}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div key="fragment2">
                  <div className="text-center text-primary font-weight-bold text-uppercase">
                    {_t(t("Please wait"))}
                  </div>
                  {modalLoading(3)}
                  <div className="mt-4">
                    <div className="row">
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {!newStockAdjustment.edit ? _t(t("Save")) : _t(t("Update"))}
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn btn-primary w-100 xsm-text text-uppercase t-width-max"
                          data-dismiss="modal"
                        >
                          {_t(t("Close"))}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div className="fk-scroll--pos-table-menu" data-simplebar>
                  <div className="t-pl-15 t-pr-15">
                    {/* Loading effect */}
                    {/* {loading === true ? (
                      tableLoading()
                    ) : ( */}
                      <div key="fragment3">
                        {/* next page data spin loading */}
                        <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                          <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        {/* spin loading ends */}

                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {!searchedStockAdjustment.searched
                                    ? _t(t("Stock Adjustment List"))
                                    : _t(t("Search Result"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center">
                              {/* Search customer */}
                              <div className="col-md-9 t-mb-15 mb-md-0">
                                <div className="input-group">
                                  <div className="form-file">
                                    <input
                                      type="text"
                                      className="form-control border-0 form-control--light-1 rounded-0"
                                      placeholder={_t(t("Search")) + ".."}
                                      onChange={handleSearch}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>

                              {/* Add customer modal trigger button */}
                              <div className="col-md-3 text-md-right">
                                {/* <button
                                  type="button"
                                  className="btn btn-primary xsm-text text-uppercase btn-lg btn-block"
                                  data-toggle="modal"
                                  data-target="#addAdjustment"
                                  onClick={() => {
                                    setNewStockAdjustment({
                                      ...newStockAdjustment,
                                      edit: false,
                                      uploading: false,
                                    });
                                  }}
                                >
                                  {_t(t("add new"))}
                                </button> */}
                                <NavLink
                                to="/dashboard/manage/stock/stock-adjustment-add"
                                className="btn btn-primary xsm-text text-uppercase btn-lg btn-block rounded"
                              >
                                {_t(t("add new"))}
                              </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Table */}
                        <div className="">
                          <table className="table table-bordered table-hover min-table-height">
                            <thead className="align-middle">
                              <tr>
                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("S/L"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Adjustment Number"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Adjustment Date"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Branch"))}
                                </th>

                                <th
                                  scope="col"
                                  className="sm-text text-capitalize align-middle text-center border-1 border"
                                >
                                  {_t(t("Action"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="align-middle">
                              {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                              {!searchedStockAdjustment.searched
                                ? [
                                    stockAdjustmentList && [
                                      !stockAdjustmentList.data || stockAdjustmentList.data.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        stockAdjustmentList.data.map((item, index) => {
                                          return (
                                            <tr
                                              className="align-middle"
                                              key={index}
                                            >
                                              <th
                                                scope="row"
                                                className="xsm-text text-capitalize align-middle text-center"
                                              >
                                                {index +
                                                  1 +
                                                  (stockAdjustmentList.current_page -
                                                    1) *
                                                    stockAdjustmentList.per_page}
                                              </th>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {item.adjust_no}
                                              </td>

                                              <td className="xsm-text align-middle text-center">
                                                {item.adjust_date}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                {branchNamesObj[item.branch_id] && branchNamesObj[item.branch_id].name
                                                ? branchNamesObj[item.branch_id].name
                                                : ''}
                                              </td>

                                              <td className="xsm-text text-capitalize align-middle text-center">
                                                <div className="d-flex justify-content-center">
                                                    <button
                                                      className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                      onClick={() => {
                                                        handleSetView(
                                                          item.id
                                                        );
                                                      }}
                                                      // data-toggle="modal"
                                                      // data-target="#addAdjustment"
                                                    >
                                                      <i className="fa fa-print edit-icon" aria-hidden="true"></i>
                                                    </button>
                                                    {authUserInfo.permissions !== null && authUserInfo.permissions.includes("Accounts") && (
                                                        <button
                                                          className="btn btn-primary ml-2 rounded-sm del-btn"
                                                          onClick={()=>handleStockLedger(item.id)}                                                    
                                                        >                                                    
                                                          <i className="fa fa-eye edit-icon" aria-hidden="true"></i>                                                    
                                                        </button>
                                                      )
                                                    }
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ),
                                    ],
                                  ]
                                : [
                                    /* searched data, logic === haveData*/
                                    searchedStockAdjustment && [
                                      searchedStockAdjustment.list.length === 0 ? (
                                        <tr className="align-middle">
                                          <td
                                            scope="row"
                                            colSpan="7"
                                            className="xsm-text align-middle text-center"
                                          >
                                            {_t(t("No data available"))}
                                          </td>
                                        </tr>
                                      ) : (
                                        searchedStockAdjustment.list.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                className="align-middle"
                                                key={index}
                                              >
                                                <th
                                                  scope="row"
                                                  className="xsm-text text-capitalize align-middle text-center"
                                                >
                                                  {index +
                                                    1 +
                                                    (stockAdjustmentList.current_page -
                                                      1) *
                                                      stockAdjustmentList.per_page}
                                                </th>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {item.adjust_date}
                                                </td>

                                                <td className="xsm-text align-middle text-center">
                                                  {item.adjust_date}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  {branchNamesObj[item.from_branch_id] && branchNamesObj[item.from_branch_id].name
                                                  ? branchNamesObj[item.from_branch_id].name
                                                  : ''}
                                                </td>

                                                <td className="xsm-text text-capitalize align-middle text-center">
                                                  <div className="d-flex justify-content-center">
                                                    <button
                                                        className="btn btn-secondary ml-2 rounded-sm del-btn"
                                                        onClick={() => {
                                                          handleSetView(
                                                            item.id
                                                          );
                                                        }}
                                                        // data-toggle="modal"
                                                        // data-target="#addAdjustment"
                                                      >
                                                        <i className="fa fa-print edit-icon" aria-hidden="true"></i>
                                                      </button>
                                                      {authUserInfo.permissions !== null && authUserInfo.permissions.includes("Accounts") && (
                                                          <button
                                                            className="btn btn-primary ml-2 rounded-sm del-btn"
                                                            onClick={()=>handleStockLedger(item.id)}                                                    
                                                          >                                                    
                                                            <i className="fa fa-eye edit-icon" aria-hidden="true"></i>                                                    
                                                          </button>
                                                        )
                                                      }
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ),
                                    ],
                                  ]}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    {/* )} */}
                  </div>
                </div>
              </div>

              {/* pagination loading effect */}
              {loading === true
                ? paginationLoading()
                : [
                    // logic === !searched
                    !searchedStockAdjustment.searched ? (
                      <div key="fragment4">
                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                          <div className="row align-items-center t-pl-15 t-pr-15">
                            <div className="col-md-7 t-mb-15 mb-md-0">
                              {/* pagination function */}
                              {pagination(stockAdjustmentList, setPaginatedStockAdjustment)}
                            </div>
                            <div className="col-md-5">
                              <ul className="t-list d-flex justify-content-md-end align-items-center">
                                <li className="t-list__item">
                                  <span className="d-inline-block sm-text">
                                    {showingData(stockAdjustmentList)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // if searched
                      <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                        <div className="row align-items-center t-pl-15 t-pr-15">
                          <div className="col-md-7 t-mb-15 mb-md-0">
                            <ul className="t-list d-flex">
                              <li className="t-list__item no-pagination-style">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    setSearchedStockAdjustment({
                                      ...searchedStockAdjustment,
                                      searched: false,
                                    })
                                  }
                                >
                                  {_t(t("Clear Search"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-5">
                            <ul className="t-list d-flex justify-content-md-end align-items-center">
                              <li className="t-list__item">
                                <span className="d-inline-block sm-text">
                                  {searchedShowingData(
                                    searchedStockAdjustment,
                                    stockAdjustmentForSearch
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ),
                  ]}
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
      <ViewAccountSheet
        accountLedger={ledgerData}
        setAccountLedger= {setLedgerData}
      />
    </>
  );
};

export default StockAdjustment;